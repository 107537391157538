import { gql } from '@apollo/client';
import client from '../Containers/App/config/ApolloClient';

const getUserRidesList = (limit, offset) => {
  const userId = localStorage.getItem('userIdHasura');
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query MyQuery($user_id: uuid!, $lim: Int!, $off: Int!) {
            yt_ride(
              where: { user_id: { _eq: $user_id }, status: { _eq: "COMPLETE" } }
              order_by: { updated_at: desc }
              limit: $lim
              offset: $off
            ) {
              route_map_file_id
              end_address
              start_address
              distance
              duration
              created_at
              id
              driver {
                id
                average_rate
                full_name
                profile_photo_file_id
              }
              vehicle {
                registration_number
                vehicle_model {
                  title
                }
                vehicle_type {
                  title
                }
              }
              boarding_pass {
                id
                created_at
                extended_at
                pass_number
                pass_type
                purchased_at
                qr_code
                rides(limit: 1) {
                  created_at
                }
              }
            }
          }
        `,
        variables: {
          user_id: userId,
          lim: limit,
          off: offset,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getUserRidesById = (rideId) => {
  const userId = localStorage.getItem('userIdHasura');
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query MyQuery($user_id: uuid!, $id: uuid!) {
            yt_ride(where: { id: { _eq: $id }, user_id: { _eq: $user_id } }) {
              route_map_file_id
              end_address
              start_address
              start_location
              end_location
              distance
              duration
              created_at
              id
              driver {
                average_rate
                full_name
                profile_photo_file_id
              }
              vehicle {
                registration_number
                vehicle_model {
                  title
                }
                vehicle_type {
                  title
                }
              }
              boarding_pass {
                pass_number
                pass_type
                valid_from
                valid_to
                qr_code
                id
                status
                purchased_at
                user_id
                rides {
                  id
                  start_location
                  end_location
                  duration
                  distance
                  start_address
                  end_address
                  vehicle_id
                  boarding_pass_id
                  created_at
                  vehicle {
                    registration_number
                    id
                    name
                  }
                }
                order {
                  amount
                  net_amount
                  transaction_charge
                  tax
                  discount
                  payments {
                    id
                    user_id
                    payment_method
                    amount
                  }
                }
                plan {
                  validity_days
                  title
                }
              }
            }
          }
        `,
        variables: {
          id: rideId,
          user_id: userId,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { getUserRidesList, getUserRidesById };
