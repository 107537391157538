import React from 'react';
import Grid from '@material-ui/core/Grid';

function NotFoundPage() {
  return (
    <Grid>
      <h1>Not Found Page</h1>
    </Grid>
  );
}

export default NotFoundPage;
