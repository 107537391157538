/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
import firebase from 'firebase/app';
import 'firebase/auth';
import '@firebase/messaging';
import { registerPushNotification } from '../Actions';

const firebaseConfig = {
  apiKey: "AIzaSyAupM5fweU_u02ZZMaZ9N49m-Ly1IBbh00",
  databaseURL: "https://yello-driver-c7950.firebaseapp.com",
  authDomain: "yello-driver-c7950.firebaseapp.com",
  projectId: "yello-driver-c7950",
  storageBucket: "yello-driver-c7950.appspot.com",
  messagingSenderId: "810878236533",
  appId: "1:810878236533:web:c50f3424e1ec43ec0fc53c",
  measurementId: "G-1LS9RQY344"
};

if (!firebase.apps[0]) {
  firebase.initializeApp(firebaseConfig);
}

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    'BPYZJF-crbT6qWSsTyRiBFS7SmrPJQrecDcM13PYBa6gIzTI-mz0hI8Qk9JQSwitF6zOmRF-wrApflCqW6Anzgk',
  );

  messaging.onTokenRefresh(() => {
    messaging
      .getToken()
      .then((refreshedToken) => {
        const existingToken = localStorage.getItem('FCMToken');
        const userId = localStorage.getItem('userIdHasura');
        if (existingToken !== refreshedToken || !existingToken) {
          localStorage.setItem('FCMToken', refreshedToken);
          registerPushNotification(refreshedToken, userId, refreshedToken)
            .then((res) => {
              console.log('Firebase', res);
            })
            .catch((err) => {
              console.log('Firebase err', err);
            });
        }
      })
      .catch((err) => {
        console.log('Firebase Unable to retrieve refreshed token ', err);
      });
  });
} else {
  console.log('Firebase Not Supported.');
}

export { firebase, messaging };
