import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    height: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: 999,
  },
  ccard: {
    // position: 'absolute',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    display: 'flex !important',
    marginTop: '40vh',
    minHeight: '60vh',
    // paddingBottom: '2vmax',
    width: '100% !important',
    background: `linear-gradient(rgb(49,49,49),rgb(31,31,30)) !important`,
  },
  ButtonContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  ratingsContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px 25px 0 25px',
    height: '80%',
    width: '100%',
  },
  imageContainer: {
    marginBottom: '1vmax',
    width: '8vmax',
    height: '8vmax',
    borderRadius: 100,
    borderWidth: 2,
    borderColor: '#FFFFFF',
    borderStyle: 'solid',
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  starRatingContainer: {
    padding: '1vmin',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .starRatingContainerText': {
      fontFamily: 'Avenir-Black',
      fontSize: '2vmax',
      color: 'rgb(255,253,0)',
    },
  },
  starIcon: {
    color: 'white',
  },
  customLabel: {
    color: '#FFFD00',
    fontSize: '1.7vmax',
    fontWeight: '600',
    // top: '-3px',
    fontFamily: 'Avenir Next',
    position: 'static',
    transform: 'inherit',
  },
  inputContainer: {
    width: '100%',
    color: 'rgb(181,181,181)',
    marginTop: theme.spacing(0.5),
    fontSize: '1.8vmax',
    '& .MuiInput-underline:before': {
      borderBottomColor: 'rgb(181,181,181)',
    },
  },
  tipContainer: {
    height: '15vmax',
    width: '100%',
  },
  addTipContainer: {
    display: 'flex',
    width: '100%',
    // height: '60%',
    flexDirection: 'column',
    paddingTop: '2vmax',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addCustomTipText: {
    color: '#FFFFFF',
    fontSize: '1.7vmax',
    fontWeight: '800',
  },
  inputContainerBox: {
    marginTop: '3vmax',
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  tipButtonDefault: {
    marginRight: '1.5vmin',
    marginLeft: '1.5vmin',
    borderRadius: 100,
    fontSize: '1.4vmax',
    padding: '2px',
    color: 'white',
    borderColor: 'white',
    fontWeight: '700',
    borderWidth: '2px',
    minWidth: '12vmin',
    height: '12vmin',
  },
  tipButtonSelected: {
    marginRight: '1.5vmin',
    marginLeft: '1.5vmin',
    borderRadius: 100,
    fontSize: '1.4vmax',
    padding: '2px',
    color: 'black',
    borderColor: 'white',
    fontWeight: '700',
    borderWidth: '2px',
    minWidth: '12vmin',
    height: '12vmin',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (hover: none)']: {
      backgroundColor: 'white !important',
    },
  },
  tipButtonContainer: {
    marginBottom: '2vmax',
  },
}));
