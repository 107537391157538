/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable global-require */
import React from 'react';
// importing UI components

// importing helpers
import useStyles from '../css/NotificationSettingCardCss';
import NotificationSwitch from '../../../Components/inputfields/NotificationSwitch';

export default function NotificationSettingsCard(props) {
  const classes = useStyles();

  return (
    <div className={classes.cardContainer} onClick={props.onClick}>
      <div className={classes.innerCardContainer}>
        <div className={classes.switchTextContainer}>
          <div className={classes.textContainer}>
            <span>{props.notificationType}</span>
          </div>
          <div className={classes.switchContainer}>
            <NotificationSwitch checked={props.value} onChange={props.handleChange} />
          </div>
        </div>
        <div className={classes.notifInfoContainer}>
          <span className={classes.notifInfoText}>{props.notificationInfoText}</span>
        </div>
      </div>
    </div>
  );
}
