/* eslint-disable import/prefer-default-export */
// const CREATE_PROFILE_ENDPOINT =
//   'https://px98k2l5qg.execute-api.us-west-2.amazonaws.com/dev/createProfile';

import slackAxios from '../slack';

// for devhasura dev env
const CREATE_PROFILE_ENDPOINT =
  'https://px98k2l5qg.execute-api.us-west-2.amazonaws.com/dev/createProfile';

export const createProfile = (idToken, data) => {
  return new Promise((resolve, reject) => {
    fetch(CREATE_PROFILE_ENDPOINT, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        full_name: data.name,
        country_code: data.code,
        mobile: data.phone,
        type: 'rider',
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        slackAxios({ file: 'createProfile', err });
        reject(err);
      });
  });
};
