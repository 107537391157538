import { gql } from '@apollo/client';

import client from '../Containers/App/config/ApolloClient';

// eslint-disable-next-line import/prefer-default-export
export const getPlans = (limit, offset) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query($limit: Int!, $offset: Int!) {
            yt_plan(limit: $limit, offset: $offset, where: { active: { _eq: true } }) {
              id
              title
              description
              price
              only_airport_service
              unlimited_trips
              extension_price_1_day
              extension_price_2_day
              extension_price_3_day
              validity_days
              unlimited_trips
              total_daily_trips
              total_trips
              active
            }
          }
        `,
        variables: {
          limit,
          offset,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getPlansDaily = (limit, offset) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query($limit: Int!, $offset: Int!) {
            yt_plan(limit: $limit, offset: $offset) {
              id
              title
              description
              price
              only_airport_service
              unlimited_trips
            }
          }
        `,
        variables: {
          limit,
          offset,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
