/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import Draggable from 'react-draggable';
import EditIcon from '../../../assets/svg/editIcon.svg';
import DeleteIcon from '../../../assets/svg/deleteIcon.svg';
import RightArrowSmall from '../../../assets/svg/smallRightArrow.svg';
import GreenDot from '../../../assets/svg/greenDot.svg';

import useStyles from '../css/SwipeItemCSS';

export default (props) => {
  const classes = useStyles();
  const [left, setLeft] = useState(0);
  // const [pos, setPos] = useState(0);

  const itemRef = useRef();
  const actionRef = useRef();

  const handleStart = () => {};

  const handleStop = (e, data) => {
    const w = itemRef.current.offsetWidth;
    const x = data.x < 0 ? data.x * -1 : data.x;
    const p = (x / w) * 100;
    if (p > 10) {
      const pw = actionRef.current.offsetWidth;
      const leftWithAction = data.x > 0 ? 0 : (pw - 5) * -2;
      setLeft(leftWithAction);
    } else {
      setLeft(0);
    }
  };

  return (
    <div className={classes.listItem}>
      <div className={classes.options} ref={actionRef}>
        <div className={classes.editButtonContainer} onClick={props.onClickEdit}>
          <img src={EditIcon} alt="nada" className={classes.editIcon} />
        </div>
        <div className={classes.deleteButtonContainer} onClick={props.onClickDelete}>
          <img src={DeleteIcon} alt="nada" className={classes.editIcon} />
        </div>
      </div>
      <Draggable
        axis="x"
        onStart={handleStart}
        scale={1}
        defaultPosition={{ x: 10, y: 0 }}
        position={{ x: left, y: 0 }}
        onStop={handleStop}
      >
        <div
          ref={itemRef}
          className={classes.singleRide}
          style={{
            // position: 'absolute',
            transform: `translate3d(${left}px, 0, 0px)`,
            // width: '100%',
          }}
        >
          <div className={classes.savedPlaceCard}>
            <div className={classes.smallArrowContainer}>
              <img
                src={RightArrowSmall}
                alt="nada"
                className={classes.rightArrowIcon}
                style={{ transform: `rotate(${left < 0 ? '0deg' : '180deg'})` }}
              />
            </div>
            <div className={classes.addressHeaderContainer}>
              <img src={GreenDot} alt="nada" className={classes.greenDot} />
              <div className={classes.addressHeaderTextContainer}>
                <span className={classes.addressHeaderText}>{props.addressLabel}</span>
              </div>
            </div>
            <div className={classes.addressContainer}>
              <span className={classes.addressText}>{props.address}</span>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};
