import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link, withRouter } from 'react-router-dom';

function NetworkErrorPage() {
  return (
    <Grid>
      <h1>Network Error </h1>
      <Link to="/">go to home</Link>
    </Grid>
  );
}

export default withRouter(NetworkErrorPage);
