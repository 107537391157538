/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  // split,
  Observable,
  ApolloLink,
  concat,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { split } from 'apollo-link';
import { onError } from '@apollo/client/link/error';
import HASURA_ENDPOINT from './uri';
import history from './history';
import { firebase } from '../../../config/firebaseConfig';
import slackAxios from '../../../slack';

const httpLink = createHttpLink({
  uri: HASURA_ENDPOINT,
});

// const getSocketLink = async () => {
//   const tk = await localStorage.getItem('token');
// }
const wsLink = new WebSocketLink({
  uri: 'wss://loyal-polliwog-98.hasura.app/v1/graphql',
  options: {
    lazy: true,
    reconnect: true,
    reconnectionAttempts: 5,
    inactivityTimeout: 10000,
    // connectionParams: () => {
    //   return {
    //     Authorization: getToken(),
    //   };
    // },
    connectionParams: () => {
      const t = localStorage.getItem('token');
      const userIdHasura = localStorage.getItem('userIdHasura');
      const userRoleHasura = localStorage.getItem('userRoleHasura');
      if (t && t !== null) {
        return {
          headers: {
            authorization: `Bearer ${t}`,
            'x-hasura-user-id': userIdHasura,
            'x-hasura-admin-secret':
              'zJCs9RPcCbxlr9eg20D47SdGLSrO9blnWPJFNkgR2YU8ZyZ5MkFL6KTZq4BQgzt2',
            "x-hasura-role":userRoleHasura
          },
        };
      }
    },

    connectionCallback: async (error) => {
      await slackAxios({ error, file: 'connectionCallback' });

      if (error) {
        console.log(error);
      }

      console.log('connectionCallback');
    },
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const t = localStorage.getItem('token');
  const userIdHasura = localStorage.getItem('userIdHasura');
  const userRoleHasura = localStorage.getItem('userRoleHasura');
  // return the headers to the context so httpLink can read them
  if (t && t !== null) {
    // alert('token not null');
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${t}`,
        'x-hasura-user-id': userIdHasura,
        'x-hasura-admin-secret': 'zJCs9RPcCbxlr9eg20D47SdGLSrO9blnWPJFNkgR2YU8ZyZ5MkFL6KTZq4BQgzt2',
        "x-hasura-role":userRoleHasura
      },
    };
  }
  return {
    ...headers,
  };
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const t = localStorage.getItem('token');
  const userIdHasura = localStorage.getItem('userIdHasura');
  if (t) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${t}`,
        'x-hasura-user-id': userIdHasura,
      },
    });
  }
  return forward(operation);
});

// eslint-disable-next-line no-unused-vars
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  console.log({ graphQLErrors, networkError, operation });
  const accessToken = localStorage.getItem('token');

  if (networkError) {
    console.log({ networkError });
    const error = networkError?.result?.error?.message;
    if (error === 'Unauthorized') {
      // hard reloading to enforce re-authorization
      window.location.reload();
    } else {
      // https://github.com/apollographql/react-apollo/issues/3331
      // history.push('/network-error', { error }); // redirect to network-error route
      slackAxios({
        networkError,
        data: '=',
      });
    }
  }

  if (graphQLErrors) {
    // User access token has expired
    if (
      graphQLErrors[0].extensions.code === 'invalid-jwt' ||
      graphQLErrors[0].extensions.code === 'invalid-headers' ||
      graphQLErrors[0].extensions.code === 'validation-failed'
    ) {
      if (accessToken && accessToken !== 'undefined') {
        // Let's refresh token through async request
        const userIdHasura = localStorage.getItem('userIdHasura');
        const userRoleHasura = localStorage.getItem('userRoleHasura');
        return new Observable((observer) => {
          firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
              user
                .getIdTokenResult(true)
                .then((token1) => {
                  const idToken = token1.token;
                  window.localStorage.setItem('token', idToken);
                  // reset the headers
                  operation.setContext(({ headers = {} }) => ({
                    headers: {
                      // Re-add old headers
                      ...headers,
                      // Switch out old access token for new one
                      authorization: `Bearer ${idToken}`,
                      'x-hasura-user-id': userIdHasura,
                      "x-hasura-role":userRoleHasura
                    },
                  }));
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };

                  // Retry last failed request
                  forward(operation).subscribe(subscriber);
                  // hard reloading to enforce re-authorization
                  if (
                    graphQLErrors[0].extensions.code.includes('jwt') ||
                    graphQLErrors[0].extensions.code.includes('JWTExpired')
                  ) {
                    window.location.reload();
                  }
                })
                .catch((error) => {
                  // No refresh or client token available, we force user to login
                  observer.error(error);
                  slackAxios({ error });
                });
            } else {
              localStorage.clear();
              localStorage.setItem('firstLoad', true);
              history.replace({
                pathname: '/login',
              });
            }
          });
        });
      }
    } else if (
      (graphQLErrors[0].extensions.code === 'start-failed' &&
        graphQLErrors[0].message ===
          "cannot start as connection_init failed with : claims key: 'https://hasura.io/jwt/claims' not found") ||
      (graphQLErrors[0].extensions.code === 'jwt-invalid-claims' &&
        graphQLErrors[0].message === "claims key: 'https://hasura.io/jwt/claims' not found")
    ) {
      console.log('unknown error');
      // localStorage.clear();
      // localStorage.setItem('firstLoad', true);
      // history.replace({
      //   pathname: '/login',
      // });
    }
    console.log('undefined error');
  }
});

// const splitLink = split(
//   async ({ query }) => {
//     const definition = getMainDefinition(query);
//     return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
//   },
//   errorLink.concat(wsLink),
//   // concat(authMiddleware, httpLink),
//   errorLink.concat(authLink.concat(httpLink)),
// );

const splitLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  errorLink.concat(wsLink),
  errorLink.concat(authLink.concat(httpLink)),
);

const options = {
  resultCaching: false,
};

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export default new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(options),
  // eslint-disable-next-line object-shorthand
  defaultOptions: defaultOptions,
});
