import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const NotificationSwitch = withStyles((theme) => ({
  root: {
    width: '42px',
    height: '22px',
    padding: 0,
    display: 'flex',
    overflow: 'visible',
  },
  switchBase: {
    padding: 2,
    color: 'rgb(174,174,174)',
    '&$checked': {
      transform: 'translateX(22px)',
      color: 'rgb(0,204,68)',
      '& + $track': {
        opacity: 1,
        backgroundColor: 'rgb(49,49,49)',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    marginTop: '1px',
    boxShadow: 'none',
  },
  track: {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: 'rgb(49,49,49)',
  },
  checked: {},
}))(Switch);

export default NotificationSwitch;
