/* eslint-disable no-else-return */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// importing UI helpers
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';
import HeaderTitle from '../../../Components/headertitle/HeaderTitle';
import useStyles from '../css/ProfilePageCss';
import ProfilePic from '../../../assets/svg/profilePic2.svg';
import CameraIcon from '../../../assets/svg/cameraIcon.svg';
import SuccessModal from './SuccessModal';
import PrimaryButton from '../../../Components/buttons/PrimaryButton';
// import navigationHandler from '../../App/helpers/NavigationHandler';
import Loader from '../../../Components/loader/Loader';
import { getProfileURL, getUploadURL, getUser, profileUpdate } from '../../../Actions';
import getUrlFromId from '../../../Actions/Common';

const fileUpload = new FormData();

export default function ProfilePage() {
  const classes = useStyles();
  const history = useHistory();
  const [userName, setUserName] = useState('Loading');
  const [userPhone, setPhone] = useState('Loading');
  const [userEmail, setEmail] = useState('Loading');
  const [profileURL, setProfileURl] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [newImageData, setNewImageData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [uploadingSuccess, setUploadingSuccess] = useState(false);
  const [error, setError] = useState('');
  const svgPath = (iconName) => `${iconName}#svgView(preserveAspectRatio(none))`;

  const getUrl = async (mapId) => {
    const url = await getUrlFromId(mapId);
    let result = null;
    if (url.data.GenViewUrl && url.data.GenViewUrl[0].url) {
      result = url.data.GenViewUrl[0].url;
    }
    return result;
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('user'))) {
      const user = JSON.parse(localStorage.getItem('user'));
      const userid = localStorage.getItem('userIdHasura');
      getUser(userid)
        .then(async (res) => {
          if (res.data.yt_user && res.data.yt_user[0].profile_photo_file_id) {
            const driverPic = await getUrl(res.data.yt_user[0].profile_photo_file_id);
            setProfileURl(driverPic);
          } else {
            setProfileURl(null);
          }
          setUserName(res.data.yt_user[0].full_name);
          setPhone(res.data.yt_user[0].country_code + res.data.yt_user[0].mobile);
          setEmail(res.data.yt_user[0].email);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });

      // if (user !== undefined || user !== null) {
      //   setUserName(user.full_name);
      //   setPhone(user.mobile);
      //   setEmail(user.email);
      // }
      // getProfileURL()
      //   .then((res) => {
      //     setProfileURl(res);
      //     setLoader(false);
      //   })
      //   .catch((err) => {
      //     console.log('aaa', err);
      //     setLoader(false);
      //   });
    }
  }, []);

  // function ArrayBufferToBinary(buffer) {
  //   // Convert an array buffer to a string bit-representation: 0 1 1 0 0 0...
  //   const dataView = new DataView(buffer);
  //   let response = ""; const offset = (8 / 8);
  //   for (let i = 0; i < dataView.byteLength; i += offset) {
  //     response += dataView.getInt8(i).toString(2);
  //   }
  //   return response;
  // }

  const setUser = () => {
    const id = localStorage.getItem('userIdHasura');
    getUser(id, history)
      .then((userRes) => {
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(userRes.data.yt_user[0]));
        setUploading(false);
        setUploadingSuccess(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImageChange = async (e) => {
    fileUpload.delete('file');
    const file = e.target.files[0];
    if (file) {
      if (
        e.target.files[0].name.split('.')[1] === 'jpg' ||
        e.target.files[0].name.split('.')[1] === 'png' ||
        e.target.files[0].name.split('.')[1] === 'jpeg'
      ) {
        setNewImage(URL.createObjectURL(file));
        setNewImageData(file);
        setProfileURl(null);
      }
    }
  };

  const handleSubmit = (e) => {
    if (userName === '' || userName.length <= 1 || !userName.match(/^[A-Za-z ]+$/)) {
      setError('Enter valid name');
      return;
      // } else if (
      //   userEmail === '' ||
      //   !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userEmail)
      // ) {
      //   setError('Enter valid email');
      //   return;
    } else {
      setError('');
    }
    setUploading(true);
    if (newImage && newImageData) {
      getUploadURL(newImageData.name, newImageData.type)
        .then((res) => {
          const putObjUrl = decodeURIComponent(res.data.UploadProfilePhoto.upload_url);

          const reader = new FileReader();
          reader.onload = function (event) {
            const data1 = event.target.result;

            const xhr = new XMLHttpRequest();
            xhr.withCredentials = false;

            xhr.addEventListener('readystatechange', function () {
              // eslint-disable-next-line react/no-this-in-sfc
              if (this.readyState === 4) {
                // eslint-di'sable-next-line react/no-this-in-sfc
                if (xhr.status === 200) {
                  profileUpdate(userEmail, userName)
                    .then((profileRes) => {
                      setUser();
                      setUploading(false);
                      setUploadingSuccess(true);
                    })
                    .catch((err) => {
                      setUploading(false);
                      setError(err.toString());
                    });
                } else {
                  setUploading(false);
                  setError('Error while uploading the image');
                }
              }
            });
            xhr.open('PUT', putObjUrl);

            xhr.send(data1);
          };

          // reader.readAsDataURL(file);
          reader.readAsArrayBuffer(newImageData);
        })
        .catch((err) => {
          setError(err.toString());
        });
    } else {
      profileUpdate(userEmail, userName)
        .then((res) => {
          setUser();
          setUploading(false);
          setUploadingSuccess(true);
        })
        .catch((err) => {
          setUploading(false);
        });
    }
  };

  const handleCloseModal = () => {
    setUploadingSuccess(false);
  };

  return (
    <div className={classes.root}>
      <HeaderTitle title="My Profile" backBtn={() => history.goBack()} />
      <div className={classes.cardBg}>
        {!loader ? (
          <div className={classes.placeCardContainer}>
            <div className={classes.placeContainer}>
              <div className={classes.inputContainer}>
                <div className={classes.imageContainer}>
                  <div>
                    <img
                      className={classes.myAcImgImg}
                      src={profileURL || newImage || require('../../../assets/img/profile.png')}
                      alt=""
                    />
                  </div>
                  <div className={classes.myAcImg}>
                    <img src={CameraIcon} alt="nothing" className={classes.cameraIcon} />
                    <div className={classes.browseBtn}>
                      <input
                        type="file"
                        className={classes.browseBtnInput}
                        onChange={(e) => handleImageChange(e)}
                        accept=".png, .jpg, .jpeg"
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.inputContainerBox}>
                  <InputLabel className={[classes.customLabel]}>Name</InputLabel>
                  <Input
                    className={classes.inputContainer}
                    placeholder="Enter full name"
                    value={userName}
                    required
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        setUserName(e.target.value);
                      } else {
                        setUserName(e.target.value);
                        setError('Name is required');
                      }
                    }}
                  />
                </div>
                <div className={classes.inputContainerBox}>
                  <InputLabel className={[classes.customLabel]}>Email</InputLabel>
                  <Input
                    className={classes.inputContainer}
                    placeholder="Enter email"
                    value={userEmail}
                    required
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        setEmail(e.target.value);
                      } else {
                        setEmail(e.target.value);
                        setError('Email is required');
                      }
                    }}
                  />
                </div>
                <div className={classes.inputContainerBox}>
                  <InputLabel className={[classes.customLabel]}>Phone Number</InputLabel>
                  <Input
                    className={classes.inputContainer}
                    placeholder="Enter phone number"
                    value={userPhone}
                    disabled
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              {error && (
                <div className="card-error" role="alert">
                  {error}
                </div>
              )}
              <PrimaryButton
                type="primary"
                variant="fullWidth"
                className={classes.saveButton}
                disabled={uploading}
                onClick={(e) => handleSubmit()}
                Title={uploading ? 'Updating' : 'Update'}
              />
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <SuccessModal openModal={uploadingSuccess} handleClose={handleCloseModal} />
    </div>
  );
}
