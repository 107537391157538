/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

// importing UI helpers
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { findAllByDisplayValue } from '@testing-library/react';
import PrimaryButton from '../../../Components/buttons/PrimaryButton';
import HeaderTitle from '../../../Components/headertitle/HeaderTitle';
import useStyles from '../css/PaymentMethodCss';
import navigationHandler from '../../App/helpers/NavigationHandler';
import CardRightArrow from '../../../Components/cards/CardRightArrow';
// import PaymentSuccessModal from './PaymentSuccessModal';
// import AppleLogo from '../../../assets/svg/appleLogo.svg';
// import NetBankLogo from '../../../assets/svg/netBankLogo.svg';
import CreditCardLogo from '../../../assets/svg/creditCardLogo.svg';
// import GoogleLogo from '../../../assets/svg/googleLogo.svg';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import CloseIcon from '../../../assets/svg/close.svg';
import PromoCodeCard from '../components/promoCodeCard';
import { getPromoCodes, validatePromocode, getTaxes } from '../../../Actions';
import { firebase } from '../../../config/firebaseConfig';
import PromoCodeModal from './PromoCodeModal';
import Loader from '../../../Components/loader/Loader';

export default function PaymentMethodPage() {
  const classes = useStyles();
  const history = useHistory();
  const [promoCodeScreen, setPromoScreen] = useState(false);
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);
  const [promoCodeFetching, setPromoCodeFetching] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeDetails, setPromoCodeDetails] = useState('');
  const [promoCodeList, setPromoCodeList] = useState('');
  const [taxesList, setTaxesList] = useState('');
  const [error, setError] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(findAllByDisplayValue);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [serviceTax, setServicetax] = useState(null);
  const [transactionCharge, setTransactionCharge] = useState(null);
  const [loader, setLoader] = useState(true);
  const svgPath = (iconName) => `${iconName}#svgView(preserveAspectRatio(none))`;

  useEffect(() => {
    const id = localStorage.getItem('userIdHasura');
    if (history.location.state) {
      getPromoCodes(10, 0)
        .then((res) => {
          setPromoCodeList(res.data.yt_coupon);
        })
        .catch((err) => {
          console.log(err);
        });
      // NV stands for NEVADA region,It could be dynamic in future
      getTaxes('NV')
        .then((res) => {
          setTaxesList(res.data.yt_tax[0]);
          const taxRate = (history.location.state.price * res.data.yt_tax[0].tax_rate) / 100;
          const transactionRate =
            (history.location.state.price * res.data.yt_tax[0].transaction_rate) / 100;
          setServicetax(taxRate);
          setTransactionCharge(transactionRate);
          setTotalPrice(history.location.state.price + taxRate + transactionRate);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      history.goBack();
    }
  }, []);

  // useEffect(() => {
  //   if (promoCodeDetails.discount_type === 'A') {
  //     const price = promoCodeDetails.discount_value;
  //     const taxRate = ((history.location.state.price - price) * taxesList.tax_rate) / 100;
  //     const transactionRate =
  //       ((history.location.state.price - price) * taxesList.transaction_rate) / 100;
  //     setServicetax(taxRate);
  //     setTransactionCharge(transactionRate);
  //     setDiscountPrice(price);
  //     setTotalPrice(history.location.state.price + taxRate + transactionRate - price);
  //   } else if (promoCodeDetails.discount_type === 'P') {
  //     const price = history.location.state.price * (promoCodeDetails.discount_value / 100);
  //     const taxRate = ((history.location.state.price - price) * taxesList.tax_rate) / 100;
  //     const transactionRate =
  //       ((history.location.state.price - price) * taxesList.transaction_rate) / 100;
  //     setServicetax(taxRate);
  //     setTransactionCharge(transactionRate);
  //     setDiscountPrice(price);
  //     setTotalPrice(history.location.state.price + taxRate + transactionRate - price);
  //   }
  //   // setPromoScreen(false);
  // }, [promoCodeDetails]);

  const handleClose = () => {
    setOpenModal(false);
    setPromoScreen(false);
  };

  // const handleClose = () => {
  //   setOpen(false);
  //   setTimeout(() => {
  //     navigationHandler(history, '/home');
  //   }, 1000);
  // };
  const handleCoupanSubmit = () => {
    setError(null);
    let isPriceZero = false;
    setPromoCodeFetching(true);
    validatePromocode(promoCode)
      .then((res) => {
        if (res.data.yt_coupon.length === 1) {
          if (res.data.yt_coupon[0].discount_type === 'A') {
            const price = res.data.yt_coupon[0].discount_value;
            const taxRate = ((history.location.state.price - price) * taxesList.tax_rate) / 100;
            const transactionRate =
              ((history.location.state.price - price) * taxesList.transaction_rate) / 100;
            if (history.location.state.price + taxRate + transactionRate - price <= 0) {
              setError('This coupon is not applicable');
              isPriceZero = true;
              setPromoCodeFetching(false);
            } else {
              setServicetax(taxRate);
              setTransactionCharge(transactionRate);
              setDiscountPrice(price);
              setTotalPrice(history.location.state.price + taxRate + transactionRate - price);
            }
          } else if (res.data.yt_coupon[0].discount_type === 'P') {
            const price =
              history.location.state.price * (res.data.yt_coupon[0].discount_value / 100);
            const taxRate = ((history.location.state.price - price) * taxesList.tax_rate) / 100;
            const transactionRate =
              ((history.location.state.price - price) * taxesList.transaction_rate) / 100;
            if (history.location.state.price + taxRate + transactionRate - price <= 0) {
              setError('This coupon is not applicable');
              isPriceZero = true;
              setPromoCodeFetching(false);
            } else {
              setServicetax(taxRate);
              setTransactionCharge(transactionRate);
              setDiscountPrice(price);
              setTotalPrice(history.location.state.price + taxRate + transactionRate - price);
            }
          }
          if (!isPriceZero) {
            setPromoCodeApplied(true);
            setPromoCode('');
            setPromoCodeDetails(res.data.yt_coupon[0]);
            setPromoCodeFetching(false);
            setOpenModal(true);
          }
        } else {
          setPromoCodeFetching(false);
          setError('Not a valid coupon');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlePromoSelect = (value) => {
    setError(null);
    setPromoCode(value);
  };

  const setToken = async () => {
    if (localStorage.getItem('token') === null) {
      await firebase
        .auth()
        .currentUser.getIdTokenResult(true)
        .then((getIdTokenResult) => {
          const idToken = getIdTokenResult.token;
          localStorage.setItem('token', idToken);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCoupanCancel = () => {
    setPromoCodeApplied(false);
    setPromoCode('');
    setPromoCodeDetails('');
    setDiscountPrice(null);
    const taxRate = (history.location.state.price * taxesList.tax_rate) / 100;
    const transactionRate = (history.location.state.price * taxesList.transaction_rate) / 100;
    setServicetax(taxRate);
    setTransactionCharge(transactionRate);
    setTotalPrice(history.location.state.price + taxRate + transactionRate);
  };

  return (
    <div className={classes.root}>
      <PromoCodeModal
        openModal={openModal && promoCodeApplied}
        handleClose={handleClose}
        promoCode={promoCodeApplied ? promoCodeDetails : null}
      />
      <HeaderTitle
        title={promoCodeScreen ? 'Promo Codes' : 'Payment Method'}
        backBtn={() => {
          if (promoCodeScreen) {
            setPromoScreen(false);
            setPromoCode('');
            setPromoCodeDetails('');
            setError(null);
          } else {
            history.goBack();
          }
        }}
      />
      <div className={classes.cardBg}>
        {promoCodeScreen ? (
          <div className={classes.innerContainer}>
            <div
              className={classes.inputContainerBox}
              style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
            >
              <div style={{ flex: 1 }}>
                <InputLabel className={[classes.customLabel]}>Promo code</InputLabel>
                <Input
                  className={classes.inputContainer}
                  placeholder="Enter promo code"
                  value={promoCode}
                  onChange={(e) => {
                    if (error !== null) {
                      setError(null);
                    }
                    setPromoCode(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={classes.cardsContainer}>
              {promoCodeList.length > 0 ? (
                promoCodeList.map((item) => (
                  <PromoCodeCard
                    onClick={handlePromoSelect}
                    code={item.code}
                    description={item.description}
                    expires={item.valid_to}
                  />
                ))
              ) : (
                <div className={classes.errorContainer}>
                  <p>No promo code found.</p>
                </div>
              )}
            </div>
            <div
              style={{ flex: 0.2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {error ? (
                <div className={classes.errorMessage}>
                  <span>{error}</span>
                </div>
              ) : null}
              <PrimaryButton
                type="primary"
                fullWidth
                className={classes.saveButton}
                disabled={promoCodeFetching}
                Title={promoCodeFetching ? 'Applying' : 'Apply'}
                onClick={(e) => handleCoupanSubmit()}
              />
            </div>
          </div>
        ) : loader ? (
          <Loader />
        ) : (
          <div className={classes.innerContainer}>
            {history.location.state && (
              <div className={classes.smallCardContainer}>
                <div className={classes.planPriceCard}>
                  <div className={classes.planHeaderText}>
                    <span>{history.location.state.title}</span>
                  </div>
                  <div className={classes.smallCardDivider} />
                  <div className={classes.planPricerText}>
                    <span>${parseFloat(history.location.state.price).toFixed(2)}</span>
                  </div>
                  <div className={classes.breakdownPricerText}>
                    <span>Pass Charges </span>
                    <span>${parseFloat(history.location.state.price).toFixed(2)}</span>
                  </div>
                  {promoCodeApplied ? (
                    <div className={classes.breakdownPricerText}>
                      <span>Discount</span>
                      <span>
                        -$
                        {parseFloat(discountPrice).toFixed(2)}
                      </span>
                    </div>
                  ) : null}
                  <div className={classes.breakdownPricerText}>
                    <span>Service Tax</span>
                    <span>${parseFloat(serviceTax).toFixed(2)}</span>
                  </div>
                  <div className={classes.breakdownPricerText}>
                    <span>Transaction Charges</span>
                    <span>${parseFloat(transactionCharge).toFixed(2)}</span>
                  </div>
                  <div className={classes.smallCardDivider} />
                  <div className={classes.breakdownPricerText}>
                    <span>Total</span>
                    <span>${parseFloat(totalPrice).toFixed(2)}</span>
                  </div>
                </div>
              </div>
            )}
            <div className={classes.promoContainer}>
              <h4
                className={classes.promoContainerText}
                onClick={() => {
                  if (promoCodeApplied) {
                    handleCoupanCancel();
                  } else {
                    setPromoScreen(true);
                  }
                }}
              >
                {promoCodeApplied ? 'Promo code applied' : 'Add a promo code'}
              </h4>
              <div className={classes.iconContainer}>
                {promoCodeApplied ? (
                  <img
                    src={CloseIcon}
                    style={{ height: '1.5vmax', width: '1.5vmax', transform: 'translate(0, 0)' }}
                    alt="go"
                    onClick={(e) => handleCoupanCancel()}
                  />
                ) : (
                  <img
                    src={RightArrowIcon}
                    style={{
                      height: '2vmax',
                      width: '1vmax',
                      transform: 'translate(0, 0)',
                      // marginTop: '8px',
                    }}
                    alt="nothing"
                  />
                )}
              </div>
            </div>
            <div className={classes.cardsContainer2}>
              {/* <CardRightArrow icon={svgPath(AppleLogo)} title="Apple Pay" onClick={handleOpen} />
                <CardRightArrow
                  icon={svgPath(NetBankLogo)}
                  title="Internet Banking"
                  onClick={handleOpen}
                /> */}
              <CardRightArrow
                icon={CreditCardLogo}
                title="Credit/Debit Card"
                // onClick={handleOpen}
                onClick={async () => {
                  await setToken();
                  navigationHandler(history, '/payment-method/card-payment', '', {
                    amountToPay: totalPrice,
                    plan_id: history.location.state.id,
                    plan_name: history.location.state.title,
                    coupon_code: promoCodeDetails ? promoCodeDetails.code : '',
                    plan_validity: history.location.state.validity_days * 24,
                  });
                }}
              />
              {/* <CardRightArrow icon={svgPath(GoogleLogo)} title="Google Pay" onClick={handleOpen} /> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
