import { gql } from '@apollo/client';

import client from '../Containers/App/config/ApolloClient';
import slackAxios from '../slack';

let vehicleSubs = null;

export const getVehicles = (coords, callback) => {
  try {
    vehicleSubs = client
      .subscribe({
        query: gql`
          subscription MySubscription($curr_loc: point!, $radius: numeric!) {
            yt_get_vehicles_within_radius(
              args: { curr_loc: $curr_loc, radius: $radius }
              limit: 3
            ) {
              id
              driver_id
              location
              distance
              vehicle_type_id
              vehicle_make_id
              vehicle_model_id
              online
              registration_number
              registration_date
              photo_file_id
              driver_full_name
              driver_photo_file_id
              driver_email
              driver_country_code
              driver_mobile
            }
          }
        `,
        variables: {
          curr_loc: `(${coords.longitude}, ${coords.latitude})`,
          radius: 20,
        },
      })
      .subscribe((res) => {
        console.log('getVehicles', res.data);
        slackAxios({ file: 'getVehicles', data: res.data });
        callback(res.data);
      });
  } catch (e) {
    slackAxios({ file: 'getVehicles', e });
    console.log(e);
  }
};

export const unsubFromVehicles = () => {
  if (vehicleSubs !== null) {
    vehicleSubs.unsubscribe();
  }
};

export const getVehicle = () => {
  return new Promise(() => {
    client.subscription({
      subscription: gql`
        yt_get_vehicles_within_radius(args: 
          {curr_loc: "(73.17094399999999,22.3084544)", radius: 100}) {
    id
    driver_id
    location
    distance
    vehicle_type_id
    vehicle_make_id
    vehicle_model_id
    online
    registration_number
    registration_date
    photo_file_id
    driver_full_name
    driver_photo_file_id
    driver_email
    driver_country_code
    driver_mobile
  }
      `,
    });
  });
};
