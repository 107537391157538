/* eslint-disable camelcase */
import { gql } from '@apollo/client';

import client from '../Containers/App/config/ApolloClient';

// eslint-disable-next-line import/prefer-default-export
export const getPromoCodes = () => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query {
            yt_coupon(where: { active: { _eq: true } }) {
              id
              code
              active
              description
              discount_type
              discount_value
              valid_to
            }
          }
        `,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const validatePromocode = (code) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query($code: String!) {
            yt_coupon(where: { code: { _eq: $code }, active: { _eq: true } }) {
              id
              code
              active
              description
              discount_type
              discount_value
              valid_to
            }
          }
        `,
        variables: {
          code,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getTaxes = (state_abbr) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query($state_abbr: String!) {
            yt_tax(where: { state: { abbreviation: { _eq: $state_abbr } } }) {
              id
              state {
                name
                abbreviation
              }
              tax_code
              tax_rate
              transaction_rate
            }
          }
        `,
        variables: {
          state_abbr,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const purchaseBoardingPass = (plan_id, coupon_code) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation($plan_id: uuid!, $coupon_code: String!) {
            PurchaseBoardingPass(plan_id: $plan_id, coupon_code: $coupon_code) {
              order_id
              status
              order_number
              order_details
            }
          }
        `,
        variables: {
          plan_id,
          coupon_code,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createPayment = (order_id, currency) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation($order_id: uuid!, $terminal: Boolean!, $currency: String!) {
            CreatePayment(order_id: $order_id, terminal: $terminal, currency: $currency) {
              client_secret
              order_id
              payment_id
              transaction_id
              transaction_status
            }
          }
        `,
        variables: {
          order_id,
          terminal: false,
          currency,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
