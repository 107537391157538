import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // paddingBottom: '7vmin',
    height: 'calc(var(--vh, 1vh) * 100)',

    // width: '100%',
    // height: '100vh',

    backgroundColor: 'black',
    overflow: 'none',
  },
  cardContainer: {
    display: 'flex',
    flex: 0.4,
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  cardInnerContainer: {
    width: '85%',

    // backgroundColor: 'pink',
    justifyContent: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  inputNumberContainer: {
    display: 'flex',
    flex: 0.33,
    justifyItems: 'center',
    width: '100%',
    '& .MuiInput-underline:before': {
      borderBottomColor: 'rgb(181,179,179)',
    },
    '& .MuiInputBase-input': {
      fontWeight: '600',
      fontSize: '17px',
      color: 'rgb(181,181,181)',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#6a6d77',
    },
    '& ::placeholder': {
      color: 'rgb(181,181,181)',
      opacity: 1 /* Firefox */,
    },
    '& :-ms-input-placeholder': {
      color: 'rgb(181,181,181)',
      opacity: 1 /* Firefox */,
    },
    '& ::-ms-input-placeholder': {
      color: 'rgb(181,181,181)',
      opacity: 1 /* Firefox */,
    },
  },
  countryList: {
    color: 'rgb(181,181,181)',
  },
  input: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: 0,
    },
    '& .MuiSvgIcon-root': {
      fill: 'rgb(181,181,181)',
    },
  },
  welcomeText: {
    fontSize: '2.2vmax',
    fontFamily: 'Avenir-Black',
    fontWeight: '800',
    color: 'white',
  },
  errorMessage: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2vmax',
    color: 'red',
    marginTop: '1.5vmax',
  },
  rootInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    flex: 1,
  },
  nextButton: {},
  welcomeTextContainer: {
    display: 'flex',
    flex: 0.26,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flex: 0.4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInput: {
    // display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
}));
