/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import useStyles from './css/TripCardCss';
import GreenDot from '../../../assets/svg/greenDot.svg';
import YellowDot from '../../../assets/svg/yellowDot.svg';

export default function Tripcard(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.tripCardContainer} onClick={props.onClick}>
        <div className={classes.tripContainer}>
          <div className={classes.tripStartContainer}>
            <img src={GreenDot} alt="nothing" style={{ height: '1.2vmax', width: '1.2vmax' }} />
            <span className={classes.tripLabel}>Start:</span>
            <span className={classes.tripText}>{props.tripStartAddress}</span>
          </div>
          <div className={classes.tripEndContainer}>
            <img src={YellowDot} alt="nothing" style={{ height: '1.2vmax', width: '1.2vmax' }} />
            <span className={classes.tripLabel}>End: </span>
            <span className={classes.tripText}>{props.tripEndAddress}</span>
          </div>
        </div>
        <div>
          <span className={classes.tripDate}>{props.tripDate}</span>
          <div className={classes.tripTimeTaxiNoContainer}>
            <span className={classes.tripTime}>{props.tripDuration}</span>
            <div className={classes.taxiNoContainer}>
              <span className={classes.taxiNoLabel}>Taxi No: </span>
              <span className={classes.taxiNoText}>{props.taxiNo}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.divider} />
    </div>
  );
}
