import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: 'black',
    minHeight: '100vh',
  },

  cardBg: {
    display: 'flex',
    flex: 1,
    minHeight: '87vh',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#313131',
    marginTop: '20px',
    borderRadius: '16px 16px 0 0',
  },
  rideButton: {
    minWidth: 'auto',
    marginTop: '16px',
    marginBottom: '20px',
    width: '322px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  passengerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '185px',
    width: '100%',
    backgroundColor: '#000000',
    '& #innerBox': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '317px',
      height: '156px',
      '& #innerBox-1': {
        display: 'flex',
        flexDirection: 'column',
        '& #innerBox-1-date-distance': {
          display: 'flex',
          flexDirection: 'column',
          '& #innerBox-1-date-distance-date': {
            fontSize: 12,
            color: '#FFFD00',
            fontWeight: 'bold',
          },
          '& #innerBox-1-date-distance-dist': {
            fontSize: 12,
            color: '#B5B5B5',
          },
        },
      },
      '& #innerBox-2': {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        '& #innerBox-2-taxi-no': {
          display: 'flex',
          alignItems: 'flex-start',
          '& #innerBox-2-taxi-no-text-1': {
            fontSize: 12,
            color: '#FFFD00',
            fontWeight: 'bold',
          },
          '& #innerBox-2-taxi-no-text-2': {
            fontSize: 12,
            color: '#B5B5B5',
          },
        },
        '& #innerBox-2-rating-profile': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          '& #innerBox-2-rating-profile-image-container': {
            marginBottom: '8px',
            width: '60px',
            height: '60px',
            borderRadius: 100,
            borderWidth: 2,
            borderColor: '#FFFFFF',
            borderStyle: 'solid',
            '& img': {
              height: '100%',
              width: '100%',
            },
          },
          '& #innerBox-2-rating-profile-text': {
            fontSize: 14,
            color: '#FFFFFF',
            fontFamily: 'Avenir Next',
          },
          '& #innerBox-2-rating-profile-rating': {
            '& .MuiRating-iconFilled': {
              color: '#FFFD00',
            },
          },
        },
      },
    },
  },
  imageMapContainer: {
    height: '140px',
    width: '100%',
    '& img': {
      width: '100%',
    },
  },
  switchContainer: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  errorContainer: {
    margin: '4vh',
    textAlign: 'center',
    '& p': {
      fontSize: 20,
      marginBottom: 50,
    },
  },
}));
