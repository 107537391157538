import React from 'react';

import useStyles from './css/InvoiceCardCss';

export default function InvoiceCard(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.invoiceContainer}>
        <div className={classes.invoiceHeaderContainer}>
          <span className={classes.invoiceHeaderText}>Yello Cab Receipt</span>
        </div>
        <div className={classes.invoiceListItemContainer}>
          <span className={classes.invoiceListItemText}>Pass charges</span>
          <span className={classes.invoiceListItemText}>{props.passCharges}</span>
        </div>
        <div className={classes.invoiceListItemContainer}>
          <span className={classes.invoiceListItemText}>Service Tax</span>
          <span className={classes.invoiceListItemText}>{props.serviceTax}</span>
        </div>
        <div className={classes.invoiceListItemContainer}>
          <span className={classes.invoiceListItemText}>Discount</span>
          <span className={classes.invoiceListItemText}>{props.discount}</span>
        </div>
        <div className={classes.invoiceListItemContainer} id="transactionContainer">
          <span className={classes.invoiceListItemText}>Transaction Charges</span>
          <span className={classes.invoiceListItemText}>{props.transactionCharge}</span>
        </div>

        <div className={classes.divider} />

        <div className={classes.invoiceListItemContainer}>
          <span className={classes.invoiceListItemTextBold}>Total</span>
          <span className={classes.invoiceListItemTextBold}>{props.totalAmt}</span>
        </div>
        <div className={classes.invoiceListItemContainer}>
          <span className={classes.invoiceListItemTextBold}>Paid Using</span>
          <span className={classes.invoiceListItemTextBold}>{props.paidUsing}</span>
        </div>
      </div>
    </div>
  );
}
