/* eslint-disable react/jsx-indent */
/* eslint-disable no-useless-escape */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useMemo } from 'react';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import { useHistory, useLocation } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import navigationHandler from '../../App/helpers/NavigationHandler';
import { purchaseBoardingPass, createPayment } from '../../../Actions';
import PaymentSuccessModal from '../screens/PaymentSuccessModal';
import { firebase } from '../../../config/firebaseConfig';
import useStyles from '../css/PaymentMethodCss';
import countryList from '../../Login/helpers/countryList';
import ErrorModal from '../../../Components/modal/ErrorModal';

export default function CheckoutForm(props) {
  const classes = useStyles();
  const [succeeded, setSucceeded] = useState(false);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(null);
  const [cardValidation, setCarValidation] = useState({
    cardExpiry: false,
    cardCvc: false,
    cardNumber: false,
  });
  const [stripeUserInfo, setStripeUserInfo] = useState({
    name: '',
    city: '',
    country: 'US',
    line1: '',
    postalCode: '',
    state: '',
  });
  const [processing, setProcessing] = useState('');
  // const [name, setName] = useState('');
  // const [loader, setLoader] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const location = useLocation();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [errorOnMutation, setErrorOnMutation] = useState(false);

  // This is used for payment button which we are not going to use as of now
  // useEffect(() => {
  //   console.log('CheckoutForm -> location', location);
  //   if (stripe) {
  //     const pr = stripe.paymentRequest({
  //       country: 'US',
  //       currency: 'usd',
  //       total: {
  //         label: 'Purchasing',
  //         amount: props.state.amountToPay * 100,
  //       },
  //       requestPayerName: true,
  //       requestPayerEmail: true,
  //     });

  //     // Check the availability of the Payment Request API.
  //     pr.canMakePayment()
  //       .then((result) => {
  //         console.log('CheckoutForm -> result', result);
  //         if (result && result !== null) {
  //           setPaymentRequest(pr);
  //         } else {
  //           setError('Payment failed, User cannot make payment');
  //         }
  //       })
  //       .catch((err) => {
  //         console.log('CheckoutForm -> err', err);
  //       });
  //   }
  // }, [stripe]);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    console.log("props.state", props.state)
    
    if (!props.state) {
      navigationHandler(history, '/plans-page');
    } else {
      purchaseBoardingPass(props.state.plan_id, props.state.coupon_code)
        .then((res) => {
          console.log("hello",res)
          createPayment(res.data.PurchaseBoardingPass.order_id, 'usd')
            .then((res1) => {
              console.log("res1", res1)
              setClientSecret(res1.data.CreatePayment.client_secret);
              setLoader(false);
            })
            .catch((err) => {
              console.log("err", err)
              setLoader(false);
              setErrorOnMutation(true);
            });
        })
        .catch((err) => {
          console.log("err", err)
          setLoader(false);
          setErrorOnMutation(true);
        });
    }
  }, []);

  const handleClose = () => {
    setTimeout(() => {
      setSucceeded(false);
      navigationHandler(history, '/home', '');
    }, 500);
  };

  const cardStyle = {
    style: {
      base: {
        color: '#fffd00',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#fffd00',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  const cardStyle1 = {
    style: {
      base: {
        color: '#fffd00',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#fffd00',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    placeholder: 'CARD NUMBER',
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setCarValidation({ ...cardValidation, [event.elementType]: event.complete });
    setError(event.error ? event.error.message : '');
  };

  const nameRegex = /^[A-Za-z\'\s\.\-]+$/;
  const cityRegex = /^[A-Za-z\'\s]+$/;
  const zipRegex = /^\d+$/;

  const isAllFieldValid =
    nameRegex.test(stripeUserInfo.name) &&
    cityRegex.test(stripeUserInfo.state) &&
    cityRegex.test(stripeUserInfo.city) &&
    zipRegex.test(stripeUserInfo.postalCode);

  const isCardInfoValid =
    cardValidation.cardCvc &&
    cardValidation.cardExpiry &&
    cardValidation.cardNumber &&
    Object.keys(stripeUserInfo).every((k) => !!stripeUserInfo[k]) &&
    isAllFieldValid;

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const { name, city, country, line1, postalCode, state } = stripeUserInfo;

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name,
          address: {
            city,
            country,
            line1,
            state,
            postal_code: postalCode,
          },
        },
      },
    });

    if (payload.error) {
      setError(`Payment failed, ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);

      if (location.param && location.param.newUser) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((getIdTokenResult) => {
            const idToken = getIdTokenResult.token;
            localStorage.setItem('token', idToken);
            setSucceeded(true);
          })
          .catch((err) => {
            console.log('CheckoutForm -> err', err);
          });
      } else {
        setSucceeded(true);
      }
    }
  };
  const handleErrorClose = () => {
    // history.location.push('/plans-page');
    history.push('/plans-page');
  };
  return (
    <>
      {errorOnMutation && (
        <ErrorModal
          openModal={errorOnMutation}
          headerText="Oops!! Something went wrong"
          title="Procced"
          handleClose={handleErrorClose}
        />
      )}
      {loader ? (
        <div className={classes.innerContainer}>
          <label className={classes.loadingLabel}>Loading...</label>
        </div>
      ) : (
        <form id="payment-form" onSubmit={handleSubmit}>
          <input
            id="card-element"
            className="cardholderInput"
            placeholder="CARDHOLDER NAME"
            required
            value={stripeUserInfo?.name}
            style={{ fontSize: '16px' }}
            onChange={(e) => setStripeUserInfo({ ...stripeUserInfo, name: e.target.value })}
          />

          <input
            id="card-element"
            className="cardholderInput"
            placeholder="ADDRESS"
            required
            value={stripeUserInfo?.line1}
            onChange={(e) => setStripeUserInfo({ ...stripeUserInfo, line1: e.target.value })}
          />

          <input
            id="card-element1"
            className="card-element"
            placeholder="CITY"
            required
            value={stripeUserInfo?.city}
            onChange={(e) => setStripeUserInfo({ ...stripeUserInfo, city: e.target.value })}
          />

          <input
            id="card-element2"
            className="card-element"
            placeholder="STATE"
            required
            value={stripeUserInfo?.state}
            onChange={(e) => setStripeUserInfo({ ...stripeUserInfo, state: e.target.value })}
          />

          <select
            label="COUNTRY"
            id="card-element1"
            style={{ fontSize: '16px' }}
            required
            value={stripeUserInfo?.country}
            onChange={(e) => {
              setStripeUserInfo({ ...stripeUserInfo, country: e.target.value });
            }}
          >
            {countryList.map((c) => (
              <option id="card-element1" value={c.countryCode}>
                {c.name}
              </option>
            ))}
          </select>

          <input
            id="card-element2"
            className="card-element"
            placeholder="ZIP"
            // style={{ width: '75%' }}
            required
            value={stripeUserInfo?.postalCode}
            onChange={(e) => setStripeUserInfo({ ...stripeUserInfo, postalCode: e.target.value })}
          />
          <CardNumberElement id="card-element" options={cardStyle1} onChange={handleChange} />
          <CardExpiryElement id="card-element1" options={cardStyle} onChange={handleChange} />
          <CardCvcElement id="card-element2" options={cardStyle} onChange={handleChange} />
          {/* {paymentRequest ? (
            <PaymentRequestButtonElement id="card-element" options={{ paymentRequest }} />
          ) : null} */}
          <button disabled={!isCardInfoValid} id="submit" className="payButton">
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner" />
              ) : (
                `Pay $${parseFloat(props.state.amountToPay).toFixed(2)}`
              )}
            </span>
          </button>
          {/* Show any error that happens when processing the payment */}

          {/* Show a success message upon completion */}
          <PaymentSuccessModal
            openModal={succeeded}
            handleClose={handleClose}
            planName={props.state ? props.state.plan_name : ''}
            validity={props.state ? props.state.plan_validity : ''}
            // isTip={!!props.state.ride_id}
            // state={props.state}
            // amountToPay={props.state.amountToPay}
          />
          {/* <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded, see the result in your
        <a
          href="https://dashboard.stripe.com/test/payments"
        >
          {" "}
          Stripe dashboard.
        </a>
        {' '}
        Refresh the page to pay again.
      </p> */}
        </form>
      )}
    </>
  );
}
