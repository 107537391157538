/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// importing UI Components
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PhoneInput from 'react-phone-input-2';

// importing helpers
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Div100vh from 'react-div-100vh';
import YelloLogo from '../../../assets/svg/yelloLogo.svg';
import useStyles from '../css/loginPageCSS';

// import navigationHandler from '../../App/helpers/NavigationHandler';
import { firebase } from '../../../config/firebaseConfig';
import countryList from '../helpers/temp';
// import loginIcon from '../../../assets/svg/loginIcon.svg';

// importing Screens
import PrimaryButton from '../../../Components/buttons/PrimaryButton';
import ModalBox from '../../../Components/modal/Modal';

export default function Login() {
  const history = useHistory();
  const [number, setNumber] = React.useState('');
  const [error, setError] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [code, selectCode] = React.useState('+91');
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const svgPath = `${YelloLogo}#svgView(preserveAspectRatio(none))`;

  React.useEffect(() => {
    // localStorage.setItem('token', 'token');
    // history
    if (history.location.search.replace('?', '') === 'isBlock=true') {
      setOpen(true);
      history.replace('/login');
    }
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
        console.log(response);
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
      },
    });
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  }, []);

  const countryListWithCode = useMemo(
    () =>
      countryList.sort(
        (a, b) => parseInt(a.dial_code?.replace('+', '')) - parseInt(b.dial_code?.replace('+', '')),
      ),
    [countryList],
  );

  const handleAuth = async () => {
    if (number.length >= 8 && number.length <= 14) {
      setLoader(true);
      const appVerifier = window.recaptchaVerifier;
      if (number) {
        const temp = `${code}${number}`;
        window.confirmationResult = await firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(function () {
            // Existing and future Auth states are now persisted in the current
            // session only. Closing the window would clear any existing state even
            // if a user forgets to sign out.
            // ...
            // New sign-in will be persisted with session persistence.
            return firebase
              .auth()
              .signInWithPhoneNumber(temp, appVerifier)
              .then((res) => {
                setLoader(false);
                setError(null);
                history.push({
                  pathname: '/verifyotp',
                  param: {
                    code,
                    number,
                    verificationid: res.verificationId,
                    confirmationResult: res,
                  },
                });
                // navigationHandler(history, '/verifyotp', null, {

                // });
              })
              .catch((err) => {
                setLoader(false);
                switch (err.code) {
                  case 'auth/invalid-phone-number':
                    setError('Invalid phone number, please enter a valid number');
                    break;
                  case 'auth/invalid-verification-code':
                    setError('Wrong Verification code. Try Again');
                    break;
                  default:
                    setError('Something went wrong');
                    break;
                }
              });
          })
          .catch(function (err) {
            // Handle Errors here.
            const errorCode = err.code;
            // const errorMessage = err.message;
            setLoader(false);
            setError(errorCode);
          });
      }
    } else {
      setError('Enter a valid number');
    }
  };
  return (
    <Div100vh>
      <Grid container className={classes.root}>
        <div id="recaptcha-container" style={{ visibility: 'hidden' }} />
        <div className={classes.rootInnerContainer}>
          <div
            style={{
              display: 'flex',
              flex: 0.6,
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              backgroundImage: `url(${YelloLogo})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'left center',
              backgroundSize: 'auto 120%',
              height: 'auto',
              width: '100%',
            }}
          >
            {/* <img src={YelloLogo} style={{}} alt="nothing" /> */}
          </div>
          <div className={classes.cardContainer}>
            <Grid container className={classes.cardInnerContainer}>
              <Grid className={classes.welcomeTextContainer}>
                <Typography className={classes.welcomeText}>
                  Login to experience world-class luxurious cab service.
                </Typography>
              </Grid>
              <Grid className={classes.inputNumberContainer}>
                <div className={classes.textInput}>
                  <div className={classes.input}>
                    <Select
                      labelId="countries"
                      id="countries"
                      displayEmpty
                      value={code}
                      onChange={(t) => {
                        selectCode(t.target.value);
                      }}
                      IconComponent={() => <KeyboardArrowDownIcon />}
                      renderValue={() => code}
                      className={classes.countryList}
                    >
                      {countryListWithCode.map((c) => (
                        <MenuItem value={c.dial_code}>{c.dial_code}</MenuItem>
                      ))}
                    </Select>
                    {/* <PhoneInput
                      country="us"
                      value={code}
                      onChange={(phone) => {
                        console.log(phone);
                        selectCode(phone);
                      }}
                    /> */}
                    <Input
                      fullWidth
                      type="number"
                      className={{ display: 'flex', flex: 1, fontSize: '16px' }}
                      placeholder="Enter your mobile number"
                      onChange={(e) => {
                        setNumber(e.target.value);
                      }}
                      // inputProps={{ 'aria-label': 'description' }}
                    />
                  </div>
                  {error ? (
                    <div className={classes.errorMessage}>
                      <span>{error}</span>
                    </div>
                  ) : null}
                </div>
              </Grid>
              <Grid className={classes.buttonContainer}>
                <PrimaryButton
                  type="primary"
                  variant="fullWidth"
                  className={classes.nextButton}
                  onClick={() => {
                    handleAuth();
                  }}
                  Title={loader ? 'Loading' : 'Next'}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {open && (
          <ModalBox
            Title="User Block"
            message="You are blocked by admin, please contact them to proceed"
            closeModal={() => setOpen(false)}
          />
        )}
      </Grid>
    </Div100vh>
  );
}
