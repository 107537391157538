
const slackAxios = async () => {
  // return Axios.post(
  //   'https://backend.sanchitsahu.com/blockhour/node/api/v1/users/slack',
  //   {
  //     text: JSON.stringify(data),
  //   },
  //   {
  //     'Access-Control-Allow-Origin': '*',
  //   },
  // );
};

export default slackAxios;
