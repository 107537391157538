/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable global-require */
import React from 'react';
import moment from 'moment';
import useStyles from './css/promoCodeCardCss';

export default function PromoCodeCard(props) {
  const classes = useStyles();

  return (
    <div className={classes.cardContainer} onClick={(e) => props.onClick(props.code)}>
      <div className={classes.innerCardContainer}>
        <div className={classes.switchTextContainer}>
          <div className={classes.textContainer}>
            <span>{props.description === '' ? 'Enjoy' : props.description}</span>
          </div>
          <div className={classes.promoContainer}>
            <span>{props.code}</span>
          </div>
        </div>
        <div className={classes.switchTextContainer}>
          <div className={classes.dateContainer}>
            <span>
              Expires:
              {moment(props.expires).format('MM/DD/YYYY')}
            </span>
          </div>
          <div className={classes.notifInfoContainer}>
            {/* <span className={classes.notifInfoText}>{props.notificationInfoText}</span> */}
            <span className={classes.notifInfoText}>*condition apply</span>
          </div>
        </div>
      </div>
    </div>
  );
}
