import { gql } from '@apollo/client';
import client from '../Containers/App/config/ApolloClient';

export const createSOSRequest = (rideId, userid, driverid, location) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation($ride_id: uuid!, $user_id: uuid!, $loc: point!, $driverid: uuid!) {
            insert_yt_sos_request_one(
              object: {
                ride_id: $ride_id
                user_id: $user_id
                location: $loc
                driver_user_id: $driverid
              }
            ) {
              id
              updated_at
              note
            }
          }
        `,
        variables: {
          ride_id: rideId,
          user_id: userid,
          loc: location,
          driverid,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const LookForRequest = (rideid, userid, driverid) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query MyQuery($rideid: uuid!, $userid: uuid!, $driverid: uuid!) {
            yt_sos_request(
              where: {
                ride_id: { _eq: $rideid }
                user_id: { _eq: $userid }
                driver_user_id: { _eq: $driverid }
              }
              order_by: { updated_at: desc }
              limit: 1
            ) {
              id
              driver_user_id
              location
              ride_id
            }
          }
        `,
        variables: {
          rideid,
          userid,
          driverid,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
