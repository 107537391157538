import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: 'black',
    minHeight: '100vmax',
  },

  cardBg: {
    display: 'flex',
    flex: 1,
    minHeight: '85vmax',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(rgb(49,49,49), rgb(31,31,30))',
    marginTop: '20px',
    borderRadius: '16px 16px 0 0',
  },
  rideButton: {
    minWidth: 'auto',
    marginTop: '2vmax',
    marginBottom: '2vmax',
    width: '85vmin',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  passengerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '25vmax',
    width: '100%',
    backgroundColor: '#000000',
    '& #innerBox': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '90%',
      overflow: 'auto',
      height: '85%',
      '& #innerBox-1': {
        display: 'flex',
        width: '60%',
        flexDirection: 'column',
        '& #innerBox-1-dot-route': {
          display: 'flex',
          flexDirection: 'row',

          paddingTop: '2vmax',
          '& .innerBox-1-dot-route-timeline': {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '3vmin',
            justifyContent: 'space-between',

            '& .innerBox-1-dot-route-start': {
              color: 'white',
              fontSize: '1.7vmax',
              fontFamily: 'AvenirNext-Regular',
              marginBottom: '4vmax',
            },
            '& .innerBox-1-dot-route-end': {
              color: 'white',
              fontSize: '1.7vmax',
              fontFamily: 'AvenirNext-Regular',
            },
          },
        },
        '& #innerBox-1-date-distance': {
          display: 'flex',
          flexDirection: 'column',
          '& #innerBox-1-date-distance-date': {
            fontSize: '1.5vmax',
            color: '#FFFD00',
            fontWeight: 'bold',
          },
          '& #innerBox-1-date-distance-dist': {
            fontSize: '1.5vmax',
            color: '#B5B5B5',
          },
        },
      },
      '& #innerBox-2': {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        '& #innerBox-2-taxi-no': {
          display: 'flex',
          alignItems: 'flex-start',
          '& #innerBox-2-taxi-no-text-1': {
            fontSize: '1.5vmax',
            color: '#FFFD00',
            fontWeight: 'bold',
          },
          '& #innerBox-2-taxi-no-text-2': {
            fontSize: '1.5vmax',
            color: '#B5B5B5',
          },
        },
        '& #innerBox-2-rating-profile': {
          marginBottom: '2vmax',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          '& #innerBox-2-rating-profile-image-container': {
            marginBottom: '1vmax',
            width: '7vmax',
            height: '7vmax',
            borderRadius: 100,
            borderWidth: 2,
            borderColor: '#FFFFFF',
            borderStyle: 'solid',
            '& img': {
              height: '100%',
              width: '100%',
            },
          },
          '& #innerBox-2-rating-profile-text': {
            fontSize: '1.7vmax',
            color: '#FFFFFF',
            fontFamily: 'Avenir Next',
          },
          '& #innerBox-2-rating-profile-rating': {
            '& .MuiRating-iconFilled': {
              color: '#FFFD00',
              fontSize: '3vmax',
            },
          },
        },
      },
    },
  },
  mapSmall: {
    width: '2.5vmin',
    height: '10vmax',
  },
  mapSmallContainer: {
    marginTop: '0.5vmax',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  imageMapContainer: {
    height: '17vmax',
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  imageMapContainerMap: {
    height: '17vmax',
    width: '100%',
    '& img': {
      width: '100%',
      height: '200%',
    },
  },
  rateReviewCardContainer: {
    marginTop: '2vmax',
    height: '15vmax',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rateReviewCard: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
  },
  profilePicContainer: {
    width: '19vmin',
    display: 'flex',
    alignItems: 'center',
  },
  driverNameText: {
    fontSize: '1.7vmax',
    color: '#FFFFFF',
    fontFamily: 'Avenir-Black',
  },
  driverTaxiLabel: {
    fontWeight: '700',
    fontSize: '1.5vmax',
    color: '#FFFFFF',
  },
  driverTaxiNumber: {
    fontWeight: '400',
    fontSize: '1.5vmax',
    color: '#FFFFFF',
  },
  ratings: {
    marginTop: '0.8vmax',
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  driverRatingContainer: {
    display: 'flex',
    width: '45vmin',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rateButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '26vmin',
  },
  rateButton: {
    minWidth: '5vmin',
    borderRadius: '30px',
    height: '2.4vmax',
    fontSize: '1.2vmax',
    color: 'white',
    borderWidth: '3px',
    padding: '3vmin 2.5vmin',
    borderColor: 'rgb(123,35,184)',
  },
  profilePic: {
    height: '18vmin',
    width: '18vmin',
    borderRadius: '30px',
  },
}));
