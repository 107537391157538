import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  ButtonedTabContainer: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    width: '85%',
    height: '60vmax',
    overflow: 'auto',
    // height: '470px',
    backgroundColor: '#000000',
    borderRadius: '30px 30px 8px 8px',
    // marginBottom: '35px',
  },
  buttonsContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  button1: {
    width: '50%',
    minWidth: 0,
    color: 'rgb(255,255,255)',
    backgroundColor: 'rgb(0,0,0)',
    height: '6vmax',
    borderRadius: '30px 0px 0px 0px',
    '&:focus': {
      backgroundColor: 'rgb(48, 47, 47)',
    },
  },
  button2: {
    width: '50%',
    minWidth: 0,
    height: '6vmax',
    backgroundColor: 'rgb(0,0,0)',
    color: 'rgb(255,255,255)',
    borderRadius: '0px 30px 0px 0px',
    '&:focus': {
      backgroundColor: 'rgb(48, 47, 47)',
    },
  },
  buttonsDivider: {
    height: '4vmax',
    width: '1vmin',
    backgroundColor: 'rgb(112,112,112)',
  },
  children: {
    // padding: '5px',
    overflow: 'auto',
  },
}));
