/* eslint-disable max-len */
/* eslint-disable global-require */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// eslint-disable-next-line no-unused-vars
const Loader = (props) => {
  const useStyles = makeStyles(() => ({
    loaderDiv: {
      width: '100%',
      textAlign: 'center',
    },
    loader: {
      color: props.color || 'rgba(255, 253, 0, 1)',
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.loaderDiv}>
      <CircularProgress className={classes.loader} color="inherit" />
    </div>
  );
};

export default Loader;

// git checkout -b dev-usama
