import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2vmax',
    height: '40vmax',
    width: '90%',
    backgroundColor: 'rgb(0,0,0)',
    // marginBottom: 20,
  },
  innerBox: {
    height: '90%',
    width: '90%',
  },
  cardsContainer: {
    overflow: 'auto',
    height: 'inherit',
  },
  arrowCardContainer: {
    padding: '0.7vmax 0',
    display: 'flex',
    flexDirection: 'column',
  },
  textArrowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  divider: {
    height: '0.1vmax',
    width: '95%',
    margin: 'auto',
    marginTop: '1vmax',
    backgroundColor: 'rgb(112,112,112)',
  },
  cardHeader: {
    color: 'rgb(255,253,0)',
    fontFamily: 'Avenir-Black',
    fontSize: '2.1vmax',
    fontWeight: 'bold',
    paddingBottom: '1.8vmax',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(255,255,255)',
    fontFamily: 'Avenir-Black',
    fontSize: '1.95vmax',
    fontWeight: 'bold',
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rightArrow: {
    height: '2vmax',
    width: '2.5vmin',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    height: '27vmax',
    width: '80vmin',
    borderRadius: '15px',
    padding: '1vmax',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundImage: 'linear-gradient(to bottom, rgb(49,49,49), rgb(31,31,30))',
  },
  headerText: {
    color: 'rgb(255,253,0)',
    fontFamily: 'Avenir-Black',
    textAlign: 'center',
    fontSize: '2.2vmax',
    fontWeight: 'bold',
    // width: '90%',
  },
  paragraphText: {
    color: 'white',
    fontFamily: 'Avenir-Book',
    textAlign: 'center',
    fontSize: '1.8vmax',
    width: '90%',
  },
  verifiedIcon: {
    marginBottom: '2.2vmax',
    height: '6vmax',
  },
  planButton: {
    width: '70%',
    marginTop: '2vmax',
  },
}));
