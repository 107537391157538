import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    height: 'calc(var(--vh, 1vh) * 100) !important',
    backgroundColor: 'black',
  },
  notifButton1: {
    border: 'none',
    background: 'transparent',
    color: 'rgb(255,253,0)',
    outline: 'none !important',
    boxShadow: 'none !important',
  },

  cardBg: {
    display: 'flex',
    flex: 0.8,
    backgroundColor: '#313131',

    marginTop: '20px',
    padding: '25px',
    flexDirection: 'column',
    borderRadius: '16px 16px 0 0',
    overflowY: 'scroll',
  },
  notifButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    marginBottom: '1vh',
    color: 'rgb(255,253,0)',
  },

  singleRide: {
    margin: '10px',
    background: '#000',
    borderRadius: 8,
    overflow: 'hidden',
  },
  singleRideInner: {
    display: 'flex',
    alignItems: 'center',

    flexFlow: 'row wrap',
  },
  rideMap: {
    minWidth: '79px',
    maxWidth: '79px',
    height: '95px',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  detailRide: {
    padding: '20px 16px 10px',
  },
  options: {
    margin: '12px',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 10,
    justifyContent: 'flex-around',
  },
}));
