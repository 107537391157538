import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  cardContainer: {
    height: 'calc(var(--vh, 1vh) * 12)',

    minHeight: 'calc(var(--vh, 1vh) * 12)',
    display: 'flex',
    marginBottom: '2.5vmax',
    width: '100%',
    borderRadius: '8px',
    backgroundColor: 'rgb(0, 0, 0)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1.3vmax 0 1.3vmax 0',
  },
  textContainer: {
    fontSize: '1.8vmax',
    color: 'white',
    fontFamily: 'Avenir-Black',
    fontWeight: '900',
  },
  dateContainer: {
    fontSize: '1.7vmax',
    color: 'rgb(181 181 181)',
    fontFamily: 'Avenir-Black',
    fontWeight: '900',
  },
  switchTextContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: 'center',
    alignItems: 'baseline',
  },
  innerCardContainer: {
    display: 'flex',
    width: '90%',
    height: '90%',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  notifInfoText: {
    fontSize: '1.4vmax',
    color: 'rgb(181 181 181)',
    fontFamily: 'Avenir-Book',
    fontWeight: '600',
    textAlign: 'justify',
  },
  notifInfoContainer: {
    paddingTop: '1vmax',
  },
  switchContainer: {
    width: '35%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  promoContainer: {
    fontSize: '1.8vmax',
    color: 'rgb(255 253 0)',
    fontFamily: 'Avenir-Black',
    fontWeight: '900',
  },
}));
