export default [
  {
    name: 'United States',
    Iso: '+1',
    countryCode: 'US',
  },
  {
    name: 'United Kingdom',
    Iso: '+44',
    countryCode: 'GB',
  },
  {
    name: 'Thailand',
    Iso: '+66',
    countryCode: 'TH',
  },
  {
    name: 'Turkey',
    Iso: '+90',
    countryCode: 'TR',
  },
  {
    name: 'Sweden',
    Iso: '+46',
    countryCode: 'SE',
  },
  {
    name: 'Switzerland',
    Iso: '+41',
    countryCode: 'CH',
  },
  {
    name: 'Spain',
    Iso: '+34',
    countryCode: 'ES',
  },
  {
    name: 'Sri Lanka',
    Iso: '+94',
    countryCode: 'LK',
  },
  {
    name: 'South Africa',
    Iso: '+27',
    countryCode: 'ZA',
  },
  {
    name: 'Singapore',
    Iso: '+65',
    countryCode: 'SG',
  },
  {
    name: 'Russia',
    Iso: '+7',
    countryCode: 'RU',
  },
  {
    name: 'Portugal',
    Iso: '+351',
    countryCode: 'PT',
  },
  {
    name: 'Pakistan',
    Iso: '+92',
    countryCode: 'PK',
  },
  {
    name: 'Australia',
    Iso: '+61',
    countryCode: 'AU',
  },
  {
    name: 'Austria',
    Iso: '+43',
    countryCode: 'AT',
  },

  {
    name: 'Bahamas',
    Iso: '+1242',
    countryCode: 'BS',
  },
  {
    name: 'India',
    Iso: '+91',
    countryCode: 'IN',
  },
];
