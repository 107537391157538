/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import useStyles from '../css/ListArrowCardCss';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import { getIssues, setRideIssue } from '../../../Actions/Profile';
import Loader from '../../../Components/loader/Loader';

const ArrowCard = (props) => {
  const classes = useStyles();
  const svgPath = (iconName) => `${iconName}#svgView(preserveAspectRatio(none))`;
  // eslint-disable-next-line react/prop-types
  return (
    <div
      className={classes.arrowCardContainer}
      onClick={() => {
        const { rideId, issueId, driver_user_id, issue, setSuccess } = props;
        setRideIssue(rideId, issueId, driver_user_id, issue)
          .then((res) => {
            setSuccess(true);
          })
          .catch((err) => {
            console.log('setRideIssue=>err', err);
          });
      }}
    >
      <div className={classes.textArrowContainer}>
        <div className={classes.textContainer}>
          <span>{props.issue}</span>
        </div>
        <div className={classes.arrowContainer}>
          <img src={RightArrowIcon} alt="nada" className={classes.rightArrow} />
        </div>
      </div>
      <div className={classes.divider} />
    </div>
  );
};

const ListArrowCard = (props) => {
  const classes = useStyles();
  const [issues, setIssues] = useState([]);
  const [loader, setLoader] = useState(true);
  // eslint-disable-next-line react/prop-types
  useEffect(() => {
    getIssues()
      .then((res) => {
        setIssues(res);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);
  return (
    <Grid className={classes.root}>
      <div className={classes.innerBox}>
        <div className={classes.cardHeader}>
          <span>Select the issue you faced </span>
        </div>
        {!loader ? (
          <div className={classes.cardsContainer}>
            {issues.length > 0 &&
              issues.map((item) => {
                if (item.target_apps.rider) {
                  return (
                    <ArrowCard
                      key={item.id}
                      issue={item.description}
                      issueId={item.id}
                      rideId={props.rideData.id}
                      driver_user_id={props.rideData.driver ? props.rideData.driver.id : null}
                      setSuccess={props.setSuccess}
                    />
                  );
                }
              })}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </Grid>
  );
};

export default ListArrowCard;

// git checkout -b dev-usama
