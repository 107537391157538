/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-fragments */
/* eslint-disable max-len */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import HamburgerIcon from '../../assets/svg/Hamburger.svg';

const useStyles = makeStyles(() => ({
  hemburgerBtn: {
    margin: '2vmax 0 0 1vmax',
    border: 'none',
    background: 'transparent',
    '&:focus': {
      outline: 'none',
    },
  },
  hamburgerIcon: {
    height: '6vmax',
    width: '6vmax',
  },
}));

// eslint-disable-next-line no-unused-vars
const HamburgerButton = (props) => {
  const classes = useStyles();
  // console.log(props.type);
  return (
    <button
      ref={props.buttonRef}
      type="button"
      onClick={props.onClick}
      className={classes.hemburgerBtn}
    >
      <img src={HamburgerIcon} alt="nada" className={classes.hamburgerIcon} />
    </button>
  );
};

export default HamburgerButton;
