/* eslint-disable max-len */
/* eslint-disable global-require */
import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// importing UI components
import PrimaryButton from '../buttons/PrimaryButton';
import navigationHandler from '../../Containers/App/helpers/NavigationHandler';

// importing helpers
import useStyles from './css/PassCardCss';

export default function ActivePassCard(props) {
  const classes = useStyles();
  const history = useHistory();

  const renderQrCode = (qrCode) => {
    const url =
      `https://chart.googleapis.com/chart?chs=250x250&cht=qr&` +
      `chl={"confirmation_code":"${qrCode}"`;
    return <img src={url} alt="Qr Code" />;
  };

  return (
    <div className={classes.qrCodeContainer}>
      {props.passDetails ? (
        <>
          <div className={classes.QRPassInfo}>
            <div className={classes.passInfoContainer}>
              <div>
                <span className={classes.passLabelText}>Pass ID: </span>
                <span className={classes.passValueText}>
                  {props.passDetails.pass_number ? props.passDetails.pass_number : ''}
                </span>
              </div>
              <div>
                <span className={classes.passLabelText}>Pass Type: </span>
                <span className={classes.passValueText}>
                  {props.passDetails.plan ? props.passDetails.plan.title : ''}
                </span>
              </div>
            </div>
            <div className={classes.qrImgContainer}>
              {renderQrCode(props.passDetails.qr_code)}
              {/* <img alt="No QR Code" src={svgPath(QrCodeLogo)} /> */}
            </div>
            <div className={classes.passDatesContainer}>
              <div className={classes.passDatesTextContainer}>
                <span className={classes.passDatesTextLabel}>Purchased: </span>
                <span className={classes.passDatesTextValue}>
                  {props.passDetails.purchased_at
                    ? moment(props.passDetails.purchased_at).format('MM/DD/YYYY')
                    : ''}
                </span>
              </div>
              <div className={classes.passDatesTextContainer}>
                <span className={classes.passDatesTextLabel}>Started: </span>
                <span className={classes.passDatesTextValue}>
                  {moment().isBetween(props.passDetails.valid_from, props.passDetails.valid_to)
                    ? moment(props.passDetails.valid_from).format('MM/DD/YYYY')
                    : 'Not started'}
                </span>
              </div>
              <div className={classes.passDatesTextContainer}>
                <span className={classes.passDatesTextLabel}>Renewal: </span>
                <span className={classes.passDatesTextValue}>
                  {props.passDetails.plan
                    ? `${props.passDetails.plan.validity_days} days from start`
                    : ''}
                </span>
              </div>
            </div>
          </div>
          <div className={classes.bookViewButtonContainer}>
            <PrimaryButton
              type="primary"
              className={classes.bookButton}
              Title="Start Trip"
              onClick={() => navigationHandler(history, '/home')}
            />
            <PrimaryButton
              type="primary"
              className={classes.viewButton}
              Title="View Details"
              onClick={() =>
                navigationHandler(history, '/my-passes/pass-details-active', null, {
                  status: 'no',
                  passDetails: props.passDetails,
                })
              }
            />
          </div>
        </>
      ) : null}
    </div>
  );
}
