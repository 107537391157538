/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import React from 'react';

import { useHistory } from 'react-router-dom';

// importing UI helpers
import HeaderTitle from '../../../Components/headertitle/HeaderTitle';
import useStyles from '../css/AccountSettingsCSS';

import ProfilePic from '../../../assets/svg/profilePic2.svg';
import EditProfileIcon from '../../../assets/svg/editIconProfile.svg';

import navigationHandler from '../../App/helpers/NavigationHandler';
import CardRightArrow from '../../../Components/cards/CardRightArrow';
import { getProfileURL, setUser } from '../../../Actions';

export default function AccountSettingsPage() {
  const classes = useStyles();
  const history = useHistory();
  const [profileURl, setProfileURl] = React.useState(null);

  const svgPath = (iconName) => `${iconName}#svgView(preserveAspectRatio(none))`;

  React.useEffect(() => {
    getProfileURL()
      .then((res) => {
        setProfileURl(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className={classes.root}>
      <HeaderTitle title="My Account" backBtn={() => history.goBack()} />
      <div className={classes.cardBg}>
        <div className={classes.innerContainer}>
          <div className={classes.imageContainer}>
            <div>
              <img
                src={profileURl || require('../../../assets/img/profile.png')}
                alt="profile"
                className={classes.imageBox}
              />
            </div>
            <img
              src={EditProfileIcon}
              onClick={() => navigationHandler(history, '/my-profile')}
              alt="nothing"
              className={classes.cameraIcon}
            />
          </div>
          <div className={classes.cardsContainer}>
            <CardRightArrow
              title="Notification Settings"
              onClick={() => navigationHandler(history, '/account-settings/notification-settings')}
            />
            <CardRightArrow
              title="FAQs"
              onClick={() => navigationHandler(history, '/account-settings/faq')}
            />
            <CardRightArrow
              title="User Guide"
              onClick={() => navigationHandler(history, '/account-settings/user-guide')}
            />
            <CardRightArrow
              title="Trip Issues"
              onClick={() => navigationHandler(history, '/account-settings/ride-issues')}
            />
            <CardRightArrow
              title="Legal"
              onClick={() => navigationHandler(history, '/account-settings/legal')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
