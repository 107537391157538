/* eslint-disable global-require */
import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
// importing UI components
import PrimaryButton from '../buttons/PrimaryButton';
import navigationHandler from '../../Containers/App/helpers/NavigationHandler';

// importing helpers
import useStyles from './css/PassCardCss';

export default function ExpiredPassCard(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.qrCodeContainer1}>
      <div className={classes.QRPassInfo1}>
        <div className={classes.passInfoContainer}>
          <div>
            <span className={classes.passLabelText}>Pass ID: </span>
            <span className={classes.passValueText}>
              {props.passDetails.pass_number ? props.passDetails.pass_number : ''}
            </span>
          </div>
          <div>
            <span className={classes.passLabelText}>Pass Type: </span>
            <span className={classes.passValueText}>
              {props.passDetails.plan ? props.passDetails.plan.title : ''}
            </span>
          </div>
        </div>
        <div className={classes.passDatesContainer}>
          <div className={classes.passDatesTextContainer}>
            <span className={classes.passDatesTextLabel}>Purchased: </span>
            <span className={classes.passDatesTextValue}>
              {props.passDetails.purchased_at
                ? moment(props.passDetails.purchased_at).format('MM/DD/YYYY')
                : ''}
            </span>
          </div>
          <div className={classes.passDatesTextContainer}>
            <span className={classes.passDatesTextLabel}>Started: </span>
            <span className={classes.passDatesTextValue}>
              {props.passDetails.valid_from
                ? moment(props.passDetails.valid_from).format('MM/DD/YYYY')
                : ''}
            </span>
          </div>
          <div className={classes.passDatesTextContainer}>
            <span className={classes.passDatesTextLabel}>Renewal: </span>
            <span className={classes.passDatesTextValue}>
              {props.passDetails.valid_to
                ? moment(props.passDetails.valid_to).format('MM/DD/YYYY')
                : ''}
            </span>
          </div>
        </div>
      </div>
      <div className={classes.bookViewButtonContainer}>
        <PrimaryButton
          type="primary"
          className={classes.bookButton}
          Title="Buy Pass"
          onClick={() => navigationHandler(history, '/plans-page')}
        />
        <PrimaryButton
          type="primary"
          className={classes.viewButton}
          Title="View Details"
          onClick={() =>
            navigationHandler(history, '/my-passes/pass-details-expired', null, {
              status: 'no',
              passDetails: props.passDetails,
            })
          }
        />
      </div>
    </div>
  );
}
