/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

// importing UI helpers

import AccordianCard from '../../../../Components/cards/AccordianCard';
import HeaderTitle from '../../../../Components/headertitle/HeaderTitle';
import getCMS from '../../../../Actions/CMS';
import useStyles from '../../css/FAQsCss';

export default function FAQsPage() {
  const classes = useStyles();
  const history = useHistory();

  const [error, setError] = useState(null);
  const [FAQ, setFAQ] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    getCMS('faq_rider')
      .then((res) => {
        setLoader(false);
        setFAQ(res.data.yt_content);
      })
      .catch((err) => {
        setLoader(false);
        setError('Something went wrong');
      });
  }, []);

  const renderFAQ = () => {
    if (FAQ.length > 0) {
      return FAQ.map((sFAQ) => {
        return (
          <div className={classes.accordianCardContainer}>
            <AccordianCard summary={sFAQ.content.data.Q} details={sFAQ.content.data.A} />
          </div>
        );
      });
    }
    return <></>;
  };

  return error !== null ? (
    <div> Something went wrong</div>
  ) : (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          flex: 0.15,
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <HeaderTitle title="FAQs" backBtn={() => history.goBack()} />
      </div>
      <div className={classes.cardBg}>{renderFAQ()}</div>
    </div>
  );
}
