/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable global-require */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import './LeftMenuCard.css';
import { Link, useHistory } from 'react-router-dom';
import navigationHandler from '../../Containers/App/helpers/NavigationHandler';
import Close from '../../assets/svg/close.svg';
import NotificationIcon from '../../assets/svg/notification.svg';
import EditIcon from '../../assets/svg/editIconProfile.svg';
import PlansIcon from '../../assets/svg/plansYello.svg';
import PassesIcon from '../../assets/svg/myPasses.svg';
import TaxiIcon from '../../assets/svg/frontal-taxi-cab.svg';
import SavedPlacesIcon from '../../assets/svg/savedPlaces.svg';
import AccountIcon from '../../assets/svg/accountSettings.svg';
import HelpIcon from '../../assets/svg/littleHelp.svg';
import LogoutIcon from '../../assets/svg/logout.svg';
import { DesubscribeFromRide, DesubscribefromRideLocation, getProfileURL } from '../../Actions';
import client from '../../Containers/App/config/ApolloClient';

// const user = JSON.parse(localStorage.getItem('user'));
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: 'calc(var(--vh, 1vh) * 100)',
    minWidth: '70vw',
  },
}));

// eslint-disable-next-line no-unused-vars
const LeftMenuCard = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [profileURl, setProfileURl] = useState(null);

  React.useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('user'));
    if (userDetails !== undefined || userDetails !== null) {
      setUser(JSON.parse(localStorage.getItem('user')));
    }
    getProfileURL()
      .then((res) => {
        setProfileURl(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log('user datat check', props.userDataCheck);
  return (
    <Grid className={classes.root}>
      <div className="menuCardBg">
        <div style={{ display: 'flex', flex: 0.9, flexDirection: 'column' }}>
          <div className="closeNotifContainer">
            <button className="btnClose" type="button" onClick={props.onClick}>
              <img alt="Close" src={Close} style={{ height: '2vmax' }} />
            </button>
            <Link to="/notifications">
              <img alt="nothing" src={NotificationIcon} style={{ height: '100%' }} />
            </Link>
          </div>
          <div className="profileSection">
            <div className="imgUploadSection">
              <img
                className="profile-image"
                // src={require('../../assets/img/blank.png')}
                src={profileURl || require('../../assets/img/profile.png')}
                style={profileURl ? {} : { objectFit: 'cover' }}
                alt="Profile Img"
              />
              <button
                className="uploadBtn"
                type="button"
                onClick={() => navigationHandler(history, '/my-profile')}
              >
                <img alt="nothing" src={EditIcon} style={{ height: 'calc(var(--vh, 1vh) * 4)' }} />
              </button>
            </div>
            <div className="profileName">
              <h3>
                Welcome Back,
                <span>{user ? user.full_name : ''}</span>
              </h3>
            </div>
          </div>
          <ul className="menuLinks">
            <li className="linkContainer">
              <Link to="/plans-page" className="menu-link-container">
                <div className="iconContainer">
                  <img
                    alt="nothing"
                    src={PlansIcon}
                    style={{ height: '1.8vmax', width: '3.1vmax' }}
                  />
                </div>
                <span>Yello Plans</span>
              </Link>
            </li>
            <li className="linkContainer">
              <Link to="/my-passes" className="menu-link-container">
                <div className="iconContainer">
                  <img
                    alt="nothing"
                    src={PassesIcon}
                    style={{ height: '1.9vmax', width: '3vmax' }}
                  />
                </div>
                <span>My Passes</span>
              </Link>
            </li>
            <li className="linkContainer">
              <Link to="/my-rides" className="menu-link-container">
                <div className="iconContainer">
                  <img alt="nothing" src={TaxiIcon} style={{ height: '2.7vmax', width: '3vmax' }} />
                </div>
                <span>My Rides</span>
              </Link>
            </li>
            <li className="linkContainer">
              <Link to="/my-saved-places" className="menu-link-container">
                <div className="iconContainer">
                  <img
                    alt="nothing"
                    src={SavedPlacesIcon}
                    style={{ height: '3vmax', width: '2.7vmax' }}
                  />
                </div>
                <span>Saved Places</span>
              </Link>
            </li>
            <li className="linkContainer">
              <Link to="/account-settings" className="menu-link-container">
                <div className="iconContainer">
                  <img
                    alt="nothing"
                    src={AccountIcon}
                    style={{ height: '2.5vmax', width: '2.2vmax' }}
                  />
                </div>
                <span>Account Settings</span>
              </Link>
            </li>
            <li className="linkContainer">
              <Link to="/help-desk" className="menu-link-container">
                <div className="iconContainer">
                  <img
                    alt="nothing"
                    src={HelpIcon}
                    style={{ height: '2.5vmax', width: '2.5vmax' }}
                  />
                </div>
                <span>A Little Help Here</span>
              </Link>
            </li>
            <li className="linkContainer">
              <Link
                to="/login"
                onClick={() => {
                  DesubscribeFromRide();
                  DesubscribefromRideLocation();
                  localStorage.clear();
                  localStorage.setItem('firstLoad', true);
                  if (navigator.serviceWorker) {
                    navigator.serviceWorker.getRegistrations().then((registrations) => {
                      registrations.map((registration) => {
                        registration.unregister();
                        return null;
                      });
                      // for (const registration of registrations) {
                      // }
                    });
                  }
                }}
                className="menu-link-container"
              >
                <div className="iconContainer">
                  <img
                    alt="nothing"
                    src={LogoutIcon}
                    style={{ height: '2.5vmax', width: '2.5vmax' }}
                  />
                </div>
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
        <div style={{ display: 'flex', flex: 0.1, alignItems: 'center', justifyContent: 'center' }}>
          <span className="copyright">
            Copyright © 2020 Yello Cab.
            <a style={{ fontSize: '0.6rem' }} href="#!">
              Terms of use.
            </a>
          </span>
        </div>
      </div>
    </Grid>
  );
};

export default LeftMenuCard;

// git checkout -b dev-usama
