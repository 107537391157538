import { gql } from '@apollo/client';

import client from '../Containers/App/config/ApolloClient';
import slackAxios from '../slack';

export default (slug) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query MyQuery($slug: String!) {
            yt_content(where: { slug: { _eq: $slug } }) {
              slug
              content
              title
              id
            }
          }
        `,
        variables: {
          slug,
        },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        slackAxios({ file: 'CMS', err });
        reject(err.toString());
      });
  });
};
