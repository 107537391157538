import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundImage: 'linear-gradient(rgb(49, 49, 49) 50%, rgb(31, 31, 31) 100%) !important',
    border: '2px solid #000',
    boxShadow: theme.shadows[4],
    padding: '10px 0px',
    outline: 'none',
    width: '80%',
    textAlign: 'center',
  },
  title: {
    color: '#fff',
    margin: 2,
    paddingBottom: 10,
    borderBottom: '1px solid #fff',
  },
}));

const ModalWithImg = (props) => {
  const { Title, message, closeModal } = props;
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <div className={classes.paper}>
          <h3 className={classes.title}>{Title}</h3>
          <p>{message}</p>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalWithImg;
