import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // padding: '20px',
    // flex: 1,
    width: '100%',
    height: '54vmax',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0px 0px 8px 8px',
    backgroundColor: 'rgb(0,0,0)',
  },
  invoiceContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '90%',
    width: '85%',
    '& #transactionContainer': {
      marginBottom: 0,
    },
  },
  noTripText: {
    display: 'flex',
    textAlign: 'center',
    color: 'rgb(255,255,255)',
    fontFamily: 'Avenir-Black',
    fontSize: '2.3vmax',
  },
}));
