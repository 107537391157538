import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // paddingBottom: '7vmin',
    width: '100%',
    // height: '100vmax',
    height: 'calc(var(--vh, 1vh) * 100)',
    backgroundColor: 'black',
  },
  cardContainer: {
    display: 'flex',
    flex: 0.4,
    width: '100%',
    // height: 'calc(var(--vh, 1vh) * 40)',
    // height: '35%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardInnerContainer: {
    width: '85%',
    // backgroundColor: 'pink',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  inputNumberContainer: {
    display: 'flex',
    flex: 0.5,
    alignItems: 'center',
    '& .MuiInput-underline:before': {
      borderBottomColor: 'rgb(181,179,179)',
    },
    '& .MuiInputBase-input': {
      fontWeight: '600',
      fontSize: '2vmax',
      color: 'rgb(181,181,181)',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#6a6d77',
    },
  },
  input: {
    width: '100%',
  },
  welcomeText: {
    fontSize: '2.2vmax',
    fontFamily: 'Avenir-Black',
    fontWeight: '800',
    color: 'white',
    textAlign: 'center',
  },
  errorMessage: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2vmax',
    color: 'red',
  },
  rootInnerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'calc(var(--vh, 1vh) * 100)',
  },
  otpInputContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  resendOtpContainer: {
    margin: '1.25vmax',
    width: '100%',
    display: 'flex',
    color: 'white',
    justifyContent: 'flex-end',
    fontFamily: 'Avenir-Black',
    fontSize: '2.1vmax',
  },
  nextButton: {
    // height: '7vmax',
  },
  welcomeTextContainer: {
    display: 'flex',
    flex: 0.2,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
