// This file has purely been created to enhance performance as the mutiple setState hooks
// all over the file reduce the performance and possibly create exhaustive hooks warning.

export const INITIAL_STATE = (props) => {
  return {
    start: null,
    viewPort: {
      latitude: 36.114647,
      longitude: -115.172813,
      zoom: 17,
    },
    end: null,
    multiplePassFound: false,
    passes: null,
    passLoader: false,
    showCode: false,
    searchResultLayer: null,
    DestinationLayer: null,
    result: null,
    stateDrawer: {
      left: false,
    },
    currentLocation: {},
    currentLocationName: 'loading',
    markerVisibility: true,
    vehicles: [],
    dialogState: false,
    error: null,
    rideFound: props.rideStatus,
    rideData: null,
    tempRideData: null,
    angle: 0,
    open: false,
    findingRoute: false,
    currentLocationLayer: null,
  };
};

export function reducer(state, action) {
  switch (action.type) {
    case 'SET_START':
      return { ...state, start: action.payload };
    case 'SET_VIEWPORT':
      return { ...state, viewPort: action.payload };
    case 'SET_END':
      return { ...state, end: action.payload };
    case 'SET_PASS':
      return { ...state, multiplePassFound: action.payload };
    case 'TOGGLE_CODE':
      return { ...state, showCode: action.payload };
    case 'SET_SEARCH_LAYER':
      return { ...state, searchResultLayer: action.payload };
    case 'SET_DESTINATION_LAYER':
      return { ...state, DestinationLayer: action.payload };
    case 'SET_RESULT':
      return { ...state, result: action.payload };
    case 'SET_DRAWER_STATE':
      return { ...state, stateDrawer: action.payload };
    case 'SET_CURRENT_LOCATION':
      return { ...state, currentLocation: action.payload };
    case 'SET_LOCATION_NAME':
      return { ...state, currentLocationName: action.payload };
    case 'SET_VISIBILITY':
      return { ...state, markerVisibility: action.payload };
    case 'SET_VEHICLES':
      return { ...state, vehicles: action.payload };
    case 'SET_DIALOG_STATE':
      return { ...state, dialogState: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'TOGGLE_RIDE_STATE':
      return { ...state, rideFound: action.payload };
    case 'TOGGLE_RIDE_STATE_TO_FALSE':
      return { ...state, rideFound: action.payload };
    case 'SET_RIDE_DATA':
      return { ...state, rideData: action.payload };
    case 'SET_ANGLE':
      return { ...state, angle: action.payload };
    case 'SET_OPEN':
      return { ...state, open: action.payload };
    case 'SET_TEMP_RIDE_DATA':
      return { ...state, tempRideData: action.payload };
    case 'SET_ROUTE_LOADER':
      return { ...state, findingRoute: action.payload };
    case 'SET_LOCATION_MARKER':
      return { ...state, currentLocationLayer: action.payload };
    default:
      return INITIAL_STATE;
  }
}
