/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { gql } from '@apollo/client';

import client from '../Containers/App/config/ApolloClient';

export const registerPushNotification = (deviceId, userId, token) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation(
            $device_id: String!
            $platform: String!
            $provider: String!
            $token: String!
            $user_id: uuid!
          ) {
            insert_yt_push_registration_one(
              object: {
                device_id: $device_id
                platform: $platform
                provider: $provider
                token: $token
                user_id: $user_id
              }
            ) {
              created_at
              device_id
              id
              platform
              provider
              token
              updated_at
              user_id
            }
          }
        `,
        variables: {
          device_id: deviceId,
          user_id: userId,
          token,
          platform: 'webpush',
          provider: 'fcm',
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
