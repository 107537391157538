/* eslint-disable camelcase */
import { gql } from '@apollo/client';

// eslint-disable-next-line import/no-cycle
import client from '../Containers/App/config/ApolloClient';

export const getUser = (id, history) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query($idt: uuid!) {
            yt_user(where: { id: { _eq: $idt } }) {
              id
              full_name
              mobile
              country_code
              email
              profile_photo_file_id
              block
            }
          }
        `,
        variables: {
          idt: id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        if (res.data.yt_user && res.data.yt_user[0].block) {
          localStorage.clear();
          localStorage.setItem('firstLoad', true);
          history.push('/login?isBlock=true');
        } else {
          resolve(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const setUser = () =>
  new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query {
            user(distinct_on: id) {
              id
            }
          }
        `,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
// import React, { Component, Fragment } from 'react';
// import { useQuery, gql } from '@apollo/client';

// const GET_USER = gql`
//   query MyQuery {
//     yt_user(where: { id: { _eq: "aac0ebb9-c022-4d09-9e5a-55782b25a65f " }, full_name: {} }) {
//       id
//       full_name
//       mobile
//     }
//   }
// `;

// export const getUser = (id) => {
//   return new Promise((resolve, reject) => {
//     const { loading, error, data } = useQuery(GET_USER);
//     if (error) {
//       reject(error);
//     }
//     resolve(data);
//   });
// };

// export const getUsers = () => {
//   const { loading, error, data } = useQuery(GET_USER);
//   if (error) {
//     console.log(error);
//   }
//   console.log(data);
// };

export const getProfileURL = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return new Promise((resolve, reject) => {
    if (user.profile_photo_file_id) {
      client
        .query({
          query: gql`
            query($file_upload_id: uuid!) {
              GenViewUrl(file_upload_id: [$file_upload_id]) {
                upload_id
                url
                expires_at
              }
            }
          `,
          variables: {
            file_upload_id: user.profile_photo_file_id,
          },
        })
        .then((res) => {
          resolve(res.data.GenViewUrl[0].url);
        })
        .catch((e) => {
          reject(e);
        });
    }
    return '';
  });
};

export const getUploadURL = (title, imageType) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation($title: String!, $mime_type: String!) {
            UploadProfilePhoto(metadata: { mime_type: $mime_type, title: $title }) {
              expires_at
              expires_in
              file_upload_id
              upload_url
              view_url
            }
          }
        `,
        variables: {
          title,
          mime_type: imageType,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const profileUpdate = (email, full_name) => {
  const user_id = localStorage.getItem('userIdHasura');
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation UpdateUser($user_id: uuid!, $email: String, $full_name: String) {
            UpdateUser(user_id: $user_id, email: $email, full_name: $full_name) {
              user_id
            }
          }
        `,
        variables: {
          user_id,
          email,
          full_name,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getIssues = () => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query {
            yt_issue {
              description
              id
              type
              target_apps
            }
          }
        `,
      })
      .then((res) => {
        resolve(res.data.yt_issue);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const setRideIssue = (ride_id, issue_id, driver_user_id, note) => {
  const user_id = localStorage.getItem('userIdHasura');
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation(
            $user_id: uuid!
            $ride_id: uuid!
            $note: String!
            $issue_id: uuid!
            $driver_user_id: uuid
          ) {
            insert_yt_ride_issue_one(
              object: {
                user_id: $user_id
                ride_id: $ride_id
                note: $note
                issue_id: $issue_id
                driver_user_id: $driver_user_id
              }
            ) {
              issue_id
            }
          }
        `,
        variables: {
          user_id,
          ride_id,
          note,
          issue_id,
          driver_user_id,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
