import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(var(--vh, 1vh) * 100) !important',
    justifyContent: 'space-between',
    backgroundColor: 'black',
  },

  cardBg: {
    backgroundColor: '#313131',
    marginTop: '20px',
    paddingBottom: '20px',
    paddingTop: 20,
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px 16px 0 0',
  },
  saveButton: {
    width: '90%',
  },
  placeCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '95%',
    borderRadius: '10px',
    width: '85%',
    backgroundColor: 'rgb(0,0,0)',
  },
  placeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '90%',
    width: '85%',
    fontSize: '3vmax',
  },
  inputContainer: {
    width: '100%',
    '& .MuiInput-underline:before': {
      borderBottomColor: 'rgb(181,179,179)',
    },
    '& .MuiInputBase-input': {
      fontSize: '2vmax',
      fontWeight: '600',
      color: 'rgb(181,181,181)',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#6a6d77',
    },
  },
  customLabel: {
    color: '#FFFD00',
    fontSize: '1.8vmax',
    fontWeight: '600',
    // top: '-3px',
    fontFamily: 'Avenir Next',
    position: 'static',
    transform: 'inherit',
  },
  inputContainerBox: {
    marginBottom: '20px',
  },
  geocoderInput: {
    height: '5vh',
    width: '100%',
    fontSize: '2vmax',
    background: 'transparent',
    fontFamily: 'Avenir-Regular',
    color: 'rgb(181, 179, 179)',
    borderBottom: 'solid 0.1pt rgb(181, 179, 179)',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderWidth: '0.1pt',
    padding: 0,
    '& geocoderInput:focus': {
      outline: 'none !important',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  warning: {
    fontSize: '2vmax',
    marginBottom: '1vmax',
    color: 'red',
  },
}));
