/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

// importing UI helpers
import HeaderTitle from '../../../../Components/headertitle/HeaderTitle';
import useStyles from '../../css/UserGuideCss';

import CardRightArrow from '../../../../Components/cards/CardRightArrow';
import getCMS from '../../../../Actions/CMS';
import AccordianCard from '../../../../Components/cards/AccordianCard';

export default function UserGuidePage() {
  const classes = useStyles();
  const history = useHistory();

  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [userGuide, setUserGuide] = useState([]);

  useEffect(() => {
    setLoader(true);
    getCMS('ug_rider')
      .then((res) => {
        setLoader(false);
        setUserGuide(res?.data?.yt_content);
      })
      .catch((err) => {
        setLoader(false);
        setError('Something went wrong');
      });
  }, []);

  const renderUserGuide = () => {
    if (userGuide.length > 0) {
      return userGuide.map((userGuide) => {
        return (
          <div className={classes.accordianCardContainer}>
            <AccordianCard summary={userGuide.content.data.Q} details={userGuide.content.data.A} />
          </div>
        );
      });
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <HeaderTitle title="User Guide" backBtn={() => history.goBack()} />
      <div className={classes.cardBg}>
        <div className={classes.innerContainer}>
          <div className={classes.cardsContainer}>{renderUserGuide()}</div>
        </div>
      </div>
    </div>
  );
}
