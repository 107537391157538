/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable global-require */
import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

// importing UI components
import List from '@material-ui/core/List';
import HeaderTitle from '../../../Components/headertitle/HeaderTitle';

// importing helpers
import useStyles from '../css/BoardingPassDetailsCSS';
import ButtonedTab from '../../../Components/tabs/ButtonedTab';
import Tripcard from '../components/TripCard';
import NoTripsCard from '../components/NoTripsCard';
// import ExtendPassCard from '../../../Components/cards/ExtendPassCard';
import InvoiceCard from '../components/InvoiceCard';
import navigationHandler from '../../App/helpers/NavigationHandler';
import PlainPassCard from '../../../Components/cards/PlainPassCard';

export default function BoardingPassDetailsExpired() {
  const [showTrips, setShowTrips] = React.useState(true);
  const classes = useStyles();
  const history = useHistory();
  const { passDetails } = history.location.state;

  return (
    <div className={classes.root}>
      <HeaderTitle title="Pass Details" backBtn={() => history.goBack()} />
      <div className={classes.cardBg}>
        <PlainPassCard
          passId={passDetails.pass_number ? passDetails.pass_number : ''}
          passType={passDetails.plan ? passDetails.plan.title : ''}
          purchaseDate={
            passDetails.purchased_at ? moment(passDetails.purchased_at).format('MM/DD/YYYY') : ''
          }
          startDate={
            passDetails.valid_from ? moment(passDetails.valid_from).format('MM/DD/YYYY') : ''
          }
          exipreDate={passDetails.valid_to ? moment(passDetails.valid_to).format('MM/DD/YYYY') : ''}
        />
        {/* <ExtendPassCard
          passId={passDetails.pass_number}
          passType={passDetails.pass_type}
          purchaseDate={passDetails.purchased_at ?
                moment(passDetails.purchased_at).format("MM/DD/YYYY") : ""}
          startDate={passDetails.valid_from ?
                moment(passDetails.valid_from).format("MM/DD/YYYY") : ""}
          exipreDate={passDetails.valid_to ?
                moment(passDetails.valid_to).format("MM/DD/YYYY") : ""}
          button1Title="Renew pass"
        /> */}
        <ButtonedTab
          title1="Trip"
          title2="Invoice"
          onClickButton1={() => setShowTrips(true)}
          onClickButton2={() => setShowTrips(false)}
        >
          {showTrips ? (
            passDetails.rides && passDetails.rides.length > 0 ? (
              <List>
                {passDetails.rides.map((item) => {
                  return (
                    <Tripcard
                      tripEndAddress={item.end_address.line1}
                      tripStartAddress={item.start_address.line1}
                      tripDate={moment(item.created_at).format('Do MMM,YYYY')}
                      tripDuration={`${item.distance}m | ${item.duration}m`}
                      taxiNo={item.vehicle ? item.vehicle.registration_number : 'Not Available'}
                      onClick={() =>
                        navigationHandler(history, '/my-rides/ride-details', null, item.id)
                      }
                    />
                  );
                })}
              </List>
            ) : (
              <NoTripsCard from="expired" />
            )
          ) : (
            <InvoiceCard
              passCharges={passDetails.order ? `$ ${passDetails.order.amount}` : '$ 0'}
              serviceTax={passDetails.order ? `$ ${passDetails.order.tax}` : '$ 0'}
              discount={passDetails.order ? `$ ${passDetails.order.discount}` : '$ 0'}
              transactionCharge={
                passDetails.order ? `$ ${passDetails.order.transaction_charge}` : '$ 0'
              }
              totalAmt={passDetails.order ? `$ ${passDetails.order.net_amount}` : '$ 0'}
              paidUsing={passDetails.order ? `${passDetails.order.payments[0].payment_method}` : ''}
            />
          )}
        </ButtonedTab>
      </div>
    </div>
  );
}
