import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import StarRatings from 'react-star-ratings';
import Slider from 'react-slick';
import moment from 'moment';

// importing assets
import PrimaryButton from '../../../../../Components/buttons/PrimaryButton';
import back from '../../../../../assets/svg/back.svg';
import DriverIcon from '../../../../../assets/svg/DriverIcon.svg';
import Loader from '../../../../../Components/loader/Loader';
import { driverDetail } from '../../../../../Actions';
import '../css/slicktheme.css';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// importing CSS
import useStyles from '../css/DriverdetailsCSS';
import getUrlFromId from '../../../../../Actions/Common';

function Driverdetails() {
  const [driverData, setDriverData] = useState(null);
  const classes = useStyles();
  const history = useHistory();

  const getUrl = async (mapId) => {
    const url = await getUrlFromId(mapId);
    const result = await url.data.GenViewUrl[0].url;
    return result;
  };

  useEffect(() => {
    if (history.location.state && history.location.state.driverId) {
      driverDetail(history.location.state.driverId)
        .then(async (res) => {
          let driverPic = '';
          if (res.data.yt_user[0].profile_photo_file_id) {
            driverPic = await getUrl(res.data.yt_user[0].profile_photo_file_id);
          }
          setDriverData({
            driver: {
              ...res.data.yt_user[0],
              profile_photo_url: driverPic,
            },
            ratingList: res.data.yt_rating,
            starRating: res.data.yt_rating_aggregate.aggregate.count,
            totalTrip: res.data.yt_ride_aggregate.aggregate.count,
          });
        })
        .catch((err) => {
          console.log('Driverdetails -> err', err);
        });
    }
  }, []);

  const generateDayLabel = (date) => {
    const now = moment();
    const postedDate = moment(date);

    const years = now.diff(postedDate, 'year');
    postedDate.add(years, 'years');

    const months = now.diff(postedDate, 'months');
    postedDate.add(months, 'months');

    const days = now.diff(postedDate, 'days');

    if (years !== 0) {
      return years === 1 ? `${years} year age` : `${years} years age`;
    }
    if (months !== 0) {
      return months === 1 ? `${months} month age` : `${months} months age`;
    }
    if (days !== 0) {
      return days === 1 ? `${days} day age` : `${days} days age`;
    }
    if (days === 0) {
      return `Posted today`;
    }
    return '';
  };

  const goBack = () => {
    history.goBack();
  };

  const settings = {
    className: `center slideSingle ${classes.slideSingle}`,
    centerMode: true,
    infinite: false,
    dots: false,
    centerPadding: '20px',
    slidesToShow: 1,
    speed: 500,
    arrows: false,
    focusOnSelect: false,
  };

  const renderCards = () =>
    driverData.ratingList.map((item) => (
      // <div className={classes.GradientCard}>
      <div className="Card">
        <div className={classes.ReviewTextSection}>
          <p style={{ marginTop: '2px', marginBottom: '2px' }} className={classes.WhiteH4}>
            &quot;
            {item.comment}
            &quot;
          </p>
        </div>
        <div className={classes.ratingSection}>
          <StarRatings
            starDimension="20px"
            starSpacing="3px"
            rating={item.given_rate}
            starRatedColor="white"
            numberOfStars={5}
          />
          <p style={{ marginTop: '2px', marginBottom: '8px', fontSize: '0.7rem' }}>
            {generateDayLabel(item.created_at)}
          </p>
        </div>
      </div>
      // </div>
    ));

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <Button
          type="submit"
          className={classes.icon}
          onClick={() => {
            goBack();
          }}
        >
          <img src={back} alt="back" className={classes.topBarIcon} />
        </Button>
        <h3 className={classes.YellowH3}>Driver Details</h3>
      </div>
      {driverData !== null ? (
        <div className={classes.body}>
          <div className={classes.DriverInfoSection}>
            <div className={classes.AvatarSection}>
              <div className={classes.greySection} />
              <div className={classes.InfoSection}>
                <div className={classes.Details}>
                  <img
                    src={driverData.driver.profile_photo_url || DriverIcon}
                    alt="icon"
                    className={classes.DriverAvatar}
                  />
                  <p style={{ marginTop: '4px', marginBottom: '4px' }}>
                    <b>{driverData.driver.full_name}</b>
                    <br />
                    {driverData.driver.vehicles &&
                      `${driverData.driver.vehicles[0].name}` +
                        ` ${driverData.driver.vehicles[0].registration_number}`}
                    <br />
                  </p>
                </div>
              </div>
              <div className={classes.StatsSection}>
                <div className={classes.Stat}>
                  <p className={classes.YellowH3}>
                    {driverData.totalTrip}
                    <br />
                    <b style={{ color: 'white', fontSize: '0.7rem' }}>Trips</b>
                  </p>
                </div>
                <div className={classes.Stat}>
                  <p className={classes.YellowH3}>
                    {driverData.starRating}
                    <br />
                    <b style={{ color: 'white', fontSize: '0.7rem' }}>5 Star Ratings</b>
                  </p>
                </div>
                <div className={classes.Stat}>
                  <p className={classes.YellowH3}>
                    {moment().diff(driverData.driver.created_at, 'years', false)}
                    <br />
                    <b style={{ color: 'white', fontSize: '0.7rem' }}>Years</b>
                  </p>
                </div>
              </div>
              <div className={classes.optionsSection}>
                <a href={`tel:${driverData.driver.country_code}${driverData.driver.mobile}`}>
                  <PrimaryButton
                    Title="Call"
                    type="primary"
                    onClick={() => {
                      console.log('click');
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className={classes.ReviewSection}>
            <div style={{ flex: 0.1, paddingLeft: '27px', paddingRight: '27px' }}>
              <p className={classes.YellowH4}> Hear from others</p>
            </div>

            {/* <div className={classes.reviewCardsSection}> */}
            <Slider {...settings}>{renderCards()}</Slider>
            {/* </div> */}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default Driverdetails;
