import React from 'react';

function RideControlComponent(props) {
  const { multiplePassFound } = props;
  if (multiplePassFound) {
    return <div> multiple passes found </div>;
  }
  return <div> ride controls</div>;
}

export default RideControlComponent;
