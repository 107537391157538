import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  listItem: {
    width: '100%',
    height: 'auto',
    minHeight: '10vmax',
    marginBottom: '2vmax',
    display: 'flex',
    justifyContent: 'center',
    transform: 'translateX(0)',
  },
  savedPlaceCard: {
    height: '85%',
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    // padding: '1vmax',
    margin: '1vmax',
  },
  singleRide: {
    // margin: '10px',
    // marginBottom: '2vmax',
    background: '#000',
    width: '80%',
    height: '100%',
    minHeight: '11.5vmax',
    borderRadius: '10px',
  },
  options: {
    marginLeft: '4vmin',
    marginRight: '4vmin',
    display: 'flex',
    height: '10vmax',
    transform: 'translateX(-4vmin)',
    flexDirection: 'column',
    position: 'absolute',
    right: '3vmin',
    justifyContent: 'space-between',
  },
  editButtonContainer: {
    // top: 0,
  },
  deleteButtonContainer: {
    // marginTop: '1.5vh',
  },
  editIcon: {
    height: '4vmax',
    width: '4vmax',
  },
  smallArrowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addressHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  addressHeaderTextContainer: {
    marginLeft: '6px',
    display: 'flex',
  },
  addressHeaderText: {
    fontFamily: 'Avenir-Black',
    fontSize: '1.7vmax',
    color: 'rgb(255, 253, 0)',
    fontWeight: '900',
  },
  addressContainer: {
    marginLeft: '14px',
    // marginTop: '4px',
  },
  addressText: {
    fontFamily: 'AvenirNext-Regular',
    fontSize: '1.7vmax',
    color: 'rgb(255, 255, 255)',
    fontWeight: '500',
  },
  rightArrowIcon: {
    height: '2vmax',
    width: '2vmax',
  },
  greenDot: {
    height: '1vmax',
    width: '1vmax',
  },
}));
