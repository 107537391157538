/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable global-require */
import React from 'react';
// importing UI components
import RightArrowIcon from '../../assets/svg/rightArrow.svg';

// importing helpers
import useStyles from './css/CardRightArrowCss';

export default function CardRightArrow(props) {
  const classes = useStyles();

  return (
    <div className={classes.cardContainer} onClick={props.onClick}>
      <div className={classes.textContainer}>
        {props.icon ? (
          <div className={classes.logoContainer}>
            <img
              src={props.icon}
              alt="nothing"
              style={{ height: '2.3vmax', width: '4.2vmin', transform: 'translate(0, 0)' }}
            />
          </div>
        ) : null}
        <span>{props.title}</span>
      </div>
      <div className={classes.iconContainer}>
        <img
          src={RightArrowIcon}
          style={{ height: '2vmax', width: '1vmax', transform: 'translate(0, 0)' }}
          alt="nothing"
        />
      </div>
    </div>
  );
}
