/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  btnPrimary: {
    backgroundColor: '#7626BC',
    '&:focus': {
      backgroundColor: '#4F1C7C',
    },
    minWidth: '10vmin',
    color: '#ffffff',
    textTransform: 'capitalize',
    letterSpacing: '0',
    padding: '0px 32px',

    height: '6.5vmax',
    borderRadius: '50px',
    fontSize: '2.3vmax',
    fontFamily: 'Avenir-Black',
    fontWeight: 'bold',
  },
  w100: {
    width: '100%',
  },
  btnSecondary: {
    backgroundColor: 'transparent',
    border: '2px solid #7626BC',
    '&:focus': {
      backgroundColor: '#transparent',
      border: '2px solid #4F1C7C',
    },
    borderRadius: 25,
    minWidth: '160px',
    height: 50,
    color: '#ffffff',
    fontSize: 16,
    fontWeight: '600',
    fontFamily: 'Avenir Next',
    textTransform: 'capitalize',
    letterSpacing: '0',
    padding: '0px 32px',
  },
  disabled: {
    backgroundColor: '#3F3F3F',
    color: '#8F8F87 !important',
  },
  disabledOutline: {
    backgroundColor: 'transparent',
    border: '2px solid #3F3F3F',
    color: '#8F8F87 !important',
  },
}));

const PrimaryButton = (props) => {
  const classes = useStyles();
  // console.log(props.type);
  if (props.type === 'outlined') {
    return (
      <>
        <Button
          onClick={props.onClick}
          className={
            props.variant === 'fullWidth' && !props.disabled
              ? [classes.btnSecondary, props.className, classes.w100]
              : props.disabled
              ? props.variant === 'fullWidth'
                ? [classes.btnSecondary, props.className, classes.w100, classes.disabledOutline]
                : [classes.btnSecondary, props.className, classes.disabledOutline]
              : [classes.btnSecondary, props.className]
          }
          style={props.style}
          disabled={props.disabled}
        >
          {props.Title}
        </Button>
      </>
    );
  }
  if (props.type === 'primary') {
    return (
      <Button
        onClick={props.onClick}
        className={
          props.variant === 'fullWidth' && !props.disabled
            ? [classes.btnPrimary, props.className, classes.w100]
            : props.disabled
            ? props.variant === 'fullWidth'
              ? [classes.btnPrimary, props.className, classes.w100, classes.disabled]
              : [classes.btnPrimary, props.className, classes.disabled]
            : [classes.btnPrimary, props.className]
        }
        style={props.style}
        disabled={props.disabled}
      >
        {props.Title}
      </Button>
    );
  }
  return <div />;
};

export default PrimaryButton;
