/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useReducer } from 'react';
import _ from 'lodash';
import { point, lineString, along, lineDistance, rhumbBearing } from '@turf/turf';
import DeckGL, { GeoJsonLayer, log } from 'deck.gl';
import Mapbox from 'mapbox';
import ReactMapGL, {
  WebMercatorViewport,
  Marker,
  GeolocateControl,
  FlyToInterpolator,
  _MapContext as MapContext,
} from 'react-map-gl';
import { useHistory } from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import InputLabel from '@material-ui/core/InputLabel';
// import Geocoder from 'react-map-gl-geocoder';
import Geocoder from 'react-mapbox-gl-geocoder';
import IconButton from '@material-ui/core/IconButton';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import NavigationHandler from '../../../App/helpers/NavigationHandler';
import getLocationAdd from './getCurrentLocation';
import PrimaryButton from '../../../../Components/buttons/PrimaryButton';

// Importing Helpers
import token from '../config/config';
import getCoordinates from '../config/getPointForString';
import { INITIAL_STATE, reducer } from '../config/reducer';
// import GeocoderInput from './GeocoderInput';
import HamburgerButton from '../../../../Components/buttons/HamburgerButton';
import LeftMenuCard from '../../../../Components/leftmenu/LeftMenuCard';
import SOSScreen from '../SOSScreen';
import RideControlComponent from './RideControlComponent';
import Emitter from '../../../../Helpers/Emitter';

// Importing Assets
import Cancel from '../../../../assets/svg/cancel.svg';
import Call from '../../../../assets/svg/Call.svg';
import QR from '../../../../assets/svg/QR.svg';
import DriverIcon from '../../../../assets/svg/DriverIcon.svg';
import Info from '../../../../assets/svg/info.svg';
import useStyles from './SearchableMapStyles';
import close from '../../../../assets/svg/close.svg';
import Location from '../../../../assets/svg/currentLocation.svg';
import nextarrow from '../../../../assets/img/next-arrow.png';
import infosmall from '../../../../assets/svg/infosmall.svg';
import takeme from '../../../../assets/svg/takeme.svg';
import sos from '../../../../assets/svg/sos.svg';
import RightArrowSmall from '../../../../assets/svg/smallRightArrow.svg';
import PlanCardDesign from '../../../../assets/svg/planCardDesign5.svg';

// import Car from '../../../../assets/svg/Car.svg';
import Car2 from '../../../../assets/svg/Car2.svg';

// importing APIs
import { getVehicles, unsubFromVehicles } from '../../../../Actions/Vehicles';
import { subscribeRideLocation, LookForRides } from '../../../../Actions/Ride';

import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './SearchableMap.css';
import './Maps.css';
import { getUserSavedPlaces } from '../../../../Actions';
import countryList from '../../../Login/helpers/countryList';
import slackAxios from '../../../../slack';

const deckStyles = {
  display: 'flex',
  flex: 0.6,
  flexDirection: 'column',
};
const mapbox = new Mapbox(token);

const defaultPickupText = 'Enter or Search Pickup Location';
const defaultDropText = 'Enter or Search Dropoff Location';

export default function SearchableMap(props) {
  const history = useHistory();
  const classes = useStyles();
  const mapRef = React.useRef();
  const containerRef = React.useRef(null);
  const LocationContainerRef = React.useRef();
  const cardRef = React.useRef(null);
  const buttonRef = React.useRef();
  const floatingref = React.useRef();
  const innercard = React.useRef();
  const submitRef = React.useRef();
  const recentLabelRef = React.useRef();
  const animationRef = React.useRef();
  const timerRef = React.useRef(0);
  const locUpdateRef = React.useRef(null);
  const locaitonIconRef = React.useRef(null);
  const pickLocation = React.useRef(null);
  const dropLocation = React.useRef(null);
  const heightRef = React.useRef(0);

  const [inputType, setInputType] = React.useState('');
  const [pickupText, setPickupText] = React.useState('');
  const [dropText, setDropText] = React.useState('');
  const [placesData, setPlacesData] = React.useState(null);
  const [ETA, setETA] = React.useState(null);
  const [rideCancelModal, setRideCancelModal] = React.useState(false);

  const [searchingOpen, setSearchingOpen] = React.useState(false);
  const [state, dispatch] = useReducer((d, action) => reducer(d, action), INITIAL_STATE(props));
  const [geoCountryCode, setGeoCountyCode] = React.useState('');
  const [proximityParams, setProximityParams] = React.useState({});
  const [isLocationOn, setIsLocationOn] = React.useState(null);
  const svgPath = (iconName) => `${iconName}#svgView(preserveAspectRatio(none))`;

  useEffect(() => {
    console.log('userEffect 1')
    // Here we dispatch changes to reducer in case the change in props did not do the job
    // Later in the file a useEffect uses the values from state.rideFound to make
    // changes in subscription to avoid making this component confusing.
    if (props.details !== null) {
      if (props.details.status === 'CANCELED') {
        dispatch({
          type: 'SET_RIDE_DATA',
          payload: { coordinates: [0, 0], type: 'Point' }, // gotaa make it null
        });
        dispatch({
          type: 'TOGGLE_RIDE_STATE',
          payload: false,
        });
      }
      dispatch({
        type: 'TOGGLE_RIDE_STATE',
        payload: props.rideStatus,
      });
    }
  }, [props.rideStatus]);

  useEffect(() => {
    console.log('userEffect 2')
    if (props.error !== null) {
      dispatch({
        type: 'SET_ERROR',
        payload: props.error,
      });
    }
  }, [props.error]);

  useEffect(() => {
    console.log('userEffect 3')
    if (props.multiplePassFound) {
      dispatch({
        type: 'SET_PASS',
        payload: true,
      });
    }
  }, [props.multiplePassFound]);

  // useEffect(() => {
  //   if (pickLocation.current !== null) {
  //     console.log('picklocation not null', pickLocation.current);
  //     pickLocation.current.addEventListener('touchmove', (e) => {
  //       console.log('touch move');
  //       e.preventDefault();
  //       if (!e.currentTarget) {
  //         return;
  //       }
  //       if (e.currentTarget.scrollTop === 0) {
  //         e.currentTarget.scrollTop = 1;
  //       } else if (
  //         e.currentTarget.scrollHeight ===
  //         e.currentTarget.scrollTop + e.currentTarget.offsetHeight
  //       ) {
  //         e.currentTarget.scrollTop -= 1;
  //       }
  //     });
  //   }
  // }, [pickLocation.current]);

  const getTime = async (data, currentCoords) => {
    if (data && data.length !== 0) {
      const directionList = data.map((item) => {
        const vehiclesCoords = {
          longitude: parseFloat(item.location.split(',')[0].replace('(', '')),
          latitude: parseFloat(item.location.split(',')[1].replace(')', '')),
        };

        return mapbox.getDirections([currentCoords, vehiclesCoords], {
          profile: 'driving-traffic',
          instructions: 'html',
          alternatives: false,
          geometry: 'geojson',
          overview: 'full',
        });
      });

      const result = await Promise.all(directionList);

      const minETA =
        Math.min(
          ...result.map((item) => {
            return Math.ceil(item.entity.routes[0].duration / 60);
          }),
        ) || 30;
      setETA(minETA);
    } else {
      setETA(null);
    }
  };

  const setUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setIsLocationOn(true);
        setProximityParams({
          ...proximityParams,
          latitude: position.coords?.latitude,
          longitude: position.coords?.longitude,
        });
        getLocationAdd(position.coords).then((res) => {
          const responseCountry = res.place;
          if (responseCountry) {
            let geoCountry = responseCountry?.split(',');
            geoCountry = geoCountry[geoCountry?.length - 1]?.replace(' ', '');
            const countryDetails = countryList.find((item) => item.name === geoCountry);
            setGeoCountyCode(countryDetails?.countryCode);
          }
          if (res) {
            dispatch({
              type: 'SET_LOCATION_NAME',
              // payload: 'Current Location',
              payload:
                res.name === 'Unnamed Location' ||
                res.name === '' ||
                res.name === null ||
                res.name === 'unnamed' ||
                res.name === 'loading'
                  ? 'Your Location'
                  : res.name,
            });
            dispatch({
              type: 'SET_START',
              payload: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                name: res.name,
                address: res.place,
              },
            });
          } else {
            console.log('position not found');
          }
        });
        dispatch({
          type: 'SET_CURRENT_LOCATION',
          payload: {
            lat: position.coords.latitude,
            long: position.coords.longitude,
          },
        });
        console.log('setting viewport from user location');
        dispatch({
          type: 'SET_VIEWPORT',
          payload: {
            zoom: 14,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        });

        getVehicles(position.coords, (res) => {
          getTime(res.yt_get_vehicles_within_radius, position.coords);
          dispatch({
            type: 'SET_VEHICLES',
            payload: res.yt_get_vehicles_within_radius,
          });
        });
      },
      (err) => {
        setIsLocationOn(false);
        if (
          (err.message && err.message === 'User denied Geolocation') ||
          (err.message &&
            err.message === 'Origin does not have permission to use Geolocation service')
        ) {
          dispatch({
            type: 'SET_ERROR',
            payload: 'Please enable location permissions',
          });

          dispatch({
            type: 'SET_LOCATION_NAME',
            payload: '',
          });
        } else {
          dispatch({
            type: 'SET_ERROR',
            payload: 'Error fetching Location',
          });

          dispatch({
            type: 'SET_LOCATION_NAME',
            payload: '',
          });
        }
      },
      { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true },
    );
  };

  const removeMapandPoints = () => {
    if (mapRef && mapRef.current && mapRef.current.getMap() !== null) {
      const map = mapRef.current.getMap();
      if (map.getLayer('line')) {
        map.removeLayer('line');
        map.removeSource('line');
      }

      dispatch({
        type: 'SET_SEARCH_LAYER',
        payload: null,
      });
      dispatch({
        type: 'SET_DESTINATION_LAYER',
        payload: null,
      });
    } else {
      dispatch({
        type: 'SET_ERROR',
        payload: 'Please reload, cannot fetch map',
      });
    }
  };

  const changePathAsPerDestinationChange = (results) => {
    console.log('getRouteDirections => getDirections => changePathAsPerDestinationChange');
    if (mapRef && mapRef.current) {
      const map = mapRef.current.getMap();
      if (map.getLayer('line')) {
        map.removeLayer('line');
        map.removeSource('line');
      }

      map.addSource('line', {
        type: 'geojson',
        lineMetrics: true,
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                coordinates: results.entity.routes[0].geometry.coordinates,
                type: 'LineString',
              },
            },
          ],
        },
      });
      map.addLayer({
        type: 'line',
        source: 'line',
        id: 'line',
        paint: {
          'line-color': 'red',
          'line-width': 5,
          // 'line-gradient' must be specified using an expression
          // with the special 'line-progress' property
          'line-gradient': [
            'interpolate',
            ['linear'],
            ['line-progress'],
            0,
            '#FFFD00',
            0.33,
            '#FFE900',
            0.66,
            '#00FD00',
            1,
            '#33CC33',
          ],
        },
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
      });
    }
  };

  const addPathonMap = (results) => {
    // This function adds the line layer depicting the route on the map.
    const map = mapRef.current.getMap();
    if (!map.getLayer('line')) {
      map.addSource('line', {
        type: 'geojson',
        lineMetrics: true,
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                coordinates: results.entity.routes[0].geometry.coordinates,
                type: 'LineString',
              },
            },
          ],
        },
      });
      map.addLayer({
        type: 'line',
        source: 'line',
        id: 'line',
        paint: {
          'line-color': 'red',
          'line-width': 5,
          // 'line-gradient' must be specified using an expression
          // with the special 'line-progress' property
          'line-gradient': [
            'interpolate',
            ['linear'],
            ['line-progress'],
            0,
            '#FFFD00',
            0.33,
            '#FFE900',
            0.66,
            '#00FD00',
            1,
            '#33CC33',
          ],
        },
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
      });
    }
  };

  const getRouteDirections = async (p1, p2) => {
    dispatch({
      type: 'SET_ROUTE_LOADER',
      payload: true,
    });
    mapbox
      .getDirections([p1, p2], {
        profile: 'driving-traffic',
        instructions: 'html',
        alternatives: false,
        geometry: 'geojson',
        overview: 'full',
      })
      .then((results) => {
        console.log('getRouteDirections => getDirections');
        dispatch({
          type: 'SET_RESULT',
          payload: results,
        });
        dispatch({
          type: 'SET_ROUTE_LOADER',
          payload: false,
        });
        // dispatch({
        //   type: 'SET_VISIBILITY',
        //   payload: false,
        // });
        const { width, height } = mapRef.current.getMap().getContainer().getBoundingClientRect();
        const cornersLongLat = [
          [Math.min(p1.longitude, p2.longitude), Math.min(p1.latitude, p2.latitude)],
          [Math.max(p1.longitude, p2.longitude), Math.max(p1.latitude, p2.latitude)],
        ];
        const fitBounds = (bounds, viewport) => new WebMercatorViewport(viewport).fitBounds(bounds);
        const zoom = fitBounds(cornersLongLat, { width, height });
        dispatch({
          type: 'SET_VIEWPORT',
          payload: {
            latitude: zoom.latitude,
            longitude: zoom.longitude,
            zoom: zoom.zoom * 0.9,
          },
        });

        changePathAsPerDestinationChange(results);
      })
      .catch((err) => {
        dispatch({
          type: 'SET_ERROR',
          payload: 'Route is invalid',
        });
        dispatch({
          type: 'SET_START',
          payload: null,
        });
        dispatch({
          type: 'SET_END',
          payload: null,
        });
        dispatch({
          type: 'SET_SEARCH_LAYER',
          payload: null,
        });
        dispatch({
          type: 'SET_DESTINATION_LAYER',
          payload: null,
        });
        setUserLocation();
        dispatch({
          type: 'SET_VISIBILITY',
          payload: true,
        });
      });
  };
  const getMapBoxDirections = async (p1, p2) => {
    dispatch({
      type: 'SET_ROUTE_LOADER',
      payload: true,
    });
    mapbox
      .getDirections([p1, p2], {
        profile: 'driving-traffic',
        instructions: 'html',
        alternatives: false,
        geometry: 'geojson',
        overview: 'full',
      })
      .then((results) => {
        dispatch({
          type: 'SET_RESULT',
          payload: results,
        });
        dispatch({
          type: 'SET_ROUTE_LOADER',
          payload: false,
        });
        // dispatch({
        //   type: 'SET_VISIBILITY',
        //   payload: false,
        // });
        const { width, height } = mapRef.current.getMap().getContainer().getBoundingClientRect();
        const cornersLongLat = [
          [Math.min(p1.longitude, p2.longitude), Math.min(p1.latitude, p2.latitude)],
          [Math.max(p1.longitude, p2.longitude), Math.max(p1.latitude, p2.latitude)],
        ];
        const fitBounds = (bounds, viewport) => new WebMercatorViewport(viewport).fitBounds(bounds);
        const zoom = fitBounds(cornersLongLat, { width, height });
        dispatch({
          type: 'SET_VIEWPORT',
          payload: {
            latitude: zoom.latitude,
            longitude: zoom.longitude,
            zoom: zoom.zoom * 0.9,
          },
        });

        setTimeout(() => {
          addPathonMap(results);
        }, 1000);
        // handleTransition();
      })
      .catch((err) => {
        dispatch({
          type: 'SET_ERROR',
          payload: 'Route is invalid',
        });
        dispatch({
          type: 'SET_START',
          payload: null,
        });
        dispatch({
          type: 'SET_END',
          payload: null,
        });
        dispatch({
          type: 'SET_SEARCH_LAYER',
          payload: null,
        });
        dispatch({
          type: 'SET_DESTINATION_LAYER',
          payload: null,
        });
        setUserLocation();
        dispatch({
          type: 'SET_VISIBILITY',
          payload: true,
        });
      });
  };

  const removeRoute = () => {
    if (mapRef && mapRef.current) {
      const map = mapRef.current.getMap();
      if (map.getLayer('line')) {
        map.removeLayer('line');
        map.removeSource('line');
      }
    }
  };

  const getRedrawCoordinates = (p1, p2) => {
    console.log({ p1, p2 });
    return mapbox
      .getDirections([p1, p2], {
        profile: 'driving-traffic',
        instructions: 'html',
        alternatives: false,
        geometry: 'geojson',
        overview: 'full',
      })
      .then((results) => {
        console.log('getRedrawCoordinates results', results);
        return results;
      })
      .catch((err) => {
        console.log('getRedrawCoordinates err', err);
        return null;
      });
  };
  useEffect(() => {
    console.log('userEffect 4')
    if (props.details !== null) {
      // If the ride gets canceled we remove the map layers.
      if (props.details.status && props.details.status === 'CANCELED') {
        removeMapandPoints();

        dispatch({
          type: 'TOGGLE_RIDE_STATE',
          payload: false,
        });

        dispatch({
          type: 'SET_VISIBILITY',
          payload: true,
        });
      } else if (props.details.status === 'ASSIGNED' || props.details.status === 'IN_PROGRESS') {
        // We need to start sending  data back to reducer to update the vehicle location.
        // Setting the initial location for vehicle in ongoing ride
        dispatch({
          type: 'SET_RIDE_DATA',
          payload: { type: 'Point', coordinates: getCoordinates(props.details.vehicle.location) },
        });
      }
      if (props.details.vehicle && props.details.vehicle.id) {
        console.log('If the ride gets canceled we remove the map layers.');
        subscribeRideLocation(props.details.vehicle.id, async (res) => {
          console.log(' subscribeRideLocation 1 ');
          slackAxios({ file: 'subscribeRideLocation 1' });

          const loc = res.data.yt_vehicle[0].location.replace('(', '').replace(')', '').split(',');
          console.log(' subscribeRideLocation 2 ');
          dispatch({
            type: 'SET_TEMP_RIDE_DATA',
            payload: [parseFloat(loc[0]), parseFloat(loc[1])],
          });

          let LAST_STATUS = localStorage.getItem('LAST_STATUS');
          console.log(' subscribeRideLocation 3 ', LAST_STATUS);
          if (
            props.details.status !== LAST_STATUS &&
            (((LAST_STATUS === '' || LAST_STATUS === null) &&
              props.details.status === 'ASSIGNED') ||
              (LAST_STATUS === 'ASSIGNED' && props.details.status === 'IN_PROGRESS'))
          ) {
            console.log(' subscribeRideLocation 4 ');
            localStorage.setItem('LAST_STATUS', props.details.status);
            LAST_STATUS = props.details.status;
            removeRoute();
            removeMapandPoints();
          } else if (
            props.details.status !== LAST_STATUS &&
            (props.details.status === 'CANCELED' || props.details.status === 'COMPLETE')
          ) {
            console.log(' subscribeRideLocation 5 ');
            localStorage.removeItem('LAST_STATUS');
            removeRoute();
            removeMapandPoints();
            LAST_STATUS = '';
          }

          if (LAST_STATUS !== 'IN_PROGRESS' && props.details.status === 'ASSIGNED') {
            if (mapRef && mapRef.current) {
              console.log(' subscribeRideLocation 6 ');
              const map = mapRef.current.getMap();
              const results = await getRedrawCoordinates(
                {
                  longitude: parseFloat(loc[0]),
                  latitude: parseFloat(loc[1]),
                  address: 'Driver Location',
                },
                {
                  longitude: parseFloat(props.details.route.source.longitude),
                  latitude: parseFloat(props.details.route.source.latitude),
                },
              );
              console.log('NOT IN_PROGRESS && ASSIGNED', results);
              slackAxios({
                file: 'subscribeRideLocation NOT IN_PROGRESS && ASSIGNED',
                results,
              });

              if (results !== null) {
                if (map.getLayer('line')) {
                  console.log(' subscribeRideLocation 7 ');
                  map.getSource('line').setData({
                    type: 'FeatureCollection',
                    features: [
                      {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                          coordinates: results.entity.routes[0].geometry.coordinates,
                          type: 'LineString',
                        },
                      },
                    ],
                  });
                } else {
                  map.addSource('line', {
                    type: 'geojson',
                    lineMetrics: true,
                    data: {
                      type: 'FeatureCollection',
                      features: [
                        {
                          type: 'Feature',
                          properties: {},
                          geometry: {
                            coordinates: results.entity.routes[0].geometry.coordinates,
                            type: 'LineString',
                          },
                        },
                      ],
                    },
                  });
                  map.addLayer({
                    type: 'line',
                    source: 'line',
                    id: 'line',
                    paint: {
                      'line-color': 'red',
                      'line-width': 5,
                      // 'line-gradient' must be specified using an expression
                      // with the special 'line-progress' property
                      'line-gradient': [
                        'interpolate',
                        ['linear'],
                        ['line-progress'],
                        0,
                        '#FFFD00',
                        0.33,
                        '#FFE900',
                        0.66,
                        '#00FD00',
                        1,
                        '#33CC33',
                      ],
                    },
                    layout: {
                      'line-cap': 'round',
                      'line-join': 'round',
                    },
                  });
                }
              }
              dispatch({
                type: 'SET_VIEWPORT',
                payload: {
                  longitude: parseFloat(loc[0]),
                  latitude: parseFloat(loc[1]),
                  zoom: map.getZoom(),
                },
              });
            }
            console.log({ LAST_STATUS });

            dispatch({
              type: 'SET_DESTINATION_LAYER',
              payload: new GeoJsonLayer({
                id: 'destination',
                data: {
                  coordinates: [
                    parseFloat(props.details.route.source.longitude),
                    parseFloat(props.details.route.source.latitude),
                  ],
                  type: 'Point',
                },
                getFillColor: [0, 253, 0, 255],
                getRadius: 600,
                pointRadiusMinPixels: 10,
                pointRadiusMaxPixels: 10,
              }),
            });
          } else if (props.details.status === 'IN_PROGRESS') {
            const DESTINATION = JSON.parse(localStorage.getItem('DESTINATION'));
            console.log({ DESTINATION });
            // removeRoute();
            // getMapBoxDirections(state?.start, {
            //   longitude: parseFloat(props.details.route.destination.longitude),
            //   latitude: parseFloat(props.details.route.destination.latitude),
            // });
            if (mapRef && mapRef.current) {
              const map = mapRef.current.getMap();
              const results = await getRedrawCoordinates(
                {
                  longitude: parseFloat(loc[0]),
                  latitude: parseFloat(loc[1]),
                  address: 'Driver Location',
                },
                {
                  longitude: parseFloat(DESTINATION.longitude),
                  latitude: parseFloat(DESTINATION.latitude),
                },
              );
              console.log('IN_PROGRESS', results);
              if (results !== null) {
                if (map.getLayer('line')) {
                  map.getSource('line').setData({
                    type: 'FeatureCollection',
                    features: [
                      {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                          coordinates: results.entity.routes[0].geometry.coordinates,
                          type: 'LineString',
                        },
                      },
                    ],
                  });
                } else {
                  map.addSource('line', {
                    type: 'geojson',
                    lineMetrics: true,
                    data: {
                      type: 'FeatureCollection',
                      features: [
                        {
                          type: 'Feature',
                          properties: {},
                          geometry: {
                            coordinates: results.entity.routes[0].geometry.coordinates,
                            type: 'LineString',
                          },
                        },
                      ],
                    },
                  });
                  map.addLayer({
                    type: 'line',
                    source: 'line',
                    id: 'line',
                    paint: {
                      'line-color': 'red',
                      'line-width': 5,
                      // 'line-gradient' must be specified using an expression
                      // with the special 'line-progress' property
                      'line-gradient': [
                        'interpolate',
                        ['linear'],
                        ['line-progress'],
                        0,
                        '#FFFD00',
                        0.33,
                        '#FFE900',
                        0.66,
                        '#00FD00',
                        1,
                        '#33CC33',
                      ],
                    },
                    layout: {
                      'line-cap': 'round',
                      'line-join': 'round',
                    },
                  });
                }
              }
              dispatch({
                type: 'SET_VIEWPORT',
                payload: {
                  longitude: parseFloat(loc[0]),
                  latitude: parseFloat(loc[1]),
                  zoom: map.getZoom(),
                },
              });
            }
            dispatch({
              type: 'SET_DESTINATION_LAYER',
              payload: new GeoJsonLayer({
                id: 'destination',
                data: {
                  coordinates: [DESTINATION.longitude, DESTINATION.latitude],
                  type: 'Point',
                },
                getFillColor: [0, 253, 0, 255],
                getRadius: 600,
                pointRadiusMinPixels: 10,
                pointRadiusMaxPixels: 10,
              }),
            });
            // } else if (props.details.status === 'CANCELED') {
            //   removeRoute();
          }
          // checkRideMovement(loc);
        });
        console.log(
          '🚀 ~ file: SearchableMap.js ~ line 378 ~ subscribeRideLocation ~ props.details.status',
          props.details.status,
        );
        slackAxios({
          file:
            '🚀 ~ file: SearchableMap.js ~ line 378 ~ subscribeRideLocation ~ props.details.status',
          status: props.details.status,
        });
      }
    }
  }, [props.details?.status]);

  useEffect(() => {
    console.log('userEffect 5')
    if (state.error !== null) {
      dispatch({
        type: 'SET_DIALOG_STATE',
        payload: true,
      });
    }
  }, [state.error]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    dispatch({
      type: 'SET_DRAWER_STATE',
      payload: { ...state.stateDrawer, [anchor]: open },
    });
  };

  const removeClass = () => {
    const card = cardRef.current;
    const button = buttonRef.current;
    const locationIcon = locaitonIconRef.current;
    const iCard = innercard.current;
    const subButton = submitRef.current;

    setSearchingOpen(!searchingOpen);
    iCard.classList.remove('transparent');
    subButton.classList.remove('none');
    card.classList.remove('expanded-card50');
    button.classList.remove('visible');
    locationIcon.classList.remove('some');
  };

  const removeClassAndroid = () => {
    const card = cardRef.current;
    const button = buttonRef.current;
    const locationIcon = locaitonIconRef.current;
    const iCard = innercard.current;
    const subButton = submitRef.current;

    setSearchingOpen(!searchingOpen);
    iCard.classList.remove('transparent');
    subButton.classList.remove('none');
    card.classList.remove('expanded-card');
    button.classList.remove('visible');
    locationIcon.classList.remove('some');
  };

  const handleOnResult = (viewport, event) => {
    setPickupText('');

    dispatch({
      type: 'SET_VIEWPORT',
      payload: viewport,
    });
    dispatch({
      type: 'SET_START',
      payload: {
        longitude: event.center[0],
        latitude: event.center[1],
        name: event.text,
        address: event.place_name,
      },
    });
    dispatch({
      type: 'SET_SEARCH_LAYER',
      payload: new GeoJsonLayer({
        id: 'source',
        data: event.geometry,
        getFillColor: [0, 253, 0, 255],
        getRadius: 600,
        pointRadiusMinPixels: 10,
        pointRadiusMaxPixels: 10,
      }),
    });
    getVehicles({ longitude: event.center[0], latitude: event.center[1] }, (res) => {
      dispatch({
        type: 'SET_VEHICLES',
        payload: res.yt_get_vehicles_within_radius,
      });
    });
  };

  const OPTIONS = {
    units: 'kilometers',
  };
  const STEPS = 8;

  const setLocationWithoutAnimation = (loc) => {
    const arc = [];
    const line = lineString([state.rideData.coordinates, loc]);
    const distance = lineDistance(line, OPTIONS);
    // dispatch({
    //   type: 'SET_RIDE_DATA',
    //   payload: { type: 'Point', coordinates: loc },
    // });
    console.log({ distance });

    for (let i = 0; i < distance; i += distance / STEPS) {
      const segment = along(line, i, OPTIONS);
      arc.push(segment.geometry.coordinates);
    }

    if (arc.length > 0) {
      dispatch({
        type: 'SET_ANGLE',
        payload: rhumbBearing(point(arc[0]), point(arc[arc.length - 1])),
      });
    }

    console.log({ timerRef, arc });
    function animate() {
      if (timerRef.current < STEPS && arc.length > 0) {
        dispatch({
          type: 'SET_RIDE_DATA',
          payload: { type: 'Point', coordinates: arc[timerRef.current] || arc[arc.length - 1] },
        });
        timerRef.current += 1;
        setTimeout(() => {
          window.requestAnimationFrame(animate);
        }, 900);
      }
    }

    if (animationRef.current !== null) {
      window.cancelAnimationFrame(animationRef.current);
      timerRef.current = 0;
    }
    animationRef.current = window.requestAnimationFrame(animate);
  };

  const isIOS = () => {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform,
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  };

  // const handleTransition = (position) => {
  //   // the source/destination card animation is controlled from here.

  //   console.log('tranisitioning');
  //   setSearchingOpen(!searchingOpen);
  //   const card = cardRef.current;
  //   const button = buttonRef.current;
  //   const locationIcon = locaitonIconRef.current;
  //   button.classList.toggle('hidden');
  //   locationIcon.classList.toggle('none');
  //   if (isIOS()) {
  //     card.classList.toggle('expanded-card50');
  //   } else {
  //     card.classList.toggle('expanded-card');
  //   }

  //   // floatingref.current.classList.toggle('none');
  //   innercard.current.classList.toggle('transparent');
  //   submitRef.current.classList.toggle('none');
  //   // recentLabelRef.current.classList.toggle('none');
  //   setSearchingOpen(!searchingOpen);
  // };

  // const redrawRoute = (result) => {
  //   console.log('redrawing route');
  //   const map = mapRef.current.getMap();
  //   if (map) {
  //     map.on('style.load', () => {
  //       const { width, height } = mapRef.current.getMap().getContainer().getBoundingClientRect();
  //       const cornersLongLat = [
  //         [
  //           Math.min(result.source.longitude, result.destination.longitude),
  //           Math.min(result.source.latitude, result.destination.latitude),
  //         ],
  //         [
  //           Math.max(result.source.longitude, result.destination.longitude),
  //           Math.max(result.source.latitude, result.destination.latitude),
  //         ],
  //       ];
  //       const fitBounds = (bounds, viewport) =>
  //  new WebMercatorViewport(viewport).fitBounds(bounds);
  //       const zoom = fitBounds(cornersLongLat, { width, height });

  //       dispatch({
  //         type: 'SET_SEARCH_LAYER',
  //         payload: new GeoJsonLayer({
  //           id: 'source',
  //           data: { coordinates: [result.source.longitude, result.source.latitude],
  // type: 'Point' },
  //           getFillColor: [255, 221, 0, 255],
  //           getRadius: 600,
  //           pointRadiusMinPixels: 10,
  //           pointRadiusMaxPixels: 10,
  //         }),
  //       });

  //       dispatch({
  //         type: 'SET_DESTINATION_LAYER',
  //         payload: new GeoJsonLayer({
  //           id: 'destination',
  //           data: {
  //             coordinates: [result.destination.longitude, result.destination.latitude],
  //             type: 'Point',
  //           },
  //           getFillColor: [0, 253, 0, 255],
  //           getRadius: 600,
  //           pointRadiusMinPixels: 10,
  //           pointRadiusMaxPixels: 10,
  //         }),
  //       });
  //       console.log('setting from redraw');
  //       dispatch({
  //         type: 'SET_VIEWPORT',
  //         payload: {
  //           latitude: zoom.latitude,
  //           longitude: zoom.longitude,
  //           zoom: zoom.zoom * 0.9,
  //         },
  //       });

  //       console.log('the style has loaded correctly');
  //       addPathonMap(result);
  //     });
  //   }
  // };

  useEffect(() => {
    console.log('userEffect 6')
    if (state.rideFound && state.tempRideData !== null) {
      setLocationWithoutAnimation(state.tempRideData);
    }
  }, [state.tempRideData]);

  useEffect(() => {
    console.log('userEffect 7')
    // Check if we meed to unsub from nearby vehciles location and sub to ride locaiton
    if (state.rideFound) {
      // Unsub from  nearby vehicles subscription
      console.log(props.details);
      dispatch({
        type: 'SET_VISIBILITY',
        payload: false,
      });
      unsubFromVehicles();
      console.log('need to redraw');
      // redrawRoute(props.details.route);
      // subscribeRideLocation(props.details.vehicle.id, (res) => {
      //   const loc = res.data.yt_vehicle[0].location.replace('(', '').replace(')', '').split(',');
      //   console.log(loc);
      //   dispatch({
      //     type: 'SET_TEMP_RIDE_DATA',
      //     payload: [parseFloat(loc[0]), parseFloat(loc[1])],
      //   });

      //   // checkRideMovement(loc);

      // });
    } else if (!state.rideFound) {
      // resubscribe nearby vehicles location
      setUserLocation();
      dispatch({
        type: 'SET_VISIBILITY',
        payload: true,
      });
      dispatch({
        type: 'TOGGLE_RIDE_STATE',
        payload: false,
      });
    }
  }, [state.rideFound, props.details]);

  const findDirections = (e) => {
    // Redundant function but who cares!
    getMapBoxDirections(state.start, e);
  };

  const rideAgain = () => {
    const { start, startAddress, end, endAddress } = history.location.state;
    const startCoordinates = {
      longitude: parseFloat(start.split(',')[0].replace('(', '')),
      latitude: parseFloat(start.split(',')[1].replace(')', '')),
      name: startAddress,
      address: startAddress,
    };
    const endCoordinates = {
      longitude: parseFloat(end.split(',')[0].replace('(', '')),
      latitude: parseFloat(end.split(',')[1].replace(')', '')),
      name: endAddress,
      address: endAddress,
    };
    setPickupText(startAddress);
    setDropText('');
    // start point
    dispatch({
      type: 'SET_START',
      payload: {
        longitude: endCoordinates.longitude,
        latitude: endCoordinates.latitude,
        name: endAddress,
        address: endAddress,
      },
    });
    dispatch({
      type: 'SET_SEARCH_LAYER',
      payload: new GeoJsonLayer({
        id: 'source',
        data: {
          coordinates: [startCoordinates.longitude, startCoordinates.latitude],
          type: 'Point',
        },
        getFillColor: [255, 221, 0, 255],
        getRadius: 600,
        pointRadiusMinPixels: 10,
        pointRadiusMaxPixels: 10,
      }),
    });

    // end point
    dispatch({
      type: 'SET_END',
      payload: {
        longitude: endCoordinates.longitude,
        latitude: endCoordinates.latitude,
        name: endAddress,
        address: endAddress,
      },
    });
    dispatch({
      type: 'SET_DESTINATION_LAYER',
      payload: new GeoJsonLayer({
        id: 'destination',
        data: {
          coordinates: [endCoordinates.longitude, endCoordinates.latitude],
          type: 'Point',
        },
        getFillColor: [0, 253, 0, 255],
        getRadius: 600,
        pointRadiusMinPixels: 10,
        pointRadiusMaxPixels: 10,
      }),
    });

    // map line
    getMapBoxDirections(startCoordinates, endCoordinates);
    const oldState = { ...history.location.state };
    delete oldState.isRideAgain;
    delete oldState.start;
    delete oldState.startAddress;
    delete oldState.end;
    delete oldState.endAddress;
    history.replace({ ...history.location, state: oldState });
  };

  useEffect(() => {
    console.log('userEffect 9')
    console.log("Nafees one")
    setUserLocation();
    console.log("Two")
    if (history.location && history.location.state) {
      const { isRideAgain } = history.location.state;
      if (isRideAgain) {
        rideAgain();
      }
    }
    console.log("Three")
    getUserSavedPlaces().then((res) => {
      console.log(res,"NLLLLLLLLLLLLLLLLL");
      //setPlacesData(res.data.yt_user_place);
    });
    return () => {
      unsubFromVehicles();
    };
  }, []);

  const selectSavedPlace = (data) => {
    const { location, name, address } = data;
    const coordinates = {
      longitude: parseFloat(location.split(',')[0].replace('(', '')),
      latitude: parseFloat(location.split(',')[1].replace(')', '')),
      name,
      address,
    };

    // // map line
    // getMapBoxDirections(startCoordinates, endCoordinates);
    if (inputType === 'pickup') {
      setPickupText(name);
      // start point
      dispatch({
        type: 'SET_START',
        payload: {
          longitude: coordinates.longitude,
          latitude: coordinates.latitude,
          name,
          address,
        },
      });
      dispatch({
        type: 'SET_SEARCH_LAYER',
        payload: new GeoJsonLayer({
          id: 'source',
          data: {
            coordinates: [coordinates.longitude, coordinates.latitude],
            type: 'Point',
          },
          getFillColor: [255, 221, 0, 255],
          getRadius: 600,
          pointRadiusMinPixels: 10,
          pointRadiusMaxPixels: 10,
        }),
      });

      dispatch({
        type: 'SET_VIEWPORT',
        payload: {
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          zoom: 14,
        },
      });

      getVehicles({ longitude: coordinates.longitude, latitude: coordinates.latitude }, (res) => {
        dispatch({
          type: 'SET_VEHICLES',
          payload: res.yt_get_vehicles_within_radius,
        });
      });
    } else if (inputType === 'drop') {
      setDropText(name);
      // end point
      dispatch({
        type: 'SET_END',
        payload: {
          longitude: coordinates.longitude,
          latitude: coordinates.latitude,
          name,
          address,
        },
      });
      dispatch({
        type: 'SET_DESTINATION_LAYER',
        payload: new GeoJsonLayer({
          id: 'destination',
          data: {
            coordinates: [coordinates.longitude, coordinates.latitude],
            type: 'Point',
          },
          getFillColor: [0, 253, 0, 255],
          getRadius: 600,
          pointRadiusMinPixels: 10,
          pointRadiusMaxPixels: 10,
        }),
      });

      dispatch({
        type: 'SET_VIEWPORT',
        payload: {
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          zoom: 14,
        },
      });

      if (state.searchResultLayer === null) {
        const { start } = state;
        dispatch({
          type: 'SET_SEARCH_LAYER',
          payload: new GeoJsonLayer({
            id: 'source',
            data: {
              coordinates: [start.longitude, start.latitude],
              type: 'Point',
            },
            getFillColor: [255, 221, 0, 255],
            getRadius: 600,
            pointRadiusMinPixels: 10,
            pointRadiusMaxPixels: 10,
          }),
        });
      }

      findDirections({
        longitude: coordinates.longitude,
        latitude: coordinates.latitude,
        name,
      });
      removeClass();
      // handleTransition();
    }
  };

  const setDestination = (viewport, event) => {
    const { start } = state;
    if (isIOS()) {
      removeClass();
    } else {
      removeClassAndroid();
    }
    setDropText('');
    dispatch({
      type: 'SET_VIEWPORT',
      payload: viewport,
    });
    dispatch({
      type: 'SET_END',
      payload: {
        longitude: event.center[0],
        latitude: event.center[1],
        name: event.text,
        address: event.place_name,
      },
    });
    dispatch({
      type: 'SET_DESTINATION_LAYER',
      payload: new GeoJsonLayer({
        id: 'destination',
        data: event.geometry,
        getFillColor: [255, 221, 0, 255],
        getRadius: 600,
        pointRadiusMinPixels: 10,
        pointRadiusMaxPixels: 10,
      }),
    });

    if (state.searchResultLayer === null && start !== null) {
      dispatch({
        type: 'SET_SEARCH_LAYER',
        payload: new GeoJsonLayer({
          id: 'source',
          data: { coordinates: [start.longitude, start.latitude], type: 'Point' },
          getFillColor: [0, 253, 0, 255],
          getRadius: 600,
          pointRadiusMinPixels: 10,
          pointRadiusMaxPixels: 10,
        }),
      });
    }
    // findDirections({ longitude: event.center[0], latitude: event.center[1], name: event.text });
    if (start !== null) {
      getRouteDirections(
        { longitude: event.center[0], latitude: event.center[1], name: event.text },
        state.start,
      );
    }
  };

  const renderPasses = () => {
    if (state.multiplePassFound && props.passData !== null) {
      return (
        <div id="car" className="cover-card-small55" ref={cardRef}>
          <div className={classes.HeaderSection}>
            <h3 className="YellowH3">Select Your Pass</h3>
          </div>
          <div className={classes.PassSection}>
            {props.passData.map((pass) => {
              return (
                <div className={classes.Pass}>
                  <div className={classes.passDetails}>
                    <p className="passtext">
                      <b style={{ color: '#FFFD00' }}>Pass ID:</b>
                      {pass.pass_number}
                    </p>
                    <p className="passtext">
                      <b style={{ color: '#FFFD00' }}>Pass Type:</b>
                      {pass.pass_type === 'LIMITED_RIDES' ? 'Limited Rides' : 'Unlimited Rides'}
                    </p>
                  </div>
                  <div
                    className={classes.passActions}
                    onClick={() => {
                      props.createRide(
                        state.start,
                        state.end,
                        state.result.entity.routes[0].distance / 1000,
                        state.vehicles,
                        state.result,
                        pass.id,
                      );

                      // dispatch({
                      //   type: 'TOGGLE_CODE',
                      //   payload: true,
                      // });
                    }}
                  >
                    <p style={{ color: 'white', fontWeight: 'bold' }}>Select</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return <></>;
  };

  // useEffect(() => {
  //   console.log(window.innerHeight);
  //   if (window.innerHeight)
  //   heightRef.current
  // }, [window.innerHeight]);

  const addClass = () => {
    console.log('adding class');
    const card = cardRef.current;
    const button = buttonRef.current;
    const locationIcon = locaitonIconRef.current;
    const iCard = innercard.current;
    const subButton = submitRef.current;

    setSearchingOpen(!searchingOpen);
    iCard.classList.add('transparent');
    subButton.classList.add('none');
    card.classList.add('expanded-card50');
    button.classList.remove('hidden');
    locationIcon.classList.add('some');
  };

  const addClassAndroid = () => {
    console.log('adding class android');

    const card = cardRef.current;
    const button = buttonRef.current;
    const locationIcon = locaitonIconRef.current;
    const iCard = innercard.current;
    const subButton = submitRef.current;

    setSearchingOpen(!searchingOpen);
    iCard.classList.add('transparent');
    subButton.classList.add('none');
    card.classList.add('expanded-card');
    button.classList.add('hidden');
    locationIcon.classList.add('none');
  };

  const renderLocationSearch = () => {
    const queryParamsGeolocation = {
      country: geoCountryCode,
    };
    if (proximityParams.latitude !== undefined) {
      queryParamsGeolocation.proximity = proximityParams;
    }

    if (state.multiplePassFound) {
      renderPasses();
    } else if (isLocationOn !== null) {
      return (
        <div id="car" ref={cardRef} className="cover-card">
          {/* <div className="recent-locations" ref={floatingref}>
            <div className="floating-div">
              <div className="floating-text">
                <b className="floating-yellow-text">The Word: </b>
                &nbsp; Dre&apos;s After Hours till... &nbsp;
                <img src={infosmall} alt="" />
                <div style={{ marginLeft: '4vh', display: 'flex' }}>
                  <img src={takeme} alt="" />
                </div>
              </div>
            </div>
          </div> */}

          <div className="ccard safariOnly" ref={innercard}>
            <div className="cardContainer">
              <h3 className="YellowH3">
                Yello, &nbsp;
                <span className="WhiteH3">{props.user.name}. &nbsp;</span>
                <span className="YellowH3">Where to? </span>
                {searchingOpen ? (
                  <CloseIcon
                    style={{
                      color: 'rgb(140, 138, 138)',
                      position: 'absolute',
                      right: 15,
                    }}
                    onClick={() => {
                      if (isIOS()) {
                        removeClass();
                      } else {
                        removeClassAndroid();
                      }
                    }}
                  />
                ) : null}
              </h3>

              {/* This rule is disabled becaus the input from mapBox is not controllable */}
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <div
                onClick={() => {
                  if (isIOS()) {
                    if (
                      cardRef &&
                      cardRef.current &&
                      !cardRef.current.classList.contains('expanded-card50')
                    ) {
                      addClass();
                    }
                  }
                  if (
                    !isIOS() &&
                    cardRef &&
                    cardRef.current &&
                    !cardRef.current.classList.contains('expanded-card')
                  ) {
                    addClassAndroid();
                  }
                }}
                className="LocationContainer"
                ref={containerRef}
                // onFocus={(e) => {
                //   if (innercard.current.classList.length === 1) {
                //     // handleTransition();
                //     console.log(pickLocation);
                //   }
                // }}
              >
                <InputLabel className={[classes.customLabel]} htmlFor="input-with-adornment">
                  Pickup Location
                </InputLabel>
                <Geocoder
                  ref={pickLocation}
                  mapRef={mapRef}
                  onSelected={(viewport, item) => {
                    handleOnResult(viewport, item);
                  }}
                  pointZoom={14}
                  pos="top"
                  hideOnSelect
                  limit={5}
                  // queryParams={{
                  //   country: geoCountryCode,
                  //   proximity: proximityParams,
                  // }}
                  queryParams={queryParamsGeolocation}
                  mapboxApiAccessToken={token}
                  containerRef={containerRef}
                  viewPort={state.viewPort}
                  blurEvent={(value) => {
                    if (value === ' ') {
                      setPickupText(defaultPickupText);
                    }
                    setTimeout(() => {
                      setInputType('');
                      if (isIOS()) {
                        removeClass();
                      } else {
                        removeClassAndroid();
                      }
                    }, 200);
                  }}
                  focusEvent={(value) => {
                    if (isIOS()) {
                      console.log(
                        'onclick destination',
                        cardRef,
                        cardRef.current,
                        cardRef.current.classList.contains('expanded-card50'),
                        state.end,
                        state.start,
                      );
                      if (
                        cardRef &&
                        cardRef.current &&
                        !cardRef.current.classList.contains('expanded-card50')
                      ) {
                        console.log('adding class from destination');
                        addClass();
                      }
                    }
                    if (
                      !isIOS() &&
                      cardRef &&
                      cardRef.current &&
                      !cardRef.current.classList.contains('expanded-card')
                    ) {
                      console.log('adding class from destination');
                      addClassAndroid();
                    }
                    if (value !== defaultPickupText) {
                      setPickupText(value);
                    } else {
                      setPickupText(' ');
                    }
                    setTimeout(() => setInputType('pickup'), 200);
                  }}
                  changeEvent={(value) => {
                    setPickupText(value);
                  }}
                  onViewportChange={(port) =>
                    dispatch({
                      type: 'SET_VIEWPORT',
                      payload: port,
                    })
                  }
                  initialInputValue={
                    (history.location.state && history.location.state.startAddress) ||
                    pickupText ||
                    (inputType !== 'pickup' && defaultPickupText)
                  }
                  textValue={pickupText}
                  resetText={() => setPickupText('')}
                  className="geocoder-input"
                  formatItem={(item) => {
                    return item.place_name;
                  }}
                />
              </div>
              <div
                // onFocus={(e) => {
                //   if (innercard.current.classList.length === 1) {
                //     console.log('innercard.current', innercard.current);
                //     // handleTransition();
                //   }
                // }}
                onFocus={() => {
                  if (isIOS()) {
                    if (
                      cardRef &&
                      cardRef.current &&
                      !cardRef.current.classList.contains('expanded-card50')
                    ) {
                      addClass();
                    }
                  }
                  if (
                    !isIOS() &&
                    cardRef &&
                    cardRef.current &&
                    !cardRef.current.classList.contains('expanded-card')
                  ) {
                    addClassAndroid();
                  }
                }}
                className="LocationContainer"
                ref={LocationContainerRef}
                style={{ marginBottom: 0 }}
              >
                <InputLabel className={[classes.customLabel]} htmlFor="input-with-adornment">
                  Drop Location
                </InputLabel>
                <Geocoder
                  mapRef={mapRef}
                  ref={dropLocation}
                  onSelected={setDestination}
                  pos="bottom"
                  formatItem={(item) => item.text}
                  limit={5}
                  hideOnSelect
                  mapboxApiAccessToken={token}
                  // queryParams={{ country: geoCountryCode, proximity: proximityParams }}
                  queryParams={queryParamsGeolocation}
                  containerRef={LocationContainerRef}
                  blurEvent={(value) => {
                    if (value === '') {
                      setDropText(defaultDropText);
                    }
                    setTimeout(() => {
                      setInputType('');
                      if (isIOS()) {
                        removeClass();
                      } else {
                        removeClassAndroid();
                      }
                    }, 200);
                  }}
                  focusEvent={(value) => {
                    console.log('focus');
                    if (isIOS()) {
                      console.log(
                        'onclick destination',
                        cardRef,
                        cardRef.current,
                        cardRef.current.classList.contains('expanded-card50'),
                        state.end,
                        state.start,
                      );
                      if (
                        cardRef &&
                        cardRef.current &&
                        !cardRef.current.classList.contains('expanded-card50')
                      ) {
                        console.log('adding class from destination');
                        addClass();
                      }
                    }

                    if (
                      !isIOS() &&
                      cardRef &&
                      cardRef.current &&
                      !cardRef.current.classList.contains('expanded-card')
                    ) {
                      console.log('adding class from destination');
                      addClassAndroid();
                    }
                    if (value !== defaultDropText) {
                      setDropText(value);
                    } else {
                      setDropText('');
                    }
                    setTimeout(() => setInputType('drop'), 200);
                  }}
                  changeEvent={(value) => {
                    setDropText(value);
                  }}
                  onViewportChange={(port) =>
                    dispatch({
                      type: 'SET_VIEWPORT',
                      payload: port,
                    })
                  }
                  viewPort={state.viewPort}
                  initialInputValue={
                    (history.location.state && history.location.state.endAddress) ||
                    dropText ||
                    (inputType !== 'drop' && defaultDropText)
                  }
                  textValue={dropText}
                  resetText={() => setDropText('')}
                  className="geocoder-input"
                />
              </div>
              {/* <div
                ref={recentLabelRef}
                style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        height: 'calc(var(--vh, 1vh) * 5)',
                      }}
              >
                <div
                  className="text-container"
                  style={{ paddingTop: '5px', flexDirection: 'row', float: 'left' }}
                  onClick={() => {
                          if (
                            !cardRef.current.classList.contains('expanded-card') ||
                            !cardRef.current.classList.contains('expanded-card50')
                          ) {
                            NavigationHandler(history, '/my-saved-places');
                          }
                        }}
                >
                  Saved Locations
                      
                </div>
                <div style={{
                  position: 'relative',
                  zIndex: 9999,
                  overflow:'scroll',
                  maxHeight:'100%',
                  minHeight: '290px'
                }}
                >
                  <div style={{ clear: 'both', zIndex: 10000 }}>
                    {placesData &&
                placesData.map((data) => {
                  // eslint-disable-next-line react/jsx-indent
                  console.log("AAinputtype", placesData);
                  return (
                    
                   
                    <div
                      style={{ color: 'rgb(181, 179, 179)', marginBottom: '5px' }}
                      key={data.id}
                      onClick={() => selectSavedPlace(data)}
                    >
                      <div style={{ fontWeight: 'bold' }}>{data.title}</div>
                      <div>{data.address}</div>
                    </div>
                    
                  )
                })}
                  </div>
                </div>
              </div>
               */}
              {((cardRef.current &&
                (!cardRef.current.classList.contains('expanded-card') ||
                  !cardRef.current.classList.contains('expanded-card50'))) ||
                (inputType !== '' &&
                  cardRef.current &&
                  (cardRef.current.classList.contains('expanded-card') ||
                    cardRef.current.classList.contains('expanded-card50')) &&
                  ((inputType === 'pickup' &&
                    (pickupText.trim() === '' || pickupText === defaultPickupText)) ||
                    (inputType === 'drop' &&
                      (dropText.trim() === '' || dropText === defaultDropText))))) && (
                // eslint-disable-next-line react/jsx-indent
                <div
                  ref={recentLabelRef}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    height: 'calc(var(--vh, 1vh) * 5)',
                  }}
                >
                  <div
                    className="text-container"
                    style={{ paddingTop: '5px', flexDirection: 'row', float: 'left' }}
                    onClick={() => {
                      if (
                        !cardRef.current.classList.contains('expanded-card') ||
                        !cardRef.current.classList.contains('expanded-card50')
                      ) {
                        NavigationHandler(history, '/my-saved-places');
                      }
                    }}
                  >
                    Saved Locations
                    {!cardRef.current.classList.contains('expanded-card') &&
                      !cardRef.current.classList.contains('expanded-card50') && (
                        <img
                          src={RightArrowSmall}
                          alt="nada"
                          className={classes.rightArrowIcon}
                          style={{ width: 16, height: 16, marginLeft: 5, marginTop: 2 }}
                        />
                      )}
                  </div>
                  <div
                    style={{
                      position: 'relative',
                      zIndex: 9999,
                      overflow: 'scroll',
                      maxHeight: '100%',
                      minHeight: '290px',
                    }}
                  >
                    <div style={{ clear: 'both' }}>
                      {cardRef.current &&
                        (cardRef.current.classList.contains('expanded-card') ||
                          cardRef.current.classList.contains('expanded-card50')) &&
                        ((inputType === 'pickup' &&
                          (pickupText.trim() === '' || pickupText === defaultPickupText)) ||
                          (inputType === 'drop' &&
                            (dropText.trim() === '' || dropText === defaultDropText))) &&
                        placesData &&
                        placesData.map((data) => {
                          // eslint-disable-next-line react/jsx-indent
                          return (
                            <div
                              style={{ color: 'rgb(181, 179, 179)', marginBottom: '5px' }}
                              key={data.id}
                              onClick={() => selectSavedPlace(data)}
                            >
                              <div style={{ fontWeight: 'bold' }}>{data.title}</div>
                              <div>{data.address}</div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )}
              {/* cardRef.current cardRef.current.classList.contains('expanded-card') */}
              <div ref={submitRef} className={classes.ButtonContainer}>
                <PrimaryButton
                  type="primary"
                  variant="fullWidth"
                  Title="Next"
                  disabled={state.start === null || state.end === null}
                  onClick={() => {
                    if (state.vehicles.length > 0 && state.start !== null && state.end !== null) {
                      props.checkPass(
                        state.start,
                        state.end,
                        state.result.entity.routes[0].distance / 1000,
                        state.vehicles,
                        state.result,
                      );

                      // props.createRide(
                      //   state.start,
                      //   state.end,
                      //   state.result.entity.routes[0].distance / 1000,
                      //   state.vehicles,
                      //   state.result,
                      // );
                    } else if (state.vehicles.length === 0) {
                      dispatch({
                        type: 'SET_ERROR',
                        payload:
                          'No Yellos are available to serve you right now!! Try after a few minutes!',
                      });
                    } else if (state.start === null || state.end === null) {
                      dispatch({
                        type: 'SET_ERROR',
                        payload: 'source/destination not set',
                      });
                    } else {
                      dispatch({
                        type: 'SET_ERROR',
                        payload: 'Something went wrong',
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const openProfile = () => {
    NavigationHandler(history, '/driverdetail', null, { driverId: props.details.driver.id });
  };

  const handleCloseRideModal = () => {
    // setTimeout(() => {
    //   setSucceeded(false);
    //   navigationHandler(history, '/home', '');
    // }, 500);

    setRideCancelModal(false);

    // props.cancelRide('', props.details.id);
  };

  const handleConfirmRideCancel = () => {
    props.cancelRide('', props.details.id);
    setRideCancelModal(false);
  };

  const renderRideControls = useCallback(() => {
    return (
      <div id="car" className="cover-card-small55" ref={cardRef}>
        <div className={classes.cardContainer}>
          <div className={classes.HeaderSection}>
            <h3 className="YellowH3">
              {props.details.status && props.details.status === 'IN_PROGRESS'
                ? 'You are on your way'
                : 'Your taxi is on the way'}
            </h3>
          </div>
          <div className={classes.DriverSection}>
            <div
              className={classes.DriverIcon}
              onClick={() => {
                openProfile();
              }}
            >
              <img
                src={props.details.driver.profile_photo_url || DriverIcon}
                alt="driver"
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  border: '3px solid #fff',
                }}
              />
              <img
                src={Info}
                alt="info"
                style={{ position: 'absolute', left: '4px', bottom: '-8px' }}
              />
            </div>
            <div className={classes.DriverDetailsSection}>
              <p>
                <b className="driver-name-font">{props.details.driver.full_name}</b>
                <br />
                <span style={{ fontSize: '15pt' }}>&#9733;</span>
                {props?.details?.driver?.average_rate?.toFixed('1') || 0}
              </p>
            </div>
            <div className={classes.VehicleDetailsSection}>
              <p className="rating-font">
                {props.details.vehicle.name || 'loading'}
                <br />
                <b className="rating-font-yellow">Taxi No:</b>
                &nbsp; {props.details.vehicle.registration_number || 'loading'}
              </p>
            </div>
          </div>
          <div
            className={classes.OptionSection}
            style={props.details.status === 'IN_PROGRESS' ? { justifyContent: 'center' } : {}}
          >
            <div
              className={
                props.details.status !== 'IN_PROGRESS' ? classes.Option : classes.OptionCenter
              }
            >
              <a
                href={`tel:${props.details.driver.country_code}${props.details.driver.mobile}`}
                className={classes.ImageIcon}
                style={
                  props.details.status && props.details.status === 'IN_PROGRESS'
                    ? {
                        display: 'flex',
                        height: '100%',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'center',
                      }
                    : { display: 'flex', height: '100%', alignItems: 'center' }
                }
              >
                {props.details.status && props.details.status === 'IN_PROGRESS' ? (
                  <PrimaryButton type="primary" Title="Call" style={{ width: '100%' }} />
                ) : (
                  <img src={Call} alt="Call" className={classes.ImageIcon} />
                )}
              </a>
            </div>

            <div
              className={
                props.details.status !== 'IN_PROGRESS' ? classes.Option : classes.OptionCenter
              }
              style={props.details.status === 'IN_PROGRESS' ? { display: 'none' } : null}
              onClick={() => {
                if (props.details.status && props.details.status !== 'IN_PROGRESS') {
                  dispatch({
                    type: 'TOGGLE_CODE',
                    payload: true,
                  });
                }
              }}
            >
              {props.details.status && props.details.status !== 'IN_PROGRESS' && (
                <img src={QR} alt="QR" className={classes.ImageIcon} />
              )}
            </div>
            <div
              className={
                props.details.status !== 'IN_PROGRESS' ? classes.Option : classes.OptionCenter
              }
              style={props.details.status === 'IN_PROGRESS' ? { display: 'none' } : null}

              // style={{ justifyContent: 'flex-end' }}
            >
              {props.details.status !== 'IN_PROGRESS' && (
                <img
                  src={Cancel}
                  alt="cancel"
                  className={classes.ImageIcon}
                  onClick={(e) => {
                    setRideCancelModal(true);
                    // handleCloseRideModal();
                    // props.cancelRide('', props.details.id);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }, [state.rideFound, state.details, props.details]);

  const renderQrCode = useCallback(() => {
    if (state.showCode && props.details.status !== 'IN_PROGRESS') {
      const url =
        `https://chart.googleapis.com/chart?chs=250x250&cht=qr&` +
        `chl={"confirmation_code":"${props.details.confirmation_code}"` +
        `,"ride_id":"${props.details.id}"}&choe=UTF-8`;
      return (
        <div
          style={{
            zIndex: 2,
            height: '100vh',
            width: '100vw',
            backgroundColor: 'rgba(0,0,0,0.7)',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{ zIndex: 10 }}
            onClick={() => {
              dispatch({
                type: 'TOGGLE_CODE',
                payload: false,
              });
              dispatch({
                type: 'SET_PASS',
                payload: false,
              });
            }}
          >
            <img
              src={close}
              alt="close"
              style={{
                height: '4vh',
                position: 'absolute',
                top: '15px',
                right: '15px',
              }}
            />
          </div>
          <div className={classes.planHeaderTextContainer}>
            <span id="planType">To start your trip</span>
            <span id="planPrice">Show the QR Code to your driver</span>
          </div>
          <img src={PlanCardDesign} alt="nada" className={classes.planCardDesign} />
          <img src={url} alt="Qr Code" className={classes.qrImg} />
        </div>
      );
    }
    return null;
  }, [state.showCode, props.details]);

  const RenderConfirmCancelRidePopup = ({ handleConfirmCancel, handleClose }) => {
    return (
      <div
        style={{
          zIndex: 2,
          height: '100vh',
          width: '100vw',
          backgroundColor: 'rgba(0,0,0,0.7)',
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ zIndex: 10 }} onClick={handleClose}>
          <img
            src={close}
            alt="close"
            style={{
              height: '4vh',
              position: 'absolute',
              top: '15px',
              right: '15px',
            }}
          />
        </div>
        <div className={classes.cardModal}>
          {/* <img src={Verified} alt="nothing" className={classes.verifiedIconModal} /> */}
          <div className={classes.headerTextModal}>
            <span>Do you want to cancel ride?</span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexFlow: 'row',
            }}
          >
            <PrimaryButton
              type="primary"
              // variant="fullWidth"
              onClick={handleClose}
              className={classes.planButtonModal}
              Title="Close"
            />
            <PrimaryButton
              type="primary"
              // variant="fullWidth"
              onClick={handleConfirmCancel}
              className={classes.planButtonModal}
              Title="Cancel Ride"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSOSDialog = () => {
    if (state.open) {
      const loc = Object.create({
        rideState: `(${state.rideData.coordinates[0]}, ${state.rideData.coordinates[1]})`,
      });
      return (
        <Dialog fullScreen open={state.open}>
          <SOSScreen
            loc={loc}
            rideid={props.details.id}
            userid={props.user.id}
            driverid={props.details.driver.id}
            dispatch={dispatch}
          />
        </Dialog>
      );
    }
    return null;
  };

  const renderCarsAround = () => {
    if (state.vehicles.length > 0 && !state.rideFound) {
      return state.vehicles.map((vehicle) => {
        const t = vehicle.location.replace('(', '').replace(')', '').split(',');
        return (
          <Marker
            key={vehicle.id}
            offsetLeft={-20}
            longitude={parseFloat(t[0])}
            latitude={parseFloat(t[1])}
          >
            <img src={Car2} alt="car" />
          </Marker>
        );
      });
    }
    return null;
  };

  const handleClose = () => {
    dispatch({
      type: 'SET_DIALOG_STATE',
      payload: false,
    });
    dispatch({
      type: 'SET_ERROR',
      payload: null,
    });
  };

  const renderError = () => {
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={state.dialogState}>
        <DialogTitle id="simple-dialog-title">Oops Something went wrong</DialogTitle>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            margin: '10pt',
          }}
        >
          {state.error}
        </div>
      </Dialog>
    );
  };

  const repositionUser = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch({
          type: 'SET_VISIBILITY',
          payload: false,
        });
        dispatch({
          type: 'SET_VEHICLES',
          payload: [],
        });
        dispatch({
          type: 'SET_VIEWPORT',
          payload: {
            zoom: 14,
            latitude: position.coords.latitude + Math.random() * 0.0000001,
            longitude: position.coords.longitude + Math.random() * 0.0000001,
          },
        });
        getLocationAdd(position.coords).then(
          (res) => {
            if (res) {
              dispatch({
                type: 'SET_LOCATION_NAME',
                payload: res.name ? res.name : 'Current Location',
              });
              dispatch({
                type: 'SET_START',
                payload: {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                  name: res.name,
                },
              });
              dispatch({
                type: 'SET_VISIBILITY',
                payload: true,
              });
              // handleTransition();
            } else {
              dispatch({
                type: 'SET_LOCATION_NAME',
                payload: 'Your Location',
              });
            }
            dispatch({
              type: 'SET_CURRENT_LOCATION',
              payload: {
                lat: position.coords.latitude,
                long: position.coords.longitude,
              },
            });

            unsubFromVehicles();

            getVehicles(position.coords, (resp) => {
              getTime(resp.yt_get_vehicles_within_radius, position.coords);
              dispatch({
                type: 'SET_VEHICLES',
                payload: resp.yt_get_vehicles_within_radius,
              });
            });
          },
          (err) => {
            if (
              (err.message && err.message === 'User denied Geolocation') ||
              (err.message &&
                err.message === 'Origin does not have permission to use Geolocation service')
            ) {
              dispatch({
                type: 'SET_ERROR',
                payload: 'Please enable location permissions',
              });

              dispatch({
                type: 'SET_LOCATION_NAME',
                payload: '',
              });
            } else {
              dispatch({
                type: 'SET_ERROR',
                payload: 'Error fetching Location',
              });

              dispatch({
                type: 'SET_LOCATION_NAME',
                payload: '',
              });
            }
          },
        );
      },
      null,
      { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true },
    );
  };

  useEffect(() => {
    console.log('userEffect 8')
    console.log('state.DestinationLayer =>', state.DestinationLayer);
  }, [state.DestinationLayer]);

  return (
    <div className={classes.container}>
      {renderQrCode()}
      {renderError()}
      {renderSOSDialog()}
      {rideCancelModal && (
        <RenderConfirmCancelRidePopup
          handleClose={() => handleCloseRideModal()}
          handleConfirmCancel={() => handleConfirmRideCancel()}
          // handleConfirm=
        />
      )}
      <DeckGL
        initialViewState={state.viewPort}
        ContextProvider={MapContext.Provider}
        height="calc(var(--vh, 1vh) * 70)"
        width="100%"
        controller
        layers={[state.searchResultLayer, state.DestinationLayer]}
        style={deckStyles}
        // onViewStateChange={(p) => {
        //   console.log(p);
        // }}
      >
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            right: '11%',
            top: '12%',
            zIndex: 4,
            background: 'rgb(118, 38, 188)',
            height: 'calc(var(--vh, 1vh) * 5)',

            width: 'calc(var(--vh, 1vh) * 5)',
            borderRadius: '40px',
          }}
          ref={locaitonIconRef}
          onClick={() => {
            repositionUser();
          }}
        >
          <MyLocationIcon style={{ color: 'white', width: 'calc(var(--vh, 1vh) * 3)' }} />
        </div>
        <ReactMapGL
          mapStyle="mapbox://styles/darjipranav/ckcftcxph0d7c1il7dvcloybi"
          mapboxApiAccessToken={token}
          transitionDuration={1000}
          transitionInterpolator={new FlyToInterpolator()}
          {...state.viewPort}
          // onViewportChange={(port) => {
          //   dispatch({
          //     type: 'SET_VIEWPORT',
          //     payload: port,
          //   });
          // }}
          width="100vw"
          height="calc(var(--vh, 1vh) * 55)"
          ref={mapRef}
        >
          {state.rideData !== null && (
            <Marker
              id="cabbie"
              transformAngle={state.angle}
              offsetLeft={-40}
              offsetTop={-40}
              key="cabbie"
              longitude={parseFloat(state.rideData.coordinates[0])}
              latitude={parseFloat(state.rideData.coordinates[1])}
            >
              <img src={Car2} alt="car" style={{ transform: `rotate(${state.angle}deg)` }} />
            </Marker>
          )}
          {renderCarsAround()}
          {Object.keys(state.currentLocation).length !== 0 && state.markerVisibility ? (
            <Marker
              offsetLeft={-20}
              latitude={state.currentLocation.lat}
              longitude={state.currentLocation.long}
              captureClick
              onClick={() => {
                console.log('click');
                // handleTransition();
              }}
              onDragStart={() => {
                console.log('drag');
              }}
              className="location-marker"
            >
              {/* <img src={Location} alt="i'm here" className="location-marker" /> */}
              <img src={Location} className="marker-div-image" alt="imhere" />

              <div className="markerdiv">
                <div className="current-marker">
                  <>
                    <>
                      <b
                        className="current-marker-time"
                        style={ETA === null || ETA > 10 ? { left: '2px' } : { left: '5px' }}
                      >
                        &nbsp; {ETA === null || ETA > 10 ? '10+' : ETA}
                      </b>
                    </>
                    <>
                      {' '}
                      <b className="current-marker-time1">min</b>
                    </>
                  </>
                </div>

                <div className="current-marker-location">
                  <div
                    style={{
                      display: 'flex',
                      flex: 0.8,
                      height: '70%',
                      justifyContent: 'center',
                    }}
                  >
                    {state.currentLocationName}
                  </div>
                </div>
                <div className="current-marker-icon-div">
                  <img className="current-marker-icon" src={nextarrow} alt="" />
                </div>
              </div>
            </Marker>
          ) : (
            <div />
          )}
        </ReactMapGL>

        <div style={{ zIndex: 2 }}>
          <div style={{ zIndex: 3 }} className="SOS">
            {state.rideFound && (
              <img
                onClick={() => {
                  dispatch({
                    type: 'SET_OPEN',
                    payload: true,
                  });
                }}
                src={sos}
                style={{ height: '5vh', widht: '5vh' }}
                alt="SOS"
              />
            )}
          </div>
          <div className={classes.hamburgerContainer}>
            <HamburgerButton buttonRef={buttonRef} onClick={toggleDrawer('left', true)} />
          </div>
          <SwipeableDrawer
            anchor="left"
            open={state.stateDrawer.left}
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}
          >
            <LeftMenuCard
              onClick={toggleDrawer('left', false)}
              userDataCheck={props.userDataCheck}
            />
          </SwipeableDrawer>
        </div>
        {state.rideFound && state.details !== null ? renderRideControls() : renderLocationSearch()}
        {renderPasses()}
      </DeckGL>
    </div>
  );
}
