/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable global-require */
import React from 'react';

import { useHistory } from 'react-router-dom';

// importing UI helpers
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import HeaderTitle from '../../../Components/headertitle/HeaderTitle';
import useStyles from '../css/HelpDeskCss';
import CancelIconSmall from '../../../assets/svg/cancelIconSmall.svg';
import AddIconGrey from '../../../assets/svg/addIconGrey.svg';

import PrimaryButton from '../../../Components/buttons/PrimaryButton';

export default function HelpDeskPage() {
  const hiddenFileInput1 = React.useRef(null);
  const hiddenFileInput2 = React.useRef(null);
  const hiddenFileInput3 = React.useRef(null);
  const classes = useStyles();
  const [userImg, setUserImg] = React.useState({
    img1: null,
    img2: null,
    img3: null,
  });
  const history = useHistory();

  const handleClick = (type) => {
    switch (type) {
      case 1: {
        return hiddenFileInput1.current.click();
      }
      case 2: {
        return hiddenFileInput2.current.click();
      }
      case 3: {
        return hiddenFileInput3.current.click();
      }
      default:
        return null;
    }
  };

  const SubmitButton = () => {
    console.log('hello');
  };

  return (
    <div className={classes.root}>
      <HeaderTitle title="Help Desk" backBtn={() => history.goBack()} />
      <div className={classes.cardBg}>
        <div className={classes.placeCardContainer}>
          <div className={classes.placeContainer}>
            <div className={classes.inputIssueImageContainer}>
              <div className={classes.inputContainer}>
                <InputLabel className={classes.customLabel}>
                  Tell us about your issue rather
                </InputLabel>
                <Input
                  className={classes.inputContainer}
                  placeholder="Describe your issue here"
                  // inputProps={{ 'aria-label': 'description' }}
                />
              </div>
              <div className={classes.inputImagesContainer}>
                <div className={classes.imageUploadBox}>
                  {userImg.img1 ? (
                    <img
                      src={CancelIconSmall}
                      className={classes.cancelIconSmall}
                      alt="cancel icon"
                      onClick={() => setUserImg({ ...userImg, img1: null })}
                    />
                  ) : null}
                  {userImg.img1 ? (
                    <img
                      id="img-upload"
                      className={classes.userImage}
                      src={URL.createObjectURL(userImg.img1)}
                      alt={userImg.img1.name}
                    />
                  ) : (
                    <div className={classes.addImageContainer}>
                      <img
                        src={AddIconGrey}
                        className={classes.addIcon}
                        alt="add icon"
                        onClick={() => handleClick(1)}
                      />
                      <input
                        id="myFileInput"
                        type="file"
                        accept="image/*"
                        multiple={false}
                        style={{ display: 'none' }}
                        ref={hiddenFileInput1}
                        //   accept="image/*;capture=camera"
                        onChange={(e) => setUserImg({ ...userImg, img1: e.target.files[0] })}
                      />
                    </div>
                  )}
                </div>
                <div className={classes.imageUploadBox}>
                  {userImg.img2 ? (
                    <img
                      src={CancelIconSmall}
                      className={classes.cancelIconSmall}
                      alt="cancel icon"
                      onClick={() => setUserImg({ ...userImg, img2: null })}
                    />
                  ) : null}
                  {userImg.img2 ? (
                    <img
                      id="img-upload"
                      className={classes.userImage}
                      src={URL.createObjectURL(userImg.img2)}
                      alt={userImg.img2.name}
                    />
                  ) : (
                    <div className={classes.addImageContainer}>
                      <img
                        src={AddIconGrey}
                        className={classes.addIcon}
                        alt="add icon"
                        onClick={() => handleClick(2)}
                      />
                      <input
                        id="myFileInput"
                        type="file"
                        accept="image/*"
                        multiple={false}
                        style={{ display: 'none' }}
                        ref={hiddenFileInput2}
                        //   accept="image/*;capture=camera"
                        onChange={(e) => setUserImg({ ...userImg, img2: e.target.files[0] })}
                      />
                    </div>
                  )}
                </div>
                <div className={classes.imageUploadBox}>
                  {userImg.img3 ? (
                    <img
                      src={CancelIconSmall}
                      className={classes.cancelIconSmall}
                      alt="cancel icon"
                      onClick={() => setUserImg({ ...userImg, img3: null })}
                    />
                  ) : null}
                  {userImg.img3 ? (
                    <img
                      id="img-upload"
                      className={classes.userImage}
                      src={URL.createObjectURL(userImg.img3)}
                      alt={userImg.img3.name}
                    />
                  ) : (
                    <div className={classes.addImageContainer}>
                      <img
                        src={AddIconGrey}
                        className={classes.addIcon}
                        alt="add icon"
                        onClick={() => handleClick(3)}
                      />
                      <input
                        id="myFileInput"
                        type="file"
                        accept="image/*"
                        multiple={false}
                        style={{ display: 'none' }}
                        ref={hiddenFileInput3}
                        //   accept="image/*;capture=camera"
                        onChange={(e) => setUserImg({ ...userImg, img3: e.target.files[0] })}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <PrimaryButton
              type="primary"
              variant="fullWidth"
              className={classes.saveButton}
              Title="Submit"
              onClick={() => SubmitButton()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
