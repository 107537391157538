/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import Div100vh from 'react-div-100vh';

// importing UI components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReactInputVerificationCode from 'react-input-verification-code';
import YelloLogo from '../../../assets/svg/yelloLogo.svg';

// importing helpers
import useStyles from '../css/OTPpageCSS';
import navigationHandler from '../../App/helpers/NavigationHandler';
import PrimaryButton from '../../../Components/buttons/PrimaryButton';
import HeaderBackButton from '../../../Components/buttons/HeaderBackButton';
import { firebase } from '../../../config/firebaseConfig';

export default function OTPScreen() {
  const [number, setNumber] = React.useState('');
  const [timer, setTimer] = React.useState(30);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const svgPath = `${YelloLogo}#svgView(preserveAspectRatio(none))`;

  React.useEffect(() => {
    // localStorage.setItem('token', 'token');
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
        console.log(response);
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
      },
    });
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  }, []);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [timer]);

  const handleChange = (otp) => {
    setNumber(otp);
  };

  const handleConfirmation = () => {
    setLoading(true);
    location.param.confirmationResult
      .confirm(number)
      .then((result) => {
        // User signed in successfully.
        localStorage.setItem('refreshToken', result.user.refreshToken);
        const { user } = result;
        let tokenResult = null;
        user.getIdTokenResult(/* force refresh */ true).then((res) => {
          tokenResult = res;
          const idToken = tokenResult.token;
          if (tokenResult.claims['https://hasura.io/jwt/claims']) {
            localStorage.setItem(
              'userIdHasura',
              tokenResult.claims['https://hasura.io/jwt/claims']['x-hasura-user-id'],
            );
            localStorage.setItem(
              'userRoleHasura',
              tokenResult.claims['https://hasura.io/jwt/claims']['x-hasura-default-role'],
            );
            localStorage.setItem('token', idToken);

            console.log("hhhsdhasdhf", tokenResult.claims['https://hasura.io/jwt/claims']['x-hasura-default-role'])

            // localStorage.setItem('token', idToken);

            // hasura claims not found
            // tokenResult = user.getIdTokenResult(true);
            // idToken = tokenResult.token;
            // hasura claims will be present upon token refresh
            navigationHandler(history, '/home');
          } else {
            history.push({
              pathname: `/profilecomplete`,
              state:
                {
                  code: location.param.code,
                  number: location.param.number,
                  token: idToken,
                } || {},
            });
            // navigationHandler(history, '/profilecomplete', null, {
            //   code: location.param.code,
            //   number: location.param.number,
            // });
          }
        });
        // this token will be used to connect with Hasura
        // ...
      })
      .catch((err) => {
        switch (err.code) {
          case 'auth/invalid-phone-number':
            setError('Invalid phone number, please enter a valid number');
            break;
          case 'auth/invalid-verification-code':
            setError('Wrong Verification code. Try Again');
            break;
          default:
            setError('Something went wrong');
            break;
        }
        setLoading(false);
        // User couldn't sign in (bad verification code?)
        // ...
      });
  };

  const handleResendOTP = async () => {
    const { code } = location.param;
    const temp = `${code}${location.param.number}`;
    const appVerifier = window.recaptchaVerifier;

    firebase
      .auth()
      .signInWithPhoneNumber(temp, appVerifier)
      .then((res) => {
        setTimer(-1);
        setTimeout(() => setTimer(30), 3000);
        setError(null);
        navigationHandler(history, '/verifyotp', {
          code,
          number,
          verificationid: res.verificationId,
          confirmationResult: res,
        });
      })
      .catch((err) => {
        switch (err.code) {
          case 'auth/invalid-phone-number':
            setError('Invalid phone number, please enter a valid number');
            break;
          case 'auth/invalid-verification-code':
            setError('Wrong Verification code. Try Again');
            break;
          default:
            setError('Something went wrong');
            break;
        }
      });
  };

  return (
    <Div100vh>
      <Grid container className={classes.root}>
        <div id="recaptcha-container" style={{ visibility: 'hidden' }} />

        <div className={classes.rootInnerContainer}>
          <div
            style={{
              display: 'flex',
              // height: 'calc(var(--vh, 1vh) * 60)',
              flex: 0.6,
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 0.2,
                width: '100%',
                paddingLeft: '5%',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <HeaderBackButton
                backBtn={() => history.goBack()}
                headerStyle={{ position: 'absolute' }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flex: 0.8,
                width: '100%',
                alignItems: 'center',
                justifyContent: 'flex-start',
                backgroundImage: `url(${YelloLogo})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left center',
                backgroundSize: 'auto 120%',
                height: 'auto',
              }}
            >
              {/* <img src={YelloLogo} style={{}} alt="nothing" /> */}
            </div>
          </div>
          <div className={classes.cardContainer}>
            <Grid container className={classes.cardInnerContainer}>
              <Grid className={classes.welcomeTextContainer}>
                <Typography className={classes.welcomeText}>
                  Please enter the 6 digit OTP sent to your mobile number
                </Typography>
              </Grid>
              <Grid className={classes.inputNumberContainer}>
                <Grid className={classes.otpInputContainer}>
                  <div className="custom-styles">
                    <ReactInputVerificationCode
                      value={number}
                      length={6}
                      placeholder=""
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {timer === 0 && (
                    <div className={classes.resendOtpContainer} onClick={() => handleResendOTP()}>
                      <span>Resend OTP</span>
                    </div>
                  )}
                  {timer > 0 && (
                    <div className={classes.resendOtpContainer}>
                      <span>{`${timer} seconds`}</span>
                    </div>
                  )}
                  {timer === -1 && (
                    <div className={classes.resendOtpContainer}>
                      <span>OTP is sent again</span>
                    </div>
                  )}
                  {error ? (
                    <div className={classes.errorMessage}>
                      <span>{error}</span>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
              <Grid style={{ display: 'flex', flex: 0.3 }}>
                <PrimaryButton
                  type="primary"
                  variant="fullWidth"
                  className={classes.nextButton}
                  onClick={() => {
                    handleConfirmation();
                  }}
                  Title={loading ? 'Loading' : 'Verify'}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </Div100vh>
  );
}
