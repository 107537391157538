/* eslint-disable import/no-cycle */
import { gql } from '@apollo/client';
import client from '../Containers/App/config/ApolloClient';
import slackAxios from '../slack';

let rideSubscriber = null;
let cancelRequestSubscriber = null;
let rideLocationSubscriber = null;

export const getActivePasses = (id) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query MyQuery($user_id: uuid!) {
            yt_boarding_pass(where: { user_id: { _eq: $user_id }, status: { _eq: "ACTIVE" } }) {
              created_at
              id
              pass_number
              pass_type
              total_trips
              total_daily_trips
            }
          }
        `,
        variables: {
          user_id: id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        slackAxios({ file: 'getActivePasses', err });

        reject(err);
      });
  });
};

export const LookForRides = (id, userid, callback) => {
  try {
    rideSubscriber = client
      .subscribe({
        query: gql`
          subscription MySubscription($id: uuid!, $user_id: uuid!) {
            yt_ride(distinct_on: id, where: { id: { _eq: $id }, user_id: { _eq: $user_id } }) {
              boarding_pass_id
              driver {
                address
                full_name
                mobile
                country_code
                id
                average_rate
                profile_photo_file_id
              }
              driver_user_id
              confirmation_code
              id
              user_id
              vehicle_id
              status
              mapbox_route
              vehicle {
                registration_number
                name
                id
                location
              }
            }
          }
        `,
        variables: {
          id,
          user_id: userid,
        },
        fetchPolicy: 'no-cache',
      })
      .subscribe((res) => {
        callback(res);
      });
  } catch (error) {
    slackAxios({ file: 'LookForRides', error });
    console.log(error);
  }
};

export const checkCancelRequest = (id, userid, callback) => {
  try {
    cancelRequestSubscriber = client
      .subscribe({
        query: gql`
          subscription MySubscription($ride_id: uuid!, $user_id: uuid!) {
            ride_request: yt_ride_request(
              where: { ride_id: { _eq: $ride_id }, user_id: { _eq: $user_id } }
            ) {
              ride_id
              available
              is_accepted
              is_rejected
              accepted_at
              rejected_at
            }
          }
        `,
        variables: {
          ride_id: id,
          user_id: userid,
        },
      })
      .subscribe((res) => {
        const rideRequest = res.data.ride_request;
        const rejectedCount = rideRequest.filter((item) => item.is_rejected === true).length;
        if (rideRequest && rejectedCount === rideRequest.length) {
          callback('Rejected by all drivers', id, true);
          if (cancelRequestSubscriber !== null) {
            cancelRequestSubscriber.unsubscribe();
          }
        }
      });
  } catch (error) {
    slackAxios({ file: 'checkCancelRequest', error });
    console.log(error);
  }
};

export const CreateRide = async (config, cb, setLoader) => {
  // const response = await getActivePasses(config.user_id);
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation(
            $boarding_pass_id: String!
            $distance: Float!
            $end_address: address!
            $end_location: locationPoint!
            $nearest_vehicles: [vehicle!]!
            $start_address: address!
            $start_location: locationPoint!
            $user_id: String!
            $result: jsonb!
          ) {
            CreateRide(
              ride: {
                boarding_pass_id: $boarding_pass_id
                distance: $distance
                end_address: $end_address
                end_location: $end_location
                nearest_vehicles: $nearest_vehicles
                start_address: $start_address
                start_location: $start_location
                user_id: $user_id
                mapbox_route: $result
              }
            ) {
              user_id
              status
              id
              distance
              boarding_pass_id
            }
          }
        `,
        variables: {
          boarding_pass_id: config.boardingPass,
          distance: config.distance,
          end_address: config.end_address,
          end_location: config.end_location,
          nearest_vehicles: config.nearest_vehicles,
          start_address: config.start_address,
          start_location: config.start_location,
          user_id: config.user_id,
          result: config.result,
        },
      })
      .then((res) => {
        // cb(res);
        LookForRides(res.data.CreateRide.id, res.data.CreateRide.user_id, cb);
      })
      .catch((error) => {
        setLoader(false);
        slackAxios({ file: 'CreateRide', error });
        reject(error);
      });
  });

  // } catch (error) {
  //   console.log(error);

  //   setLoader(false);
  //   throw error;
  // }
};

export const subscribeRideLocation = (vehicleid, callback) => {
  rideLocationSubscriber = client
    .subscribe({
      query: gql`
        subscription MySubscription($vehicle_id: uuid!) {
          yt_vehicle(where: { id: { _eq: $vehicle_id } }) {
            location
            name
            id
          }
        }
      `,
      variables: {
        vehicle_id: vehicleid,
      },
    })
    .subscribe((res) => {
      console.log({ res });
      callback(res);
    });
};

export const searchForExistingRide = (id) => {
  // console.log(id);
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query MyQuery($user_id: uuid!) {
            yt_ride(
              limit: 1
              where: { user_id: { _eq: $user_id } }
              order_by: { updated_at: desc }
            ) {
              mapbox_route
              status
              id
              updated_at
              driver_user_id
              confirmation_code
              driver {
                address
                email
                full_name
                mobile
                id
                average_rate
                profile_photo_file_id
              }
              vehicle {
                id
                registration_number
                name
                location
              }
              end_location
              end_address
              start_address
              start_location
              distance
              user_id
            }
          }
        `,
        variables: {
          user_id: id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        slackAxios({ file: 'searchForExistingRide', err });
        reject(err);
      });
  });
};

export const driverDetail = (id) => {
  // console.log(id);
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query MyQuery($driver_id: uuid!) {
            yt_user(where: { id: { _eq: $driver_id } }) {
              full_name
              id
              country_code
              mobile
              profile_photo_file_id
              created_at
              average_rate
              vehicles {
                name
                registration_number
              }
            }
            yt_rating(where: { to_user_id: { _eq: $driver_id } }) {
              id
              comment
              given_rate
              created_at
            }
            yt_rating_aggregate(
              where: { to_user_id: { _eq: $driver_id }, is_approved: { _eq: true } }
            ) {
              aggregate {
                count(columns: id)
              }
            }
            yt_ride_aggregate(
              where: { driver_user_id: { _eq: $driver_id }, status: { _eq: "COMPLETE" } }
            ) {
              aggregate {
                count(columns: id)
              }
            }
          }
        `,
        variables: {
          driver_id: id,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const DesubscribeFromRide = () => {
  if (rideSubscriber !== null) {
    rideSubscriber.unsubscribe();
  }
};

export const DesubscribefromRideLocation = () => {
  if (rideLocationSubscriber !== null) {
    rideLocationSubscriber.unsubscribe();
  }
};

export const CancelRide = (config) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation($cancellation_reason: String!, $ride_id: uuid!) {
            CancelRide(cancellation_reason: $cancellation_reason, ride_id: $ride_id) {
              cancellation_reason
              cancelled_at
              cancelled_by_user_id
              id
              status
              user_id
            }
          }
        `,
        variables: {
          cancellation_reason: config.cancellation_reason,
          ride_id: config.ride_id,
        },
      })
      .then((res) => {
        console.log('Ride IT', config.ride_id);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const UpdateRideRequestStatus = (ride_id) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation UpdateRideStatus($ride_id: uuid) {
            update_yt_ride_request(
              where: { ride_id: { _eq: $ride_id } }
              _set: { available: false }
            ) {
              affected_rows
              returning {
                driver_user_id
                id
                ride_id
              }
            }
          }
        `,
        variables: {
          ride_id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
