/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';

import { useHistory } from 'react-router-dom';

// importing UI components
import Slider from 'react-slick';

// importing helpers
import useStyles from '../css/PlansPageCSS';
import HeaderTitle from '../../../Components/headertitle/HeaderTitle';
import navigationHandler from '../../App/helpers/NavigationHandler';
import PlanCard from '../components/PlanCard';
import { getPlans } from '../../../Actions/Plans';
import Loader from '../../../Components/loader/Loader';
import '../css/plansPage.css';

export default function PlansPage() {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [plansList, setPlansList] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [loader, setLoader] = React.useState(true);
  const [settings] = React.useState({
    className: `center slideSingle ${classes.slideSingle}`,
    centerMode: true,
    infinite: false,
    dots: true,
    centerPadding: '65px',
    slidesToShow: 1,
    speed: 500,
    arrows: false,
    dotsClass: `slick-dots ${classes.dotsPlans}`,
  });
  const history = useHistory();

  React.useEffect(() => {
    getPlans(10, count)
      .then((res) => {
        setPlansList([...plansList, ...res.data.yt_plan]);
        setLoader(false);
      })
      .catch((err) => {
        setPlansList([]);
        setLoader(false);
      });
  }, []);

  const [switchState, setSwitchState] = React.useState({
    checkedSwitch: true,
  });

  const getNextPlans = () => {
    // if (switchState) {
    getPlans(10, count)
      .then((res) => {
        setPlansList([...plansList, ...res.data.yt_plan]);
      })
      .catch((err) => {
        setPlansList([]);
      });
    // } else {
    //   getPlansDaily(10, count)
    //     .then((res) => {
    //       setPlansList([...res.data.yt_plan]);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       setPlansList([]);
    //     });
    // }
  };

  React.useEffect(() => {
    getNextPlans();
  }, [count]);

  // const handleChange = (event) => {
  //   setCount(0);
  //   setLoader(true);
  //   setSwitchState({ [event.target.name]: event.target.checked });
  //   if (event.target.checked) {
  //     getPlans(10, count)
  //       .then((res) => {
  //         setPlansList([...res.data.yt_plan]);
  //         setLoader(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setPlansList([]);
  //         setLoader(false);
  //       });
  //   } else {
  //     getPlansDaily(10, count)
  //       .then((res) => {
  //         setPlansList([...res.data.yt_plan]);
  //         setLoader(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setPlansList([]);
  //         setLoader(false);
  //       });
  //   }
  // };

  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: 'black' }}>
      <HeaderTitle title="Yello Plans" backBtn={() => history.goBack()} />
      {/* <Grid item className={classes.cardContainer}> */}

      <div className={classes.cardBg}>
        {!loader ? (
          <>
            {/* <SwitchField
              switchLabel1="Daily Plan"
              switchLabel2="Extended Plan"
              onChange={handleChange}
              checked={switchState.checkedSwitch}
            /> */}
            <div className={classes.slider}>
              <Slider
                afterChange={() => {
                  const indexCount = +1;
                  if (indexCount == plansList.length) {
                    // console.log('inde ===x  ===', index);
                    setCount(count + 10);
                  }
                }}
                {...settings}
              >
                {plansList.length > 0 ? (
                  plansList.map((item) => {
                    const planList = [];
                    planList.push(`${item.validity_days * 24} Hours Validity`);
                    if (item.unlimited_trips) {
                      planList.push('Unlimited Rides');
                    } else {
                      planList.push(`${item.total_daily_trips} Daily Rides`);
                      planList.push(`${item.total_trips} Total Rides`);
                    }
                    if (item.only_airport_service) {
                      planList.push(`Only Airport Service`);
                    }
                    if (item.active) {
                      return (
                        <PlanCard
                          onClick={() => navigationHandler(history, '/payment-method', '', item)}
                          planType={item.title}
                          planPrice={item.price}
                          planInfoList={planList}
                        />
                      );
                    }
                  })
                ) : (
                  <h1>No Plans Found</h1>
                )}
              </Slider>
            </div>
          </>
        ) : (
          <Loader />
        )}
        {/* </Grid> */}
      </div>
    </div>
  );
}
