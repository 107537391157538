import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  notificationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '15vmax',
    backgroundColor: '#000000',
    borderRadius: '8px 8px 8px 8px',
    marginBottom: '2vmax',
  },
  notificationInfoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80%',
    margin: 'auto',
    width: '85%',
  },
  notificationDatetimeContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '66%',
    justifyContent: 'space-between',
    '& #notificationDateText': {
      fontFamily: 'Avenir-Black',
      fontSize: '1.7vmax',
      color: 'rgb(181,181,181)',
      fontWeight: '900',
    },
    '& #notificationTimeText': {
      fontFamily: 'Avenir-Book',
      fontSize: '1.7vmax',
      color: 'rgb(181,181,181)',
    },
  },
  notificationTextContainer: {
    overflow: 'scroll',
    '& #notificationHeader': {
      '& #notificationHeaderText': {
        fontFamily: 'Avenir-Black',
        fontSize: '2vmax',
        color: 'rgb(255,253,0)',
        fontWeight: '900',
      },
    },
    '& #notificationTextContainer': {
      '& #notificationText': {
        overflow: 'scroll',
        fontFamily: 'Avenir-Book',
        fontSize: '2vmax',
        color: 'rgb(255,255,255)',
      },
    },
  },
}));
