import { gql } from '@apollo/client';
import client from '../Containers/App/config/ApolloClient';

const getUrlFromId = (id) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query($uploadId: [uuid!]) {
            GenViewUrl(file_upload_id: $uploadId) {
              expires_at
              url
            }
          }
        `,
        variables: {
          uploadId: id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export default getUrlFromId;
