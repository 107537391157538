/* eslint-disable global-require */
import React from 'react';
// importing UI components

// importing helpers
import useStyles from './css/notificationCardCss';

export default function NotificationCard(props) {
  const classes = useStyles();

  return (
    <div
      style={{ opacity: props.readStatus === true ? 0.7 : 1 }}
      className={classes.notificationContainer}
    >
      <div className={classes.notificationInfoBox}>
        <div className={classes.notificationTextContainer}>
          <div id="notificationHeader">
            <span id="notificationHeaderText">{props.notificationHeader}</span>
          </div>
          <div id="notificationTextContainer">
            <span id="notificationText">{props.notificationText}</span>
          </div>
        </div>
        <div className={classes.notificationDatetimeContainer}>
          <div>
            <span id="notificationDateText">{props.notificationDate}</span>
          </div>
          <div>
            <span id="notificationTimeText">{props.notificationTime}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
