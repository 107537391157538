/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';

// importing UI helpers
import HeaderTitle from '../../../Components/headertitle/HeaderTitle';
import useStyles from '../css/MyRidesPageCSS';
import navigationHandler from '../../App/helpers/NavigationHandler';
import PrimaryButton from '../../../Components/buttons/PrimaryButton';

import SwipeList from '../helpers/SwipeList';
import getUrlFromId from '../../../Actions/Common';
import { getUserRidesList } from '../../../Actions/RideHistory';
import Loader from '../../../Components/loader/Loader';

export default function MyRidesPage() {
  const classes = useStyles();
  const history = useHistory();

  const [ridesList, setRidesList] = useState([]);
  const [ridesImageList, setRidesImageList] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const getUrl = async (mapId) => {
    const url = await getUrlFromId(mapId);
    const result = await url.data.GenViewUrl[0].url;
    return result;
  };

  useEffect(() => {
    getUserRidesList(5, 0)
      .then(async (res) => {
        const resultArray = res.data.yt_ride;
        const imagePromiseList = [];
        resultArray.map((item) => {
          if (item.route_map_file_id !== null) {
            return imagePromiseList.push(getUrl(item.route_map_file_id));
          }
          return imagePromiseList.push('');
        });
        setRidesImageList(await Promise.all(imagePromiseList));
        setRidesList([...resultArray]);
        setHasMore(false);
      })
      .catch((err) => {
        setHasMore(false);
      });
  }, []);

  const fetchMoreData = () => {
    getUserRidesList(5, ridesList.length)
      .then(async (res) => {
        const resultArray = res.data.yt_ride;
        const imagePromiseList = [];
        resultArray.map((item) => {
          if (item.route_map_file_id !== null) {
            return imagePromiseList.push(getUrl(item.route_map_file_id));
          }
          return imagePromiseList.push('');
        });
        setRidesImageList([...ridesImageList, ...(await Promise.all(imagePromiseList))]);
        setRidesList([...ridesList, ...resultArray]);
        if (resultArray < 1) {
          setHasMore(false);
        }
      })
      .catch((err) => {
        setHasMore(false);
      });
  };

  const convertToDate = (date) => {
    const localDate = moment.utc(date).local().format('Do MMMM[,] YYYY');
    return localDate;
  };

  return (
    <div style={{ width: '100%', minHeight: '100vh', backgroundColor: 'black' }}>
      <HeaderTitle title="My Rides" backBtn={() => history.goBack()} />
      <div className={classes.cardBg}>
        <div
          id="scrollableDiv"
          style={{
            marginTop: '2vmax',
            height: '74vmax',
            overflow: 'scroll',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
          }}
        >
          <InfiniteScroll
            dataLength={ridesList.length}
            next={fetchMoreData}
            hasMore
            loader={hasMore ? <Loader /> : null}
            scrollableTarget="scrollableDiv"
          >
            {ridesList && ridesList.length > 0 ? (
              ridesList.map((data, index) => {
                return (
                  <div key={data.id}>
                    <SwipeList
                      startAddress={data.start_address.line1 ? data.start_address.line1 : ''}
                      endAddress={data.end_address.line1 ? data.end_address.line1 : ''}
                      date={data.created_at ? convertToDate(data.created_at) : ''}
                      time={`
                        ${data.duration ? `${parseFloat(data.duration).toFixed(2)}m` : ''} | 
                        ${
                          data.distance ? `${parseFloat(data.distance * 0.621371).toFixed(2)}m` : ''
                        }`}
                      mapUrl={ridesImageList[index] || null}
                      onClickCard={() => {
                        navigationHandler(history, '/my-rides/ride-details', null, data.id);
                      }}
                    />
                  </div>
                );
              })
            ) : !hasMore && ridesList.length < 1 ? (
              <div className={classes.errorContainer}>
                <p>It seems you have not done any rides.</p>
                <Link to="/home" style={{ textDecoration: 'none' }}>
                  <PrimaryButton
                    type="primary"
                    variant="fullWidth"
                    className={classes.saveButton}
                    Title="Go to ride"
                  />
                </Link>
              </div>
            ) : null}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
