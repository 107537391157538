/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(var(--vh, 1vh) * 100)',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(0,0,0)',
  },

  cardBg: {
    backgroundImage: 'linear-gradient(rgb(49, 49, 49), rgb(31, 31, 30))',
    paddingTop: '2vmax',
    paddingBottom: '2vmax',
    marginTop: '20px',
    height: '80%',
    display: 'flex',
    overflow: 'scroll',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '16px 16px 0 0',
    overflowX: 'hidden',
  },
  innerContainer: {
    width: '85%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // height: '95%',
  },
  smallCardContainer: {
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    flex: 0.6,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
  planHeaderText: {
    fontSize: '2.5vmax',
    fontWeight: 'bold',
    color: '#fff',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
  },
  smallCardDivider: {
    height: '1px',
    backgroundColor: 'rgb(112,112,112)',
    width: '70%',
    margin: '1vmax 0',
  },
  planPricerText: {
    fontSize: '4vmax',
    fontWeight: 'bolder',
    color: '#fff',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
  },
  planPriceCard: {
    // height: '80vw',
    width: '80vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px',
    border: '5px solid rgb(112,112,112)',
    background: 'transparent',
    padding: '40px 20px',
  },
  editIcon: {
    position: 'absolute',
    transform: 'translate(-32px,67px)',
  },
  cardsContainer: {
    display: 'flex',
    flex: 0.7,
    flexDirection: 'column',
    marginTop: 10,
    overflow: 'scroll',
  },
  cardsContainer2: {
    display: 'flex',
    flex: 0.2,
    justifyContent: 'center',
    flexDirection: 'column',
  },
  promoContainer: {
    display: 'flex',
    flex: 0.2,
    justifyContent: 'center',
    // flexDirection: 'column',
    // marginBottom: '8px',
    // marginTop: '36px',
    textAlign: 'center',
    alignItems: 'center',
  },
  promoContainerText: {
    color: 'white',
    fontSize: '2vmax',
    alignSelf: 'center',
    margin: 0,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // marginRight: '-50vmin',
    // marginTop: '-10vmin',
    margin: 4,
    marginTop: 4,
  },
  inputContainer: {
    width: '100%',
    '& .MuiInput-underline:before': {
      borderBottomColor: 'rgb(181,179,179)',
    },
    '& .MuiInputBase-input': {
      fontSize: '1.9vmax',
      fontWeight: '600',
      color: 'rgb(181,181,181)',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#6a6d77',
    },
  },
  customLabel: {
    color: '#FFFD00',
    fontSize: '1.7vmax',
    fontWeight: '600',
    // top: '-3px',
    fontFamily: 'Avenir Next',
    position: 'static',
    transform: 'inherit',
  },
  inputContainerBox: {
    display: 'flex',
    flex: 0.1,
    marginBottom: 'calc(var(--vh, 1vh) * 2)',
    width: '100%',
    // marginTop: '35vmax',
  },
  errorMessage: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2vmax',
    color: 'red',
    marginTop: '1.5vmax',
    marginBottom: '1.5vmax',
  },
  breakdownPricerText: {
    fontSize: '2vmax',
    fontWeight: 'bolder',
    color: '#fff',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
    '& :first-child': {
      textAlign: 'left',
      width: '80%',
    },
    '& :last-child': {
      textAlign: 'right',
      width: '20%',
    },
  },
  loadingLabel: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2vmax',
    fontWeight: 'bolder',
    color: 'white',
    width: '100%',
  },
  errorContainer: {
    margin: '4vh',
    textAlign: 'center',
    '& p': {
      fontSize: 20,
      marginBottom: 50,
    },
  },
}));
