/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

// importing UI helpers
import useStyles from '../../css/NotificationSettingsCss';
import HeaderTitle from '../../../../Components/headertitle/HeaderTitle';
import NotificationSettingsCard from '../../components/NotificationSettingCard';
import {
  getNotificationSettings,
  editNotificationSettings,
  addNotificationSettings,
} from '../../../../Actions/Notification';
import Loader from '../../../../Components/loader/Loader';

export default function NotificationSettingsPage() {
  const [notificationSetting, setNotificationSetting] = useState(null);
  const [hasData, setHasData] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const classes = useStyles();
  const history = useHistory();

  const params = (notificationSetting && notificationSetting.params) || {};

  useEffect(() => {
    getNotificationSettings()
      .then(async (res) => {
        setNotificationSetting(res.data.yt_user_setting ? res.data.yt_user_setting[0] : {});
        setHasData(res.data.yt_user_setting.length !== 0);
        setIsLoader(false);
      })
      .catch((err) => {
        setIsLoader(false);
      });
  }, []);

  const handleChange = (key) => {
    if (hasData) {
      const param = { ...notificationSetting.params };
      param[key] = !param[key];
      editNotificationSettings(notificationSetting.id, param)
        .then(async (resR) => {
          setNotificationSetting(
            resR.data.update_yt_user_setting_by_pk ? resR.data.update_yt_user_setting_by_pk : {},
          );
        })
        .catch((err) => console.log('error rides list', err));
    } else {
      const param = {
        email: true,
        promotional: true,
        push: true,
        sms: true,
      };
      param[key] = !param[key];
      addNotificationSettings(param)
        .then(async (resR) => {
          setNotificationSetting(resR.data.insert_yt_user_setting_one);
        })
        .catch((err) => console.log('error rides list', err));
    }
  };

  return (
    <div className={classes.root}>
      <HeaderTitle title="Notification Settings" backBtn={() => history.goBack()} />
      <div className={classes.cardBg}>
        {isLoader ? (
          <Loader />
        ) : (
          <div className={classes.innerContainer}>
            <NotificationSettingsCard
              notificationType="Push Notification"
              notificationInfoText="Once disabled you wont be able receive any updates or notification from Yello cab on your device."
              value={params.push !== undefined ? params.push : true}
              handleChange={() => handleChange('push')}
            />
            <NotificationSettingsCard
              notificationType="SMS Notification"
              notificationInfoText="Once disabled you wont be able receive any updates or notification from Yello cab on your registered mobile number."
              value={params.sms !== undefined ? params.sms : true}
              handleChange={() => handleChange('sms')}
            />
            <NotificationSettingsCard
              notificationType="Email Notification"
              notificationInfoText="Once disabled you wont be able receive any updates or notification from Yello cab on your registered email address."
              value={params.email !== undefined ? params.email : true}
              handleChange={() => handleChange('email')}
            />
            <NotificationSettingsCard
              notificationType="Promotional Notification"
              notificationInfoText="Once disabled you wont be able receive any promotional messages  from Yello cab on your registered mobile number or email address."
              value={params.promotional !== undefined ? params.promotional : true}
              handleChange={() => handleChange('promotional')}
            />
          </div>
        )}
      </div>
    </div>
  );
}
