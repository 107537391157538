/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

// importing UI helpers
import HeaderTitle from '../../../Components/headertitle/HeaderTitle';
import useStyles from '../css/NotificationCSS';
import NotificationCard from '../components/notificationItemCard';
import { getNotification, readNotifications } from '../../../Actions';

export default function NotificationsPage() {
  const classes = useStyles();
  const history = useHistory();
  const [notificationList, setNotificationList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [, updateState] = React.useState();

  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    getNotification()
      .then(({ data }) => {
        setNotificationList(data.yt_notification);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', flex: 0.2 }}>
        <HeaderTitle title="Notification" backBtn={() => history.goBack()} />
      </div>
      <div className={classes.cardBg}>
        <div className={classes.notifButton}>
          <button
            onClick={() => {
              setLoader(true);
              readNotifications(notificationList)
                .then(() => {
                  getNotification()
                    .then(({ data }) => {
                      setNotificationList(data.yt_notification);
                      forceUpdate();
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  setLoader(false);
                })
                .catch((err) => {
                  setLoader(false);
                  setError(err.toString());
                });
            }}
            className={classes.notifButton1}
            type="button"
          >
            {loader ? 'Loading' : 'Mark as read'}
          </button>
          {error !== null ? error : <div />}
        </div>
        {notificationList.map(({ id, content, created_at, read_statuses }) => {
          return (
            <NotificationCard
              key={id}
              notificationHeader={content?.title}
              notificationText={content?.message}
              notificationDate={new Date(created_at).toLocaleDateString()}
              notificationTime={new Date(created_at).toLocaleTimeString()}
              readStatus={read_statuses.length > 0}
            />
          );
        })}

        {/* <h1>No Notification</h1> */}
      </div>
    </div>
  );
}
