import { gql } from '@apollo/client';

import client from '../Containers/App/config/ApolloClient';

export const getNotificationSettings = () => {
  const userId = localStorage.getItem('userIdHasura');
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query MyQuery($user_id: uuid!, $groupFilter: jsonb) {
            yt_user_setting(where: { user_id: { _eq: $user_id } }) {
              id
              params
              type
              user_id
            }
          }
        `,
        variables: {
          user_id: userId,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addNotificationSettings = (params) => {
  const userId = localStorage.getItem('userIdHasura');
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation($params: jsonb!, $user_id: uuid) {
            insert_yt_user_setting_one(
              object: { user_id: $user_id, params: $params, type: "notification" }
            ) {
              id
              params
              type
              user_id
            }
          }
        `,
        variables: {
          user_id: userId,
          params,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const editNotificationSettings = (id, params) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation($id: uuid!, $params: jsonb!) {
            update_yt_user_setting_by_pk(pk_columns: { id: $id }, _set: { params: $params }) {
              id
              params
              type
              user_id
            }
          }
        `,
        variables: {
          id,
          params,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getNotification = () => {
  const userId = localStorage.getItem('userIdHasura');
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query MyQuery($user_id: uuid!, $groupFilter: jsonb) {
            yt_notification(
              where: {
                _or: [{ user_id: { _eq: $user_id } }, { user_groups: { _contains: $groupFilter } }]
              }
            ) {
              id
              content
              priority
              target
              user_groups
              user_id
              sender {
                email
                id
                profile_photo {
                  file_object
                  storage_provider
                  file_type_id
                }
              }
              read_statuses(where: { user_id: { _eq: $user_id } }) {
                id
                read_at
              }
            }
          }
        `,
        variables: {
          user_id: userId,
          groupFilter: { rider: true },
        },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const readNotifications = (notifs) => {
  const userId = localStorage.getItem('userIdHasura');
  const objs = [];
  const date = new Date();
  const dt = `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`;
  notifs.map((notif) => {
    return objs.push({
      notification_id: notif.id,
      user_id: userId,
      read_at: dt,
    });
  });
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation($objs: [yt_notification_read_status_insert_input!]!) {
            insert_yt_notification_read_status(objects: $objs) {
              returning {
                notification_id
                id
                user_id
              }
            }
          }
        `,
        variables: {
          objs,
        },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
