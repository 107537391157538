/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import React from 'react';

import { useHistory } from 'react-router-dom';

// importing UI helpers
import HeaderTitle from '../../../../../Components/headertitle/HeaderTitle';
import useStyles from '../../../css/UserGuideCss';

import CardRightArrow from '../../../../../Components/cards/CardRightArrow';
import navigationHandler from '../../../../App/helpers/NavigationHandler';

export default function LegalPage() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <HeaderTitle title="Legal" backBtn={() => history.goBack()} />
      <div className={classes.cardBg}>
        <div className={classes.innerContainer}>
          <div className={classes.cardsContainer}>
            <CardRightArrow
              title="About Us"
              onClick={() => {
                navigationHandler(history, '/account-settings/legal/about-us', null, {
                  title: 'About Us',
                  dataOf: 'aboutus',
                });
              }}
            />
            <CardRightArrow
              title="Terms & Conditions"
              onClick={() => {
                navigationHandler(history, '/account-settings/legal/terms-and-conditions', null, {
                  title: 'Terms & Conditions',
                  dataOf: 'tnc',
                });
              }}
            />
            <CardRightArrow
              title="Privacy Policy"
              onClick={() => {
                navigationHandler(history, '/account-settings/legal/privacy-policy', null, {
                  title: 'Privacy Policy',
                  dataOf: 'privacy',
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
