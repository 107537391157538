/* eslint-disable eqeqeq */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ children, ...rest }) => {
  const getToken = () => {
    return !!localStorage.getItem('token');
  };

  return (
    <Route
      {...rest}
      render={({ location }) =>
        getToken() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export const PublicRoute = ({ children, isGlobalAccess, ...rest }) => {
  const getToken = () => {
    return !!localStorage.getItem('token');
  };

  return (
    <Route
      {...rest}
      render={({ location }) =>
        getToken() == false || isGlobalAccess ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/home',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
