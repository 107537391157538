import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100vmax',
    justifyContent: 'space-between',
    backgroundColor: 'black',
  },

  cardBg: {
    backgroundImage: 'linear-gradient(rgb(49, 49, 49), rgb(31, 31, 30))',
    paddingTop: '1vmax',
    paddingBottom: '1vmax',
    marginTop: '1.5vmax',
    height: '80%',
    display: 'flex',
    overflow: 'scroll',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px 16px 0 0',
  },
  innerContainer: {
    width: '85%',
    height: '90%',
  },
}));
