import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // padding: '20px',
    // flex: 1,
    width: '100%',
    height: '50vmax',
    overflow: 'scroll',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(0,0,0)',
  },
  invoiceContainer: {
    height: '85%',
    width: '80%',
    // margin: '20px',
    '& #transactionContainer': {
      marginBottom: 0,
    },
  },
  invoiceHeaderText: {
    fontSize: '2.2vmax',
    fontWeight: '900',
    color: 'rgb(255,253,0)',
    fontFamily: 'Avenir-Black',
  },
  invoiceHeaderContainer: {
    marginBottom: theme.spacing(2),
  },
  invoiceListItemContainer: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  invoiceListItemText: {
    fontSize: '1.9vmax',
    color: 'rgb(255,255,255)',
    fontFamily: 'Avenir-Book',
  },
  invoiceListItemTextBold: {
    fontSize: '1.9vmax',
    fontWeight: '900',
    color: 'rgb(255,255,255)',
    fontFamily: 'Avenir-Book',
  },
  divider: {
    height: '1.5px',
    backgroundColor: 'rgb(112,112,112)',
    width: '100%',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));
