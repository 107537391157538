/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import moment from 'moment';

// importing UI components
import Rating from '@material-ui/lab/Rating';
// import PrimaryButton from '../../../Components/buttons/PrimaryButton';
import HeaderTitle from '../../../Components/headertitle/HeaderTitle';
import MapBig from '../../../assets/svg/mapBig.svg';
import RoutePath from '../../../assets/svg/ridePath.svg';

// importing helpers
import useStyles from '../css/RideDetailsPageCSS';
import ActivePassCard from '../../../Components/cards/ActivePassCard';
import getUrlFromId from '../../../Actions/Common';
import { getUserRidesById } from '../../../Actions/RideHistory';
import ExpiredPassCard from '../../../Components/cards/ExpiredPassCard';
// import navigationHandler from '../../App/helpers/NavigationHandler';

export default function RideDetailsPage() {
  const classes = useStyles();
  const history = useHistory();

  const [rideData, setRideData] = useState(null);
  const [profileUrl, setprofileUrl] = useState(null);
  const [mapUrl, setmapUrl] = useState(null);
  // const [barCode, setBarCode] = useState(null);

  const getUrl = async (mapId) => {
    const url = await getUrlFromId(mapId);
    const result = await url.data.GenViewUrl[0].url;
    return result;
  };

  useEffect(() => {
    getUserRidesById(history.location.state)
      .then(async (res) => {
        const result = res.data.yt_ride[0];
        setRideData(result);
        if (result.route_map_file_id) {
          const image = await Promise.resolve(getUrl(result.route_map_file_id));
          setmapUrl(image);
        } else {
          setmapUrl('');
        }
        if (result.driver) {
          if (result.driver.profile_photo_file_id) {
            if (result.driver.profile_photo_file_id) {
              getUrlFromId(result.driver.profile_photo_file_id)
                .then((resProfilePhoto) => {
                  setprofileUrl(resProfilePhoto.data.GenViewUrl[0].url);
                })
                .catch((err) => {
                  console.log('err', err);
                });
            } else {
              setprofileUrl('');
            }
          }
        }
        if (result.boarding_pass) {
          if (result.boarding_pass.qr_code) {
            // setBarCode(`https://chart.googleapis.com/chart?chs=250x250&
            // cht=qr&chl={"confirmation_code":"${result.boarding_pass.qr_code}"`);
          }
        }
      })
      .catch((err) => console.log('error rides list', err));
  }, []);

  const convertToDateType1 = (date) => {
    const localDate = moment.utc(date).local().format('Do MMMM[,] YYYY');
    return localDate;
  };

  // const convertToDateType2 = (date) => {
  //   const localDate = moment.utc(date).local().format('MM/DD/YYYY');
  //   return localDate;
  // };

  return (
    <div className={classes.root}>
      <HeaderTitle title="Ride Details" backBtn={() => history.goBack()} />
      <div
        className={classes.cardBg}
        style={
          rideData && rideData.boarding_pass && rideData.boarding_pass.status === 'ACTIVE'
            ? null
            : { height: '84.5vh' }
        }
      >
        {rideData ? (
          <>
            <div className={classes.imageMapContainer}>
              <img alt="nothing" src={mapUrl || MapBig} className={classes.mapBig} />
            </div>
            <div className={classes.passengerContainer}>
              <div id="innerBox">
                <div id="innerBox-1">
                  <div id="innerBox-1-date-distance">
                    <span id="innerBox-1-date-distance-date">
                      {rideData.created_at ? convertToDateType1(rideData.created_at) : ''}
                    </span>
                    <span id="innerBox-1-date-distance-dist">
                      {`${
                        rideData.duration ? `${parseFloat(rideData.duration).toFixed(2)}m` : ''
                      } | ${
                        rideData.distance
                          ? `${parseFloat(rideData.distance * 0.621371).toFixed(2)}m`
                          : ''
                      }`}
                    </span>
                  </div>
                  <div id="innerBox-1-dot-route">
                    <div className={classes.mapSmallContainer}>
                      <img alt="nothing" src={RoutePath} className={classes.mapSmall} />
                    </div>
                    <div className="innerBox-1-dot-route-timeline">
                      <div className="innerBox-1-dot-route-start">
                        <span>
                          {rideData.start_address.line1 ? rideData.start_address.line1 : ''}
                        </span>
                      </div>

                      <div className="innerBox-1-dot-route-end">
                        <span>{rideData.end_address.line1 ? rideData.end_address.line1 : ''}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="innerBox-2">
                  <div id="innerBox-2-taxi-no">
                    <span id="innerBox-2-taxi-no-text-1">Taxi No:</span>
                    <span id="innerBox-2-taxi-no-text-2">
                      {rideData.vehicle ? rideData.vehicle.registration_number : ''}
                    </span>
                  </div>
                  <div id="innerBox-2-rating-profile">
                    <div id="innerBox-2-rating-profile-image-container">
                      <img
                        alt="no profile"
                        src={profileUrl || require('../../../assets/img/Avatar1.png')}
                      />
                    </div>
                    <span id="innerBox-2-rating-profile-text">
                      {`You rated ${rideData.driver ? rideData.driver.full_name : ''}:`}
                    </span>
                    <Rating
                      id="innerBox-2-rating-profile-rating"
                      name="read-only"
                      emptyIcon={<StarBorderIcon style={{ color: 'grey' }} />}
                      value={
                        rideData.driver
                          ? rideData.driver.average_rate
                            ? rideData.driver.average_rate
                            : 0
                          : 0
                      }
                      precision={0.5}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={classes.buttonContainer}>
              <PrimaryButton
                type="primary"
                variant="fullWidth"
                className={classes.rideButton}
                Title="Ride Again"
                onClick={() =>
                  navigationHandler(history, '/home', null, {
                    start: rideData.start_location,
                    startAddress: rideData.start_address.line1 ? rideData.start_address.line1 : '',
                    end: rideData.end_location,
                    endAddress: rideData.end_address.line1 ? rideData.end_address.line1 : '',
                    isRideAgain: true,
                  })
                }
              />
            </div> */}
            {rideData.boarding_pass && rideData.boarding_pass.status === 'ACTIVE' ? (
              <ActivePassCard passDetails={rideData.boarding_pass} />
            ) : (
              <ExpiredPassCard passDetails={rideData.boarding_pass} />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}
