import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
  },
  listItem: {
    // height: '15vmax',
    // width: '100%',
  },

  cardBg: {
    backgroundColor: '#313131',
    marginTop: '20px',
    paddingTop: '3vmax',
    minHeight: '80vmax',
    overflow: 'auto',
    borderRadius: '16px 16px 0 0',
    paddingBottom: '2vmax',
  },

  singleRide: {
    // margin: '10px',
    marginBottom: '2vmax',
    background: '#000',
    borderRadius: 10,
    margin: '0px 16px',
    // overflow: 'hidden',
  },
  singleRideInner: {
    display: 'flex',
    alignItems: 'center',

    // flexFlow: 'row wrap',
  },
  options: {
    marginLeft: '6vmin',
    marginRight: '6vmin',
    display: 'flex',
    height: '13vmax',
    flexDirection: 'column',
    position: 'absolute',
    right: '3vmin',
    justifyContent: 'center',
  },
  cardContainer: {
    minHeight: '13vmax',
    height: '100%',
    display: 'flex',
    // marginBottom: 'vmax',
    width: '100%',
    borderRadius: 10,
    backgroundColor: 'rgb(0, 0, 0)',
    flexDirection: 'row',
    flexGrow: 1,

    overflow: 'hidden',
  },
  mapImageContainer: {
    width: '30%',
    marginLeft: 10,
  },
  mapSmall: {
    width: '100%',
    height: '100%',
  },
  mapImage: {
    width: '100%',
    height: '100%',
  },
  rideInfoOuterContainer: {
    width: '70%',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 0px',
  },
  rideInfoInnerContainer: {
    height: '100%',
    width: '85%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    display: 'flex',
  },
  startAddressContainer: {
    flexDirection: 'row',
    display: 'flex',
  },
  endAddressContainer: {
    flexDirection: 'row',
    display: 'flex',
  },
  label: {
    color: 'rgb(255,253,0)',
    fontSize: '1.8vmax',
    fontFamily: 'Avenir-Black',
    fontWeight: 'bold',
  },
  address: {
    color: 'rgb(255,255,255)',
    fontSize: '1.8vmax',
    fontFamily: 'Avenir-Roman',
    fontWeight: '400',
    marginLeft: '1vmin',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rideAddressContainer: {
    flexDirection: 'column',
    display: 'flex',
  },
  colorDot: {
    marginTop: '0.7vmax',
    marginRight: '0.5vmax',
    // padding: '0.7vh 0.5vh',
    height: '1vmax',
    width: '1vmax',
  },
  rideDateTimeContainer: {
    flexDirection: 'row',
    display: 'flex',
    width: '90%',
    justifyContent: 'space-between',
  },
  rideDateContainer: {
    color: 'rgb(181,181,181)',
    fontSize: '1.6vmax',
    fontFamily: 'AvenirNext-Bold',
    fontWeight: 'bold',
  },
  rideTimeContainer: {
    color: 'rgb(181,181,181)',
    fontSize: '1.6vmax',
    fontFamily: 'AvenirNext-Regular',
    fontWeight: '400',
    position: 'absolute',
    right: 11,
  },
  rightArrowContainer: {
    width: '100%',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'flex-end',
  },
  rightArrowIcon: {
    marginTop: '1vmax',
    marginRight: '1vmax',
  },
  deleteIcon: {
    height: '4vmax',
    width: '4vmax',
  },
  errorContainer: {
    margin: '4vh',
    textAlign: 'center',
    '& p': {
      fontSize: 20,
      marginBottom: 50,
    },
  },
}));
