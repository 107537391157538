import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: 'calc(var(--vh, 1vh) * 100)',

    justifyContent: 'space-between',
    backgroundColor: 'black',
    overflowX: 'hidden',
  },

  cardBg: {
    backgroundImage: 'linear-gradient(rgb(49, 49, 49), rgb(31, 31, 30))',
    paddingTop: '20px',
    paddingBottom: '20px',
    marginTop: '20px',
    height: 'calc(var(--vh, 1vh) * 80)',
    display: 'flex',
    overflowX: 'hidden',
    overflowY: 'scroll',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px 16px 0 0',
  },
  innerContainer: {
    minWidth: '80%',
    height: '95%',
  },
  imageBox: {
    height: '11vmax',
    width: '11vmax',
    display: 'flex',
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '6vmax',
  },
  cameraIcon: {
    position: 'absolute',
    height: '3.2vmax',
    width: '3.2vmax',
    transform: 'translate(-4vmax,8vmax)',
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));
