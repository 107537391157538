/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

// importing UI components
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import RoutePath from '../../../assets/svg/ridePath.svg';

// importing helpers
import useStyles from '../css/TripDetailRatingCardCss';

export default function TripDetailRatingCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const svgPath = (iconName) => `${iconName}#svgView(preserveAspectRatio(none))`;
  const convertToDateType1 = (date) => {
    const localDate = moment.utc(date).local().format('Do MMMM[,] YYYY');
    return localDate;
  };
  return (
    <div className={classes.passengerContainer}>
      <div id="innerBox">
        <div id="innerBox-1">
          <div id="innerBox-1-date-distance">
            <span id="innerBox-1-date-distance-date">
              {props.rideData.created_at ? convertToDateType1(props.rideData.created_at) : ''}
            </span>
            <span id="innerBox-1-date-distance-dist">
              {`${props.rideData.distance ? parseFloat(props.rideData.distance).toFixed(2) : ''}
                       | ${props.rideData.duration ? props.rideData.duration : 0}m`}
            </span>
          </div>
          <div id="innerBox-1-dot-route">
            <div className={classes.mapSmallContainer}>
              <img
                alt="nothing"
                src={props.rideData.mapURL || RoutePath}
                className={classes.mapSmall}
              />
            </div>
            <div className="innerBox-1-dot-route-timeline">
              <div className="innerBox-1-dot-route-start">
                <span>
                  {props.rideData.start_address.line1 ? props.rideData.start_address.line1 : ''}
                </span>
              </div>

              <div className="innerBox-1-dot-route-end">
                <span>
                  {props.rideData.end_address.line1 ? props.rideData.end_address.line1 : ''}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="innerBox-2">
          <div id="innerBox-2-taxi-no">
            <span id="innerBox-2-taxi-no-text-1">Taxi No:</span>
            <span id="innerBox-2-taxi-no-text-2">
              {props.rideData.vehicle
                ? props.rideData.vehicle.registration_number
                : 'Not Available'}
            </span>
          </div>
          <div id="innerBox-2-rating-profile">
            <div id="innerBox-2-rating-profile-image-container">
              <img alt="no profile" src={require('../../../assets/img/Avatar1.png')} />
            </div>
            <span id="innerBox-2-rating-profile-text">
              {`You rated ${props.rideData.driver ? props.rideData.driver.full_name : ''}:`}
            </span>
            <Rating
              id="innerBox-2-rating-profile-rating"
              name="read-only"
              emptyIcon={<StarBorderIcon style={{ color: 'grey' }} />}
              value={
                props.rideData.driver
                  ? props.rideData.driver.average_rate
                    ? props.rideData.driver.average_rate
                    : 0
                  : 0
              }
              precision={0.5}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
}
