/* eslint-disable global-require */
import React from 'react';

// importing UI components
import PrimaryButton from '../buttons/PrimaryButton';

// importing helpers
import useStyles from './CSS/ButtonedTabCSS';

export default function ButtonedTab(props) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState('trip');
  return (
    <div className={classes.ButtonedTabContainer}>
      <div className={classes.buttonsContainer}>
        <PrimaryButton
          type="primary"
          className={classes.button1}
          Title={props.title1}
          onClick={() => {
            setActiveTab('trip');
            props.onClickButton1();
          }}
          style={activeTab === 'trip' ? { backgroundColor: '#7626BC' } : null}
        />
        <div className={classes.buttonsDivider} />
        <PrimaryButton
          type="primary"
          className={classes.button2}
          Title={props.title2}
          onClick={() => {
            setActiveTab('invoice');
            props.onClickButton2();
          }}
          style={activeTab === 'invoice' ? { backgroundColor: '#7626BC' } : null}
        />
      </div>
      <div className={classes.children}>{props.children}</div>
    </div>
  );
}
