import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(var(--vh, 1vh) * 100) !important',
    justifyContent: 'space-between',
    backgroundColor: 'black',
  },
  cardBg: {
    backgroundColor: '#313131',
    marginTop: '20px',
    paddingBottom: '20px',
    paddingTop: 20,
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '16px 16px 0 0',
  },
  listContainer: {
    width: '100%',
    height: '95%',
    display: 'flex',
    overflow: 'scroll',
    flexDirection: 'column',
  },
  addButton: {
    marginTop: '1vmax',
    width: '75%',
  },
  errorContainer: {
    margin: '4vh',
    textAlign: 'center',
    '& p': {
      fontSize: 20,
      marginBottom: 50,
    },
  },
}));
