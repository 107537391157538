import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100vmax',
    justifyContent: 'space-between',
    backgroundColor: 'black',
  },

  cardBg: {
    backgroundColor: '#313131',
    marginTop: '1.5vmax',
    paddingBottom: '1.5vmax',
    paddingTop: '3vmax',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px 16px 0 0',
  },
  placeCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '95%',
    borderRadius: '10px',
    width: '85%',
    backgroundColor: 'rgb(0,0,0)',
  },
  placeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '90%',
    width: '85%',
    fontSize: '1.8vmax',
    overflow: 'scroll',
  },
  legalDataContainer: {
    fontFamily: 'Avenir-Book',
    fontSize: '1.8vmax',
    color: 'white',
  },
}));
