/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-fragments */
/* eslint-disable max-len */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  headertitle: {
    padding: '5vmax 0 0 6vmin',
  },
  backBtn: {
    padding: 0,
    border: 'none',
    background: 'transparent',
    '&:focus': {
      outline: 'none',
    },
  },
  screentitle: {
    fontFamily: 'Avenir-Black',
    fontSize: '3vmax',
    color: '#FFFD00',
    marginBottom: 8,
    marginTop: 8,
    fontWeight: '900',
  },
}));

// eslint-disable-next-line no-unused-vars
const HeaderTitle = (props) => {
  const classes = useStyles();
  // console.log(props.type);
  return (
    <div className={classes.headertitle}>
      <button type="button" className={classes.backBtn} onClick={props.backBtn}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8.752"
          height="15.098"
          viewBox="0 0 8.752 15.098"
        >
          <g id="icons_back_arrow" data-name="icons/back arrow" transform="translate(1 1.414)">
            <path
              id="Line_3"
              data-name="Line 3"
              d="M-.338,0l-6,6L0,12.27"
              transform="translate(6.338 0)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
        </svg>
      </button>
      <h2 className={classes.screentitle}>{props.title}</h2>
    </div>
  );
};

export default HeaderTitle;
