/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Markup } from 'interweave';

// importing UI helpers
import HeaderTitle from '../../../../../Components/headertitle/HeaderTitle';
import useStyles from '../../../css/LegalSubPageCss';
import getCMS from '../../../../../Actions/CMS';
import Loader from '../../../../../Components/loader/Loader';

export default function LegalSubPage() {
  const classes = useStyles();
  const history = useHistory();

  const [error, setError] = useState(null);
  const [CMS, setCMS] = useState('');
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    let slag = '';
    if (history.location.state.dataOf) {
      if (history.location.state.dataOf === 'aboutus') {
        slag = 'about_us';
      } else if (history.location.state.dataOf === 'tnc') {
        slag = 'terms_and_conditions';
      } else if (history.location.state.dataOf === 'privacy') {
        slag = 'privacy_policy';
      }
    }
    getCMS(slag)
      .then((res) => {
        setLoader(false);
        setCMS(res.data.yt_content[0].content.data);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {
        setLoader(false);
        setError('Something went wrong');
      });
  }, []);

  return (
    <div className={classes.root}>
      <HeaderTitle title={history.location.state.title} backBtn={() => history.goBack()} />
      <div className={classes.cardBg}>
        <div className={classes.placeCardContainer}>
          <div className={classes.placeContainer}>
            <div className={classes.legalDataContainer}>
              {loader && <Loader />}
              {error !== null ? (
                <div>Something went wrong</div>
              ) : (
                <span>
                  {/* {history.location.state.dataOf === 'privacy'
                      ? legalData.privacy
                      : legalData.aboutUs} */}
                  <Markup content={CMS} />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
