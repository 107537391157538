import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Grow from '@material-ui/core/Grow';
import useStyles from './css/ErrorModalCss';
import Danger from '../../assets/svg/danger.svg';
import PrimaryButton from '../buttons/PrimaryButton';

export default function ErrorModal(props) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openModal}
        disableAutoFocus
        // onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{ outline: 'none !important' }}
      >
        <Grow in={props.openModal}>
          <div className={classes.card}>
            <img src={Danger} alt="nothing" className={classes.verifiedIcon} />
            <div className={classes.headerText}>
              <span>{props.headerText}</span>
            </div>
            {props.paragraphText && (
              <div className={classes.paragraphText}>
                <span>{props.paragraphText}</span>
              </div>
            )}
            <PrimaryButton
              type="primary"
              variant="fullWidth"
              onClick={props.handleClose}
              className={classes.planButton}
              Title={props.title}
            />
          </div>
        </Grow>
      </Modal>
    </div>
  );
}
