/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable global-require */
import React from 'react';
// importing UI components
import YellowDot from '../../../assets/svg/yellowDot.svg';
import GreenDot from '../../../assets/svg/greenDot.svg';
import MapSmall from '../../../assets/svg/mapSmall.svg';

// importing helpers
import useStyles from '../css/RideDetailsCardCss';

export default function RideDetailsCard(props) {
  const classes = useStyles();
  const svgPath = (iconName) => `${iconName}#svgView(preserveAspectRatio(none))`;

  return (
    <div className={classes.cardContainer} onClick={props.onClick}>
      <div className={classes.mapImageContainer}>
        <img src={props.mapURL || MapSmall} alt="map" className={classes.mapSmall} />
      </div>
      <div className={classes.rideInfoOuterContainer}>
        <div className={classes.rideInfoInnerContainer}>
          <div className={classes.rideAddressContainer}>
            <div className={classes.startAddressContainer}>
              <img src={GreenDot} alt="start" className={classes.colorDot} />
              <div className={classes.label}>
                <span>Start:</span>
              </div>
              <div className={classes.address}>
                <span>{props.startAddress}</span>
              </div>
            </div>
            <div className={classes.endAddressContainer}>
              <img src={YellowDot} alt="end" className={classes.colorDot} />
              <div className={classes.label}>
                <span>End:</span>
              </div>
              <div className={classes.address}>
                <span>{props.endAddress}</span>
              </div>
            </div>
          </div>
          <div className={classes.rideDateTimeContainer}>
            <div className={classes.rideDateContainer}>
              <span>{props.date}</span>
            </div>
            <div className={classes.rideTimeContainer}>
              <span>{props.time}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
