import React from 'react';
import { useHistory } from 'react-router-dom';

import useStyles from './css/NoTripsCardCss';
import PrimaryButton from '../../../Components/buttons/PrimaryButton';
import navigationHandler from '../../App/helpers/NavigationHandler';

export default function NoTripsCard(props) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <div className={classes.invoiceContainer}>
        <span className={classes.noTripText}>Please start a trip to use this pass.</span>
        {props.from !== 'expired' ? (
          <PrimaryButton
            type="primary"
            variant="fullWidth"
            className={classes.rideButton}
            Title="Ride"
            onClick={() => navigationHandler(history, '/home')}
          />
        ) : null}
      </div>
    </div>
  );
}
