/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from '../css/PaymentMethodCss';
import HeaderTitle from '../../../Components/headertitle/HeaderTitle';
import Stripe from '../helpers/stripe';

export default function CardPayment() {
  const classes = useStyles();
  const history = useHistory();
  React.useEffect(() => {
    console.log('CARDPAYMENT', history.location.state);
    // const id = localStorage.getItem('userIdHasura');
    // console.log("history", history.location.state);
    // getPromoCodes(10, 0)
    //     .then((res) => {
    //         console.log("PaymentMethodPage -> res", res.data.yt_coupon);
    //         setPromoCodeList(res.data.yt_coupon)
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
  }, []);

  return (
    <div className={classes.root}>
      <HeaderTitle
        title="Payment"
        backBtn={() => {
          history.goBack();
        }}
      />
      {/* <PaymentSuccessModal openModal={open} handleClose={handleClose} /> */}
      <div className={classes.cardBg}>
        <Stripe state={history.location.state} />
      </div>
    </div>
  );
}
