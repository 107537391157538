/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';

// importing helpers
import useStyles from '../css/PlanCardCss';
import PrimaryButton from '../../../Components/buttons/PrimaryButton';
import YellowCheckMark from '../../../assets/svg/yellowCheck.svg';
import PlanCardDesign from '../../../assets/svg/planCardDesign.svg';

export default function PlanCard(props) {
  const classes = useStyles();

  return (
    <div className={classes.planContainer}>
      <div className="planBox">
        <div className={classes.planHeaderBox}>
          <img src={PlanCardDesign} alt="nada" className={classes.planCardDesign} />
          <div className={classes.planHeaderTextContainer}>
            <span id="planType">{props.planType}</span>
            <div />
            <span id="planPrice">${props.planPrice}</span>
          </div>
        </div>
        <div className={classes.planDetailBox}>
          <ul>
            {props.planInfoList
              ? props.planInfoList.map((item) => (
                  <li>
                    <img src={YellowCheckMark} alt="nada" className={classes.yellowCheckMark} />
                    <span>{item}</span>
                  </li>
                ))
              : null}
          </ul>
          <PrimaryButton
            type="primary"
            variant="fullWidth"
            onClick={props.onClick}
            className={classes.planButton}
            Title="Purchase"
          />
        </div>
      </div>
    </div>
  );
}
