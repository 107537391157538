import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  cardContainer: {
    height: '13vmax',
    display: 'flex',
    marginBottom: '2vmax',
    width: '100%',
    borderRadius: '0 10px 10px 0',
    backgroundColor: 'rgb(0, 0, 0)',
    flexDirection: 'row',
    flexGrow: 1,
    overflow: 'hidden',
  },
  mapImageContainer: {
    width: '30%',
  },
  mapSmall: {
    width: '100%',
    height: '100%',
  },
  mapImage: {
    width: '100%',
    height: '100%',
  },
  rideInfoOuterContainer: {
    width: '70%',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rideInfoInnerContainer: {
    height: '75%',
    width: '85%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    display: 'flex',
  },
  startAddressContainer: {
    flexDirection: 'row',
    display: 'flex',
  },
  endAddressContainer: {
    flexDirection: 'row',
    display: 'flex',
  },
  label: {
    color: 'rgb(255,253,0)',
    fontSize: '1.8vmax',
    fontFamily: 'Avenir-Black',
    fontWeight: 'bold',
  },
  address: {
    color: 'rgb(255,255,255)',
    fontSize: '1.8vmax',
    fontFamily: 'Avenir-Roman',
    fontWeight: '400',
    marginLeft: '1vmin',
  },
  rideAddressContainer: {
    flexDirection: 'column',
    display: 'flex',
  },
  colorDot: {
    marginTop: '0.7vmax',
    marginRight: '0.5vmax',
    // padding: '0.7vmax 0.5vmax',
    height: '1vmax',
    width: '1vmax',
  },
  rideDateTimeContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  rideDateContainer: {
    color: 'rgb(181,181,181)',
    fontSize: '1.6vmax',
    fontFamily: 'AvenirNext-Bold',
    fontWeight: 'bold',
  },
  rideTimeContainer: {
    color: 'rgb(181,181,181)',
    fontSize: '1.6vmax',
    fontFamily: 'AvenirNext-Regular',
    fontWeight: '400',
  },
}));
