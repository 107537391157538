/* eslint-disable global-require */
import React from 'react';
// importing UI components

// importing helpers
import useStyles from './css/ExtendPassCardCss';

export default function PlainPassCard(props) {
  const classes = useStyles();

  return (
    <div className={classes.qrCodeContainer2}>
      <div className={classes.QRPassInfo1}>
        <div className={classes.passInfoContainer}>
          <div>
            <span className={classes.passLabelText}>Pass ID: </span>
            <span className={classes.passValueText}>{props.passId}</span>
          </div>
          <div>
            <span className={classes.passLabelText}>Pass Type: </span>
            <span className={classes.passValueText}>{props.passType}</span>
          </div>
        </div>
        <div className={classes.passDatesContainer}>
          <div className={classes.passDatesTextContainer}>
            <span className={classes.passDatesTextLabel}>Purchased: </span>
            <span className={classes.passDatesTextValue}>{props.purchaseDate}</span>
          </div>
          <div className={classes.passDatesTextContainer}>
            <span className={classes.passDatesTextLabel}>Started: </span>
            <span className={classes.passDatesTextValue}>{props.startDate}</span>
          </div>
          <div className={classes.passDatesTextContainer}>
            <span className={classes.passDatesTextLabel}>
              {props.renewDate ? 'Renewal:' : 'Expired:'}
            </span>
            <span className={classes.passDatesTextValue}>
              {props.renewDate ? props.renewDate : props.exipreDate}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
