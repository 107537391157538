/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable global-require */
import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

// importing UI components
import HeaderTitle from '../../../../../Components/headertitle/HeaderTitle';

// importing helpers
import useStyles from '../../../css/RideIssueDetailsCss';
import TripDetailRatingCard from '../../../components/TripDetailRatingCard';
import ListArrowCard from '../../../components/ListArrowCard';
import MapBig from '../../../../../assets/svg/mapBig.svg';
import IssueSuccessModal from '../../../components/IssueSuccessModal';

export default function RideIssueDetailsPage() {
  const classes = useStyles();
  const history = useHistory();
  const rideData = history.location.state;
  const [success, setSuccess] = useState(false);

  const svgPath = (iconName) => `${iconName}#svgView(preserveAspectRatio(none))`;

  const handleClose = () => {
    history.goBack();
  };
  return (
    <div className={classes.root}>
      <HeaderTitle title="Issue Details" backBtn={() => history.goBack()} />
      <div className={classes.cardBg}>
        {success && <IssueSuccessModal openModal={success} handleClose={handleClose} />}
        <div className={classes.imageMapContainer}>
          <img src={rideData.mapURL || MapBig} className={classes.mapBig} />
        </div>
        <TripDetailRatingCard rideData={rideData} />
        <ListArrowCard rideData={rideData} setSuccess={setSuccess} />
      </div>
    </div>
  );
}
