/* eslint-disable import/prefer-default-export */
/* eslint-disable global-require */
import { messaging } from './firebaseConfig';

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        messaging.onMessage((payload) => {
          const { title } = payload.notification;
          const options = {
            body: payload.notification.body,
            icon: require('../assets/svg/DriverIcon.svg'),
          };

          messaging.swRegistration.showNotification(title, options);
        });

        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });
