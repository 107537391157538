import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: 'black',
    minHeight: '100vh',
  },

  cardBg: {
    display: 'flex',
    flex: 1,
    minHeight: '80vh',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#313131',
    marginTop: '20px',
    borderRadius: '16px 16px 0 0',
    padding: '25px 0 25px 0',
  },
  rideButton: {
    minWidth: 'auto',
    marginTop: '16px',
    marginBottom: '20px',
    width: '322px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
