/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import React from 'react';
// importing UI components
import Slide from '@material-ui/core/Slide';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import Input from '@material-ui/core/Input';
import { Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import PrimaryButton from '../buttons/PrimaryButton';

// importing helpers
import useStyles from './css/RateDriverCardCss';

export default function RateDriverCard(props) {
  const classes = useStyles();
  const [amt, setAmt] = React.useState(['NONE', '$1', '$2', '$5']);
  const [rating, setRating] = React.useState(0);
  const [comment, setComment] = React.useState('');
  const [selectedButton, setSelectedButton] = React.useState('');

  const buttonSelected = (slctedBtn) => {
    setSelectedButton(slctedBtn);
  };

  return (
    <div className={props.checked ? classes.root : null}>
      <Slide
        timeout={{ appear: 300, enter: 300, exit: 300 }}
        direction="up"
        in={props.checked}
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.ccard}>
          <div className={classes.ratingsContainer}>
            <div className={classes.imageContainer}>
              <img alt="no profile" src={props.profilePic} style={{ borderRadius: '100px' }} />
            </div>
            <div className={classes.starRatingContainer}>
              <span className="starRatingContainerText">Rating</span>
              <Rating
                name="hover-feedback"
                value={rating}
                precision={0.5}
                onChange={(e) => {
                  // console.log('rating value', e.target.value);/
                  setRating(parseFloat(e.target.value));
                }}
                emptyIcon={<StarIcon className={classes.starIcon} fontSize="inherit" />}
              />
            </div>
            <div className={classes.inputContainerBox}>
              <InputLabel className={[classes.customLabel]} htmlFor="input-with-adornment">
                Tell us about your ride
              </InputLabel>
              <Input
                className={classes.inputContainer}
                placeholder="Additional comments"
                value={comment}
                onChange={(e) => {
                  // console.log('comment value', e.target.value);
                  setComment(e.target.value);
                }}
                // inputProps={{ 'aria-label': 'description' }}
              />
            </div>
            <div className={classes.tipContainer}>
              <span className={classes.customLabel}>Add a tip</span>
              <div className={classes.addTipContainer}>
                <div className={classes.tipButtonContainer}>
                  {amt.map((key) => (
                    <Button
                      key={key}
                      onClick={() => {
                        buttonSelected(key);
                      }}
                      className={
                        key == selectedButton ? classes.tipButtonSelected : classes.tipButtonDefault
                      }
                      variant="outlined"
                    >
                      {key}
                    </Button>
                  ))}
                </div>
                <span className={classes.addCustomTipText}>
                  Add a custom amount
                  {/* <InputLabel className={[classes.customLabel]} htmlFor="input-with-adornment">
                    Add a custom amount
                  </InputLabel>
                  <Input
                    className={classes.inputContainer}
                    type="number"
                    placeholder="Custom amount"
                    value={selectedButton}
                    onChange={(e) => {
                      // console.log('comment value', e.target.value);
                      buttonSelected(e.target.value);
                    }}
                  // inputProps={{ 'aria-label': 'description' }} 
                  /> */}
                </span>
              </div>
            </div>
            <div className={classes.ButtonContainer}>
              <PrimaryButton
                onClick={() => props.onClickToggle({ rating, comment })}
                type="primary"
                variant="fullWidth"
                Title="Submit"
                style={{ marginTop: '10px' }}
              />
            </div>
          </div>
        </div>
      </Slide>
    </div>
  );
}
