import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    background: 'rgb(0,0,0)',
    overflowX: 'hidden',
  },

  topBar: {
    display: 'flex',
    flex: 0.15,
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: '27px',
    paddingRight: '27px',
  },
  YellowH3: {
    height: '5vh',
    fontSize: '1.33rem',
    color: '#FFFD00',
    fontWeight: 'bold',
    margin: 0,
  },
  YellowH4: {
    height: '5vh',
    fontSize: '1.2rem',
    color: '#FFFD00',
    fontWeight: 'bold',
    margin: 0,
    marginTop: '4px',
  },
  icon: {
    justifyContent: 'flex-start',
    background: 'rgb(0,0,0)',
    border: 'none',
    paddingLeft: 0,
    marginBottom: '0.2vh',
  },
  topBarIcon: {
    height: '3vh',
  },
  body: {
    display: 'flex',
    flex: 0.85,
    flexDirection: 'column',
  },
  DriverInfoSection: {
    display: 'flex',
    flex: 0.67,
    flexDirection: 'column',
  },
  AvatarSection: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  greySection: {
    display: 'flex',
    flex: 0.27,
    background: 'rgba(73, 73, 73, 1)',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  },
  InfoSection: {
    display: 'flex',
    flex: 0.32,
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  StatsSection: {
    display: 'flex',
    flex: 0.2,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textAlign: 'center',
    marginLeft: '27px',
    marginRight: '27px',
  },
  Stat: {
    display: 'flex',
    flex: 0.33,
    alignItems: 'center',
    justifyContent: 'center',
  },
  Details: {
    position: 'absolute',
    top: '-45%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DriverAvatar: {
    height: '15vh',
    width: '15vh',
    border: '3px solid #fff',
    borderRadius: '50%',
  },
  optionsSection: {
    display: 'flex',
    flex: 0.17,
    justifyContent: 'center',
  },
  ReviewSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.43,
    background: 'linear-gradient(#313131 50%, #1F1F1E 100%)',
  },
  reviewCardsSection: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    flex: 0.9,
  },
  // Card: {
  //   display: 'flex',
  //   flex: '0 0 auto',
  //   background: '#313131',
  //   borderRadius: '20px',
  //   width: '85% !important',
  //   height: '79%',
  //   flexDirection: 'column',
  //   textAlign: 'left',
  //   marginTop: '8px',
  //   marginLeft: '20px',
  //   marginRight: '20px',
  //   padding: '10px',
  //   // boxShadow: '0 0px 15px rgba(0,253,0,0.56)',
  // },
  WhiteH3: {
    fontSize: '1.33rem',
    color: 'white',
    fontWeight: 'bold',
  },
  WhiteH4: {
    fontSize: '0.8rem',
    color: 'white',
  },
  ReviewTextSection: {
    display: 'flex',
    flex: 0.7,
    alignItems: 'center',
  },
  ratingSection: {
    display: 'flex',
    flex: 0.3,
    flexDirection: 'column',
    justifyContent: 'center',
  },

  // slideSingle: {
  //   height: '100%',
  //   '& .slick-active': {
  //     "& [class^='makeStyles-Card-']": {
  //       boxShadow: '0px 0px 15px #08FD00',
  //     },
  //   },
  // },
  slider: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));
