import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(var(--vh, 1vh) * 100) !important',
    marginRight: 0,
    backgroundColor: 'black',
  },
  // slideSingle: {
  //   '& .slick-active': {
  //     "& [class^='makeStyles-planBox-']": {
  //       boxShadow: '0px 0px 2vmax #08FD00',
  //     },
  //   },
  // },

  cardBg: {
    backgroundColor: '#313131',
    marginTop: '20px',
    paddingTop: 40,
    minHeight: '81vh',
    borderRadius: '16px 16px 0 0',
  },
  slider: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100%',
    marginTop: '3vmax',
  },
  dotsPlans: {
    display: 'flex !important',
    justifyContent: 'center',
    '& li': {
      width: 6,
      height: 6,
      margin: '0 3px',
      listStyleType: 'none',
      '&.slick-active': {
        '& button': {
          '&:before': {
            opacity: 1,
            color: '#8F8F87',
          },
        },
      },
      '& button': {
        width: 6,
        height: 6,
        padding: 0,
        lineHeight: '16px',
        marginLeft: -18,
        color: 'rgb(83,83,83)',
        background: 'rgb(83,83,83)',
        fontSize: 0,
        outline: 'none',
        boxShadow: 'none',
        border: 'none',
        borderRadius: '50%',

        '&:before': {
          lineHeight: 0,
          width: 6,
          height: 6,
          color: '#535353',
          opacity: 1,
        },
      },
    },
    '& li.slick-active button': {
      background: 'rgb(143,143,135)',
    },
  },
  loadingLabel: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2vmax',
    fontWeight: 'bolder',
    color: 'white',
    width: '100%',
  },
  // 'li::marker': {
  //   // color: '#535353',
  //   display: 'none'
  // },
}));
