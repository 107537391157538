/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Input } from '@material-ui/core';

import { firebase } from '../../../config/firebaseConfig';
import YelloLogo from '../../../assets/svg/yelloLogo.svg';
import navigationHandler from '../../App/helpers/NavigationHandler';

import useStyles from '../css/OTPpageCSS';
import { createProfile } from '../../../Actions';
import PrimaryButton from '../../../Components/buttons/PrimaryButton';
import HeaderBackButton from '../../../Components/buttons/HeaderBackButton';

export default function CompleteProfile() {
  const classes = useStyles();
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [name, setName] = useState('');
  const svgPath = `${YelloLogo}#svgView(preserveAspectRatio(none))`;

  const RegisterUser = () => {
    setLoader(true);
    firebase
      .auth()
      .currentUser.getIdTokenResult(true)
      .then((getIdTokenResult) => {
        const idToken = getIdTokenResult.token;
        createProfile(idToken, {
          name,
          code: history.location.state.code,
          phone: history.location.state.number,
        })
          .then((res) => {
            localStorage.setItem('userIdHasura', res.user_id);

            setLoader(false);
            navigationHandler(history, '/plans-page', null, { newUser: true });
            // history.push({
            //   pathname: `/plans-page`,
            //   state: {
            //     newUser: true,
            //   },
            // });
          })
          .catch((err) => {
            console.log(err);
            setLoader(false);
          });
      });
  };
  return (
    <Grid container className={classes.root}>
      <div className={classes.rootInnerContainer}>
        <div style={{ height: '65%' }}>
          <HeaderBackButton
            backBtn={() => history.goBack()}
            headerStyle={{ position: 'absolute' }}
          />
          <img
            src={svgPath}
            style={{ height: '65vmax', width: '100vmin', transform: 'translate(0, 3vmax)' }}
            alt="nothing"
          />
        </div>
        <div className={classes.cardContainer}>
          <Grid container className={classes.cardInnerContainer}>
            <Grid className={classes.welcomeTextContainer}>
              <Typography className={classes.welcomeText}>Just finishing up</Typography>
            </Grid>
            <Grid className={classes.inputNumberContainer}>
              <div className={classes.input}>
                <Input
                  type="text"
                  className={classes.inputNumberContainer}
                  placeholder="Enter your name"
                  required
                  onChange={(text) => {
                    setName(text.target.value);
                    if (text.target.value === '') setError('Name required');
                  }}
                  // inputProps={{ 'aria-label': 'description' }}
                />
                {error ? (
                  <div className={classes.errorMessage}>
                    <span>{error}</span>
                  </div>
                ) : null}
              </div>
            </Grid>
            <Grid style={{ height: '30%' }}>
              <PrimaryButton
                type="primary"
                variant="fullWidth"
                className={classes.nextButton}
                onClick={() => {
                  if (name === '') {
                    setError('Name required');
                  } else {
                    setError(null);
                    RegisterUser();
                  }
                }}
                Title={loader ? 'Loading' : 'Next'}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  );
}
