/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import React from 'react';

import { useHistory } from 'react-router-dom';
import moment from 'moment';

// importing UI components
import Rating from '@material-ui/lab/Rating';
import RoutePath from '../../../assets/svg/ridePath.svg';

// importing helpers
import useStyles from '../css/RideEndTripDetCard';

export default function RideEndTripDetailCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const svgPath = (iconName) => `${iconName}#svgView(preserveAspectRatio(none))`;
  const convertToDate = (date) => {
    const localDate = moment.utc(date).local().format('Do MMMM[,] YYYY');
    return localDate;
  };
  const { tripData } = props;
  return (
    <div className={classes.passengerContainer}>
      <div id="innerBox">
        <div id="innerBox-1">
          <div id="innerBox-1-dot-route">
            <div className={classes.mapSmallContainer}>
              <img alt="nothing" src={RoutePath} className={classes.mapSmall} />
            </div>
            <div className="innerBox-1-dot-route-timeline">
              <div className="innerBox-1-dot-route-start">
                <span>{tripData ? tripData.start_address.line1 : ''}</span>
              </div>

              <div className="innerBox-1-dot-route-end">
                <span>{tripData ? tripData.end_address.line1 : ''}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.innerBox2}>
          <div className={classes.dateTimeContainer}>
            <span className={classes.labelTextInBox2}>
              {' '}
              {tripData ? convertToDate(tripData.end_at) : ''}
            </span>
            <span className={classes.normalTextInBox2}>
              {`
                ${
                  tripData && tripData.duration
                    ? `${parseFloat(tripData.duration).toFixed(2)}m`
                    : ''
                } | 
                ${
                  tripData && tripData.distance
                    ? `${parseFloat(tripData.distance * 0.621371).toFixed(2)}m`
                    : ''
                }
              `}
            </span>
          </div>
          <div className={classes.passIdContainer}>
            <span className={classes.labelTextInBox2}>Pass ID: </span>
            <span className={classes.normalTextInBox2}>
              {tripData ? tripData.boarding_pass.pass_number : ''}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
