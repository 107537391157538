import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(var(--vh, 1vh) * 100)',

    justifyContent: 'space-between',
    backgroundColor: 'black',
  },

  cardBg: {
    display: 'flex',
    flex: 0.85,
    paddingTop: '2.5vmax',
    backgroundImage: 'linear-gradient(rgb(49, 49, 49), rgb(31, 31, 30))',
    overflowY: 'auto',
    height: 'calc(var(--vh, 1vh) * 90)',

    flexDirection: 'column',
    alignItems: 'center',
  },
  innerContainer: {
    width: '85%',
    paddingTop: '9vh',
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  accordianCardContainer: {
    width: '85%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
