import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  cardContainer: {
    minHeight: '7vh',
    display: 'flex',
    marginBottom: '2.5vmax',
    width: '100%',
    borderRadius: '8px',
    backgroundColor: 'rgb(0, 0, 0)',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textContainer: {
    fontSize: '2.1vmax',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontFamily: 'Avenir-Black',
    fontWeight: '900',
    marginLeft: '5vmin',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '3vmin',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '5vmin',
  },
}));
