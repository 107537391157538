/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

// importing UI components
import HeaderTitle from '../../../Components/headertitle/HeaderTitle';

// importing helpers
import useStyles from '../css/PassesPageCSS';
import SwitchField from '../../../Components/inputfields/SwitchField';
import ActivePassCard from '../../../Components/cards/ActivePassCard';
import ExpiredPassCard from '../../../Components/cards/ExpiredPassCard';
import Loader from '../../../Components/loader/Loader';
import { getPasses } from '../../../Actions/Passes';
import PrimaryButton from '../../../Components/buttons/PrimaryButton';

export default function PassesPage() {
  const [switchState, setSwitchState] = useState({
    checkedSwitch: false,
  });
  const [activePasses, setActivePasses] = useState([]);
  const [expiredPasses, setExpiredPasses] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const active = [];
    const expired = [];
    getPasses()
      .then((res) => {
        res.data.yt_boarding_pass.forEach((item) => {
          if (item.status === 'ACTIVE') {
            active.push(item);
          } else {
            expired.push(item);
          }
        });
        setActivePasses(active);
        setExpiredPasses(expired);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        // setPlansList([]);
        // setLoader(false);
      });
  }, []);

  const handleChange = (event) => {
    setSwitchState({ ...switchState, [event.target.name]: event.target.checked });
  };
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <HeaderTitle title="My Passes" backBtn={() => history.goBack()} />
      <div className={classes.cardBg}>
        <div className={classes.switchContainer}>
          <SwitchField
            switchLabel1="Active"
            switchLabel2="Expired"
            onChange={handleChange}
            checked={switchState.checkedSwitch}
          />
        </div>
        {!loader ? (
          switchState.checkedSwitch ? (
            <>
              {expiredPasses.length > 0 ? (
                expiredPasses.map((item) => <ExpiredPassCard passDetails={item} key={item.id} />)
              ) : (
                <div className={classes.errorContainer}>
                  <p>No Passes Found</p>
                </div>
              )}
            </>
          ) : (
            <>
              {activePasses.length > 0 ? (
                activePasses.map((item) => <ActivePassCard passDetails={item} key={item.id} />)
              ) : (
                <div className={classes.errorContainer}>
                  <p>
                    It seems you don&apos;t have an active pass. You need to have an active pass to
                    ride Yello.
                  </p>
                  <Link to="/plans-page" style={{ textDecoration: 'none' }}>
                    <PrimaryButton
                      type="primary"
                      variant="fullWidth"
                      className={classes.saveButton}
                      Title="Get A Pass"
                    />
                  </Link>
                </div>
              )}
            </>
          )
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}
