import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    height: '27vmax',
    width: '80vmin',
    borderRadius: '15px',
    padding: '1vmax',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundImage: 'linear-gradient(to bottom, rgb(49,49,49), rgb(31,31,30))',
  },
  headerText: {
    color: 'rgb(255,253,0)',
    fontFamily: 'Avenir-Black',
    textAlign: 'center',
    fontSize: '2.2vmax',
    fontWeight: 'bold',
    // width: '90%',
  },
  paragraphText: {
    color: 'white',
    fontFamily: 'Avenir-Book',
    textAlign: 'center',
    fontSize: '1.8vmax',
    width: '90%',
  },
  verifiedIcon: {
    marginBottom: '2.2vmax',
    height: '6vmax',
  },
  planButton: {
    width: '70%',
    marginTop: '2vmax',
  },
}));
