/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

// importing UI helpers
import HeaderTitle from '../../../Components/headertitle/HeaderTitle';
import useStyles from '../css/SavedPlacesCSS';

import SwipeList from '../components/SwipeListItem';
import PrimaryButton from '../../../Components/buttons/PrimaryButton';
import navigationHandler from '../../App/helpers/NavigationHandler';
import { getUserSavedPlaces, deleteSavedPlace } from '../../../Actions';

export default function SavedPlaces() {
  const classes = useStyles();
  const history = useHistory();
  const [placesData, setPlacesData] = React.useState(null);


  useEffect(() => {
    getUserSavedPlaces().then((res) => {
      console.log(res.data.yt_user_place,'res');
      setPlacesData(res.data.yt_user_place);
    });
  }, []);

  const getSavedPlaces = () => {
    console.log("I am here ...")
    getUserSavedPlaces().then((res) => {
      // console.log('saved ride places NEW', res);
      setPlacesData(res.data.yt_user_place);
    });
  };

  const dltSavedPlace = (id) => {
    deleteSavedPlace(id)
      .then((res) => {
        getSavedPlaces();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={classes.root}>
      <HeaderTitle title="Saved Places" backBtn={() => history.goBack()} />
      <div className={classes.cardBg}>
        <div className={classes.listContainer}>
          {placesData && placesData.length > 0 ? (
            placesData.map((data) => (
              // eslint-disable-next-line react/jsx-indent
              <div key={data.id}>
                <SwipeList
                  addressLabel={data.title ? data.title : ''}
                  address={data.address ? data.address : ''}
                  onClickEdit={() =>
                    navigationHandler(history, '/my-saved-places/edit-place', null, {
                      ...data,
                      type: 'edit',
                      typeLabel: 'Edit Place',
                    })
                  }
                  onClickDelete={() => dltSavedPlace(data.id)}
                />
              </div>
            ))
          ) : (
            <div className={classes.errorContainer}>
              <p>No Saved Places</p>
            </div>
          )}
        </div>
        <PrimaryButton
          onClick={() =>
            navigationHandler(history, '/my-saved-places/add-place', null, {
              type: 'add',
              typeLabel: 'Add Place',
            })
          }
          type="primary"
          //   variant="fullWidth"
          className={classes.addButton}
          Title="Add Place"
        />
      </div>
    </div>
  );
}
