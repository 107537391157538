import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Splash from '../../../assets/img/splash.gif';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}));

function SplashScreen() {
  const history = useHistory();
  const classes = useStyles();
  const navigationHandler = (url, param, data) => {
    history.push({
      pathname: `${url}`,
      param,
      data: data || {},
    });
  };

  useEffect(() => {
    setTimeout(() => {
      const token = localStorage.getItem('token');
      const firstLoad = localStorage.getItem('firstLoad');
      if (firstLoad) {
        if (token) {
          navigationHandler('home');
        } else {
          navigationHandler('login');
        }
      } else {
        navigationHandler('addToHome');
        localStorage.setItem('firstLoad', true);
      }
    }, 4000);
  }, []);

  return (
    <div className={classes.root}>
      <img src={Splash} alt="Splash" height="100%" />
    </div>
  );
}

export default SplashScreen;
