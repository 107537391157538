/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorLink } from '@apollo/client/link/error';
import useStyles from '../css/HomePageCSS';
import SearchableMap from '../helpers/Maps/SearchableMap';
import PrimaryButton from '../../../Components/buttons/PrimaryButton';
import { getUser, registerPushNotification, UpdateRideRequestStatus } from '../../../Actions';
import {
  CreateRide,
  DesubscribeFromRide,
  DesubscribefromRideLocation,
  searchForExistingRide,
  CancelRide,
  LookForRides,
  getActivePasses,
  checkCancelRequest,
} from '../../../Actions/Ride';
import Animation from '../../../assets/img/animation.gif';
import NavigationHandler from '../../App/helpers/NavigationHandler';
import getUrlFromId from '../../../Actions/Common';
import { requestFirebaseNotificationPermission } from '../../../config/firebasePermission';
import ModalWithImg from '../../../Components/modal/ModalWithImg';
import slackAxios from '../../../slack';

export default function Home(props) {
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  const [user, setUser] = useState({ name: 'Loading', id: null });
  const [driverStatus, setStatus] = useState(false);
  const [ridedetails, setDetails] = useState(null);
  const [userDataCheck, setUserDataCheck] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [passFound, setPass] = useState(false);
  const [passData, setPassData] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [openCancelByUserModal, setOpenCancelByUserModal] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        // eslint-disable-next-line no-console
        const existingToken = localStorage.getItem('FCMToken');
        const userId = localStorage.getItem('userIdHasura');
        if (existingToken !== firebaseToken || !existingToken) {
          localStorage.setItem('FCMToken', firebaseToken);
          registerPushNotification(firebaseToken, userId, firebaseToken)
            .then((res) => {
              console.log('Firebase', res);
            })
            .catch((err) => {
              console.log('Firebase err', err);
            });
        }
      })
      .catch((err) => {
        console.log('Firebase Error', err);
        return err;
      });
  }, []);

  const getUrl = async (mapId) => {
    const url = await getUrlFromId(mapId);
    const result = await url.data.GenViewUrl[0].url;
    return result;
  };

  // eslint-disable-next-line camelcase
  const RideCancel = (reason, ride_id, cancelByDriver) => {
    setSpinner(true);

    if (cancelByDriver) {
      setOpen(true);
    }
    const config = {
      cancellation_reason: reason || 'Cancelled by Rider',
      ride_id,
    };
    CancelRide(config)
      .then(() => {
        UpdateRideRequestStatus(config.ride_id)
          .then(() => {
            setLoader(false);
            setSpinner(false);
            setStatus(false);
            setDetails(null);
            setOpenCancelByUserModal(true);
          })
          .catch((err) => {
            setSpinner(false);
          });
      })
      .catch((err) => {
        setSpinner(false);
      });
  };

  const RideFound = useCallback(async (res) => {
    console.log('RideFound res', res);
    try {
      if (res.data) {
        await checkCancelRequest(res.data.yt_ride[0].id, res.data.yt_ride[0].user_id, RideCancel);
        if (res.data.yt_ride[0].driver_user_id !== null) {
          if (res.data.yt_ride[0].driver.profile_photo_file_id) {
            const driverPic = await getUrl(res.data.yt_ride[0].driver.profile_photo_file_id);
            setDetails({
              driver: {
                ...res.data.yt_ride[0].driver,
                profile_photo_url: driverPic,
              },
              vehicle: res.data.yt_ride[0].vehicle,
              confirmation_code: res.data.yt_ride[0].confirmation_code,
              status: res.data.yt_ride[0].status,
              id: res.data.yt_ride[0].id,
              route: res.data.yt_ride[0].mapbox_route,
            });
            localStorage.setItem(
              'DESTINATION',
              JSON.stringify(res.data.yt_ride[0].mapbox_route.destination),
            );
          } else {
            setDetails({
              driver: res.data.yt_ride[0].driver,
              vehicle: res.data.yt_ride[0].vehicle,
              confirmation_code: res.data.yt_ride[0].confirmation_code,
              status: res.data.yt_ride[0].status,
              id: res.data.yt_ride[0].id,
              route: res.data.yt_ride[0].mapbox_route,
            });
            localStorage.setItem(
              'DESTINATION',
              JSON.stringify(res.data.yt_ride[0].mapbox_route.destination),
            );
          }

          if (
            res.data.yt_ride[0].status === 'COMPLETE' ||
            res.data.yt_ride[0].status === 'CANCELED'
          ) {
            DesubscribeFromRide();
            DesubscribefromRideLocation();
            setLoader(false);
            setStatus(false);
          }
        } else if (res.data.yt_ride[0].status === 'NEW') {
          setDetails({
            status: res.data.yt_ride[0].status,
            id: res.data.yt_ride[0].id,
          });
        } else if (res.data.yt_ride[0].status === 'CANCELED') {
          setLoader(false);
          setSpinner(false);
          setStatus(false);
          setDetails(null);
          localStorage.removeItem('DESTINATION');
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  console.log({ ridedetails });
  useEffect(() => {
    slackAxios({ file: 'ridedetails changed', ridedetails });

    if (ridedetails !== null && ridedetails.status === 'COMPLETE') {
      NavigationHandler(history, '/home/end-ride', null, { id: ridedetails.id });
    } else if (ridedetails !== null && ridedetails.driver !== undefined) {
      setLoader(false);
      setStatus(true);
    }
  }, [ridedetails]);

  const RideCreated = (p1, p2, distance, vehicles, route, boardingPass) => {
    setLoader(true);
    const t = [];
    if (vehicles.length > 0) {
      vehicles.map((item) => {
        return t.push({ id: item.id });
      });
    }
    const rt = { ...route, source: p1, destination: p2 };
    const config = {
      distance,
      end_address: { line1: p2.address || ' ' },
      end_location: { lat: p2.latitude, lon: p2.longitude },
      start_address: { line1: p1.address || ' ' },
      start_location: { lat: p1.latitude, lon: p1.longitude },
      user_id: user.id,
      nearest_vehicles: t,
      result: rt,
      boardingPass,
    };
    CreateRide(config, RideFound, setLoader)
      .then(() => {})
      .catch((err) => {
        if (err.toString() === 'Error: Boarding pass is expired.') {
          setError('Boarding pass expired, please buy a new one.');
        } else {
          setError(err.toString());
        }
      });
  };

  const checkPasses = (p1, p2, distance, vehicles, route) => {
    if (user !== null) {
      getActivePasses(user.id)
        .then((res) => {
          if (res.data.yt_boarding_pass.length === 0) {
            setError('Sorry we could not find a pass');
          } else if (res.data.yt_boarding_pass.length > 1) {
            setPassData(res.data.yt_boarding_pass);
            setPass(true);
          } else {
            RideCreated(p1, p2, distance, vehicles, route, res.data.yt_boarding_pass[0].id);
          }
        })
        .catch((err) => {
          setError('Pass not valid');
        });
    }
  };

  const renderLoaderScreen = () =>
    loader && (
      <div
        style={{
          display: 'flex',
          flex: 1,
          position: 'absolute',
          height: 'calc(var(--vh, 1vh) * 100)',
          background: 'rgba(0,0,0,0.8)',
          zIndex: 2,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', marginTop: '10px', width: '100vw' }}>
          <img src={Animation} alt="Loading" style={{ width: '100%' }} />
        </div>
        <div
          className={classes.ButtonContainer}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            position: 'absolute',
            bottom: 0,
          }}
        >
          <PrimaryButton
            type="primary"
            variant="fullWidth"
            Title={spinner ? 'Loading' : 'Cancel'}
            disabled={spinner}
            style={{ margin: '30px' }}
            onClick={(e) => {
              if (ridedetails != null) {
                RideCancel('', ridedetails.id);
              } else {
                const id = localStorage.getItem('userIdHasura');
                getUser(id, history)
                  .then((res) => {
                    searchForExistingRide(id).then((respo) => {
                      RideCancel('', respo.data.yt_ride[0].id);
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }}
          />
        </div>
      </div>
    );

  // eslint-disable-next-line consistent-return
  const checkRideStatus = (data) => {
    if (data && data.status !== 'COMPLETE' && data.status !== 'CANCELED') {
      switch (data.status) {
        case 'NEW':
          // If its not assigned a driver yet
          setLoader(true);
          LookForRides(data.id, data.user_id, RideFound);
          break;
        default:
          // if the ride is in progress
          setDetails({
            driver: data.driver,
            vehicle: data.vehicle,
            confirmation_code: data.confirmation_code,
            status: data.status,
            id: data.id,
            route: data.mapbox_route,
          });
          LookForRides(data.id, data.user_id, RideFound);
      }
    }
  };

  React.useEffect(() => {
    const getUserInfo = async () => {
      const id = await localStorage.getItem('userIdHasura');
      await getUser(id)
        .then((res) => {
          setUser({ name: res.data.yt_user[0].full_name, id: res.data.yt_user[0].id });
          localStorage.setItem('user', JSON.stringify(res.data.yt_user[0]));
          setUserDataCheck(true);
          searchForExistingRide(id)
            .then(async (respo) => {
              if (
                respo.data.yt_ride.length > 0 &&
                (respo.data.yt_ride[0].status === 'ASSIGNED' ||
                  respo.data.yt_ride[0].status === 'IN_PROGRESS')
              ) {
                const resData = { ...respo.data.yt_ride[0] };
                if (resData.driver && resData.driver.profile_photo_file_id) {
                  // const driverPic = await getUrl(
                  //   respo.data.yt_ride[0].driver.profile_photo_file_id,
                  // );
                  // resData.driver = {
                  //   ...resData.driver,
                  //   profile_photo_url: driverPic,
                  // };
                }
                checkRideStatus(resData);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getUserInfo();
  }, []);

  return (
    <div className={classes.root}>
      {renderLoaderScreen()}
      <SearchableMap
        user={user}
        userDataCheck={userDataCheck}
        createRide={RideCreated}
        rideStatus={driverStatus}
        details={ridedetails}
        setLoader={setLoader}
        rideFound={RideFound}
        cancelRide={RideCancel}
        spinner={spinner}
        checkPass={checkPasses}
        multiplePassFound={passFound}
        passData={passData}
        error={error}
      />
      {(open || openCancelByUserModal) && (
        <ModalWithImg
          Title={open ? 'Reject Ride' : 'Cancel Ride'}
          message={
            open
              ? 'All the near by driver has been rejected your ride request'
              : 'You cancelled the ride'
          }
          closeModal={() => {
            setOpenCancelByUserModal(false);
            setOpen(false);
          }}
        />
      )}
    </div>
  );
}
