import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100vmax',
    justifyContent: 'space-between',
    backgroundColor: 'black',
  },

  cardBg: {
    backgroundImage: 'linear-gradient(rgb(49, 49, 49), rgb(31, 31, 30))',
    // paddingTop: '20px',
    paddingBottom: '3vmax',
    marginTop: '3vmax',
    height: '90vmax',
    display: 'flex',
    // overflow: 'scroll',
    flexDirection: 'column',
    width: '100%',
    // justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px 16px 0 0',
  },
  imageMapContainer: {
    height: '37vmin',
    width: '100vmin',
  },
  mapBig: {
    height: '100%',
    width: '100%',
  },
}));
