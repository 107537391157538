/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable global-require */
import React from 'react';
// importing UI components
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ReactComponent as RightArrowIcon } from '../../assets/svg/rightArrow.svg';

// importing helpers
import useStyles from './css/AccordianCardCss';

export default function AccordianCard(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordian}>
        <AccordionSummary
          className={classes.accordianSummary}
          expandIcon={<RightArrowIcon height="15px" width="15px" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.summaryTextContainer}>
            <span>{props.summary}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.detailsTextContainer}>
            <span>{props.details}</span>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
