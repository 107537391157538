import { hot } from 'react-hot-loader/root';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';

// import helpers
import history from './config/history';
import Login from '../Login/screens/Login';
import SplashScreen from '../SplashScreen/screen/Splashscreen';
import Home from '../Home/screens/Home';
import NotFoundPage from '../NotFoundPage/NotFound';
import OTPScreen from '../Login/screens/OTPScreen';
import CompleteProfile from '../Login/screens/ProfileComplete';
import PlansPage from '../PlansPage/screens/PlansPage';
import MyRidesPage from '../MyRidesPage/screens/MyRidesPage';
import RideDetailsPage from '../RideDetailsPage/screens/RideDetailsPage';
import Driverdetails from '../Home/subcomponents/driver/screens/DriverDetails';
import PassesPage from '../PassesPage/screens/PassesPage';
import BoardingPassDetailsActive from '../BoardingPassDetails/screens/BoardingPassDetailsActive';
import BoardingPassDetailsExpired from '../BoardingPassDetails/screens/BoardingPassDetailsExpired';
import NotificationsPage from '../Notificatiions/screens/Notifications';
import SavedPlaces from '../SavedPlaces/screens/SavedPlacesPage';
import AddEditPlace from '../SavedPlaces/screens/AddEditPlace';
import ProfilePage from '../Profile/screens/ProfilePage';
import AccountSettingsPage from '../AccountSettings/screens/AccountSettings';
import NotificationSettingsPage from '../AccountSettings/screens/SubPages/NotificationSettings';
import UserGuidePage from '../AccountSettings/screens/SubPages/UserGuide';
import FAQsPage from '../AccountSettings/screens/SubPages/FAQs';
import RideIssuesPage from '../AccountSettings/screens/SubPages/RideIssues/RideIssues';
import RideIssueDetailsPage from '../AccountSettings/screens/SubPages/RideIssues/RideIssueDetails';
import LegalPage from '../AccountSettings/screens/SubPages/Legal/Legal';
import LegalSubPage from '../AccountSettings/screens/SubPages/Legal/LegalSubPage';
import HelpDeskPage from '../HelpDesk/screens/HelpDesk';
import PaymentMethodPage from '../PaymentMethod/screens/PaymentMethod';
import CardPaymentPage from '../PaymentMethod/screens/CardPayment';
import EndRideDetail from '../Home/screens/RideEndScreens/RideEndDetails';
import AddToHomeScreen from '../AddToHomeScreen/screen/AddToHomeScreen';
import NetworkErrorPage from '../NetworkErrorPage/NetworkErrorPage';

import './App.css';
import client from './config/ApolloClient';

import 'slick-carousel/slick/slick.css';
import { PrivateRoute, PublicRoute } from './helpers/Privateroute';

// eslint-disable-next-line react/prefer-stateless-function

function App() {
  const [isDesktop, setValue] = React.useState(false);
  const updateViewPort = () => {
    setValue(window.innerWidth >= 720);
  };
  useEffect(() => {
    setValue(window.innerWidth >= 720);
    window.addEventListener('resize', updateViewPort);
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = `width=device-width,height=${window.innerHeight}, initial-scale=1.0`;
    document.getElementsByTagName('head')[0].appendChild(meta);
    return function cleanup() {
      window.removeEventListener('resize', updateViewPort);
    };
  }, []);

  return isDesktop ? (
    <div> Cannot view on desktop</div>
  ) : (
    <ApolloProvider client={client}>
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <SplashScreen />
          </Route>
          <Route exact path="/addToHome">
            <AddToHomeScreen />
          </Route>
          <PublicRoute exact path="/login">
            <Login />
          </PublicRoute>
          <PublicRoute exact path="/verifyotp">
            <OTPScreen />
          </PublicRoute>
          <PublicRoute exact path="/profilecomplete">
            <CompleteProfile />
          </PublicRoute>
          <PrivateRoute exact path="/home">
            <Home />
          </PrivateRoute>
          <PrivateRoute exact path="/home/end-ride">
            <EndRideDetail />
          </PrivateRoute>
          <PublicRoute exact path="/plans-page" isGlobalAccess>
            <PlansPage />
          </PublicRoute>
          <PublicRoute exact path="/payment-method" isGlobalAccess>
            <PaymentMethodPage />
          </PublicRoute>
          <PrivateRoute exact path="/payment-method/card-payment" isGlobalAccess>
            <CardPaymentPage />
          </PrivateRoute>
          <PrivateRoute exact path="/my-rides">
            <MyRidesPage />
          </PrivateRoute>
          <PrivateRoute exact path="/my-rides/ride-details">
            <RideDetailsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/driverdetail">
            <Driverdetails />
          </PrivateRoute>
          <PrivateRoute exact path="/my-passes">
            <PassesPage />
          </PrivateRoute>
          <PrivateRoute exact path="/my-passes/pass-details-active">
            <BoardingPassDetailsActive />
          </PrivateRoute>
          <PrivateRoute exact path="/my-passes/pass-details-expired">
            <BoardingPassDetailsExpired />
          </PrivateRoute>
          <PrivateRoute exact path="/notifications">
            <NotificationsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/my-saved-places">
            <SavedPlaces />
          </PrivateRoute>
          <PrivateRoute exact path="/my-saved-places/:addOrEdit">
            <AddEditPlace />
          </PrivateRoute>
          <PrivateRoute exact path="/my-profile">
            <ProfilePage />
          </PrivateRoute>
          <PrivateRoute exact path="/account-settings">
            <AccountSettingsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/account-settings/notification-settings">
            <NotificationSettingsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/account-settings/user-guide">
            <UserGuidePage />
          </PrivateRoute>
          <PrivateRoute exact path="/account-settings/faq">
            <FAQsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/account-settings/ride-issues">
            <RideIssuesPage />
          </PrivateRoute>
          <PrivateRoute exact path="/account-settings/ride-issues/ride-issue-detail">
            <RideIssueDetailsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/account-settings/legal">
            <LegalPage />
          </PrivateRoute>
          <PrivateRoute exact path="/account-settings/legal/:subpage">
            <LegalSubPage />
          </PrivateRoute>
          <PrivateRoute exact path="/help-desk">
            <HelpDeskPage />
          </PrivateRoute>
          <Route path="/network-error">
            <NetworkErrorPage />
          </Route>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
}

export default hot(App);
