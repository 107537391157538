import axios from 'axios';
import token from '../config/config';

export default async (props) =>
  axios
    .get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/` +
        `${props.longitude},${props.latitude}.json?` +
        `types=poi&access_token=${token}`,
    )
    .then((res) => {
      if (res.data.features !== []) {
        return {
          name: res.data.features[0].text || null,
          place: res.data.features[0].place_name,
        };
      }
      return {
        name: 'unnamed',
      };
    })
    .catch((err) => {
      return {
        name: 'Unnamed Location',
      };
    });
