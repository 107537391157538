import { gql } from '@apollo/client';
// eslint-disable-next-line import/no-cycle
import client from '../Containers/App/config/ApolloClient';

export const getUserSavedPlaces = () => {
  //const id = '11ee17c6-48a3-4220-967e-5cc50c3c5d6a';
  //console.log(id,'user id');
   const id = localStorage.getItem('userIdHasura');

  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query($user_id: uuid!) {
            yt_user_place(where: { user_id: { _eq: $user_id } }, order_by: { updated_at: desc }) {
              address
              id
              location
              name
              title
              created_at
            }
          }
        `,
        variables: {
          user_id: id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addSavedPlace = (data, title) => {
  const id = localStorage.getItem('userIdHasura');
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation(
            $address: jsonb!
            $user_id: uuid!
            $location: point!
            $name: String!
            $title: String!
          ) {
            insert_yt_user_place_one(
              object: {
                address: $address
                user_id: $user_id
                location: $location
                name: $name
                title: $title
              }
            ) {
              user_id
              created_at
            }
          }
        `,
        variables: {
          address: data.place_name,
          user_id: id,
          location: `(${data.center[0]}, ${data.center[1]})`,
          name: data.text,
          title,
        },
        fetchPolicy: 'no-cache',
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: gql`
              query($user_id: uuid!) {
                yt_user_place(where: { user_id: { _eq: $user_id } }) {
                  address
                  id
                  location
                  name
                  title
                  created_at
                }
              }
            `,
            variables: {
              user_id: id,
            },
          },
        ],
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteSavedPlace = (id) => {
  const userId = localStorage.getItem('userIdHasura');
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation($id: uuid!) {
            delete_yt_user_place_by_pk(id: $id) {
              name
              title
              id
              updated_at
            }
          }
        `,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: gql`
              query($user_id: uuid!) {
                yt_user_place(where: { user_id: { _eq: $user_id } }) {
                  address
                  id
                  location
                  name
                  title
                  created_at
                }
              }
            `,
            variables: {
              user_id: userId,
            },
          },
        ],
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateSavedPlace = (placeData, title, id) => {
  const userId = localStorage.getItem('userIdHasura');
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation(
            $address: jsonb!
            $location: point!
            $name: String!
            $title: String!
            $id: uuid!
          ) {
            update_yt_user_place_by_pk(
              _set: { address: $address, location: $location, name: $name, title: $title }
              pk_columns: { id: $id }
            ) {
              address
              location
              name
              title
            }
          }
        `,
        variables: {
          address: placeData.place_name,
          id,
          location: `(${placeData.center[0]}, ${placeData.center[1]})`,
          name: placeData.text,
          title,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: gql`
              query($user_id: uuid!) {
                yt_user_place(where: { user_id: { _eq: $user_id } }) {
                  address
                  id
                  location
                  name
                  title
                  created_at
                }
              }
            `,
            variables: {
              user_id: userId,
            },
          },
        ],
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateSavedPlaceAddress = (placeData, id) => {
  const userId = localStorage.getItem('userIdHasura');
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation($address: jsonb!, $location: point!, $name: String!, $id: uuid!) {
            update_yt_user_place_by_pk(
              _set: { address: $address, location: $location, name: $name }
              pk_columns: { id: $id }
            ) {
              address
              location
              name
            }
          }
        `,
        variables: {
          address: placeData.place_name,
          id,
          location: `(${placeData.center[0]}, ${placeData.center[1]})`,
          name: placeData.text,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: gql`
              query($user_id: uuid!) {
                yt_user_place(where: { user_id: { _eq: $user_id } }) {
                  address
                  id
                  location
                  name
                  title
                  created_at
                }
              }
            `,
            variables: {
              user_id: userId,
            },
          },
        ],
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateSavedPlaceTitle = (title, id) => {
  const userId = localStorage.getItem('userIdHasura');
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation MyMutation($title: String!, $id: uuid!) {
            update_yt_user_place_by_pk(_set: { title: $title }, pk_columns: { id: $id }) {
              title
            }
          }
        `,
        variables: {
          id,
          title,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: gql`
              query($user_id: uuid!) {
                yt_user_place(where: { user_id: { _eq: $user_id } }) {
                  address
                  id
                  location
                  name
                  title
                  created_at
                }
              }
            `,
            variables: {
              user_id: userId,
            },
          },
        ],
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
