import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  qrCodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '85%',
    height: '43vmax',
    backgroundColor: '#000000',
    borderRadius: '8px 8px 30px 30px',
    marginBottom: '3vmax',
  },
  qrCodeContainer1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '85%',
    height: '20vmax',
    backgroundColor: '#000000',
    borderRadius: '8px 8px 30px 30px',
    marginBottom: '3vmax',
  },
  bookButton: {
    height: '6vmax',
    width: '50%',
    minWidth: '0px',
    borderRadius: '0px 0px 0px 30px',
    '& span': {
      fontSize: '1.9vmax',
    },
  },
  viewButton: {
    height: '6vmax',
    minWidth: '0px',
    width: '50%',
    borderRadius: '0px 0px 30px 0px',
    '& span': {
      fontSize: '1.9vmax',
    },
  },
  bookViewButtonContainer: {
    display: 'flex',
    width: '100%',
  },
  QRPassInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '33vmax',
    margin: 'auto',
    overflow: 'auto',
    width: '90%',
  },
  QRPassInfo1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '10vmax',
    margin: 'auto',
    overflow: 'auto',
    width: '90%',
  },
  passInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  passLabelText: {
    fontSize: '1.8vmax',
    color: '#FFFD00',
    fontFamily: 'Avenir-Black',
    fontWeight: 'bold',
  },
  passValueText: {
    fontSize: '1.8vmax',
    color: '#FFFFFF',
    fontFamily: 'Avenir-Book',
  },
  passDatesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  passDatesTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  passDatesTextLabel: {
    textAlign: 'center',
    fontSize: '1.8vmax',
    color: '#FFFD00',
    fontFamily: 'Avenir-Black',
    fontWeight: 'bold',
  },
  passDatesTextValue: {
    fontSize: '1.8vmax',
    color: '#FFFFFF',
    fontFamily: 'Avenir-Book',
  },
  qrImgContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '20vmax',
      height: '20vmax',
    },
  },
}));
