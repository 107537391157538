import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    // height: '100vmax',

    display: 'flex',
    flex: 1,
    height: 'calc(var(--vh, 1vh) * 95)',

    // justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
  },
  pointerStyle: {
    padding: '0%',
  },
  dialog: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    background:
      'linear-gradient(180deg, rgba(49, 49, 49, 1) 50%, rgba(31, 31, 31, 1) 100%) !important',
  },
  card: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    display: 'flex',
    marginTop: 'calc(var(--vh, 1vh) * 47)',
    height: 'calc(var(--vh, 1vh) * 50)',
    width: '100%',
    background:
      'linear-gradient(180deg, rgba(99,99,99,1) 0%, rgba(73,73,73,1) 23%, rgba(0,0,0,1) 100%)',
  },

  ButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 'calc(var(--vh, 1vh) * 10)',
    // marginTop: '2vmax',
  },
  customLabel: {
    color: '#FFFD00',
    fontSize: '2vmax',
    fontWeight: '600',
    marginBottom: '0.5vmax',
    // top: '-3px',
    position: 'static',
    transform: 'inherit',
    fontFamily: 'Avenir-Regular',
  },

  hamburgerContainer: {
    display: 'flex',
    marginTop: '10%',
    marginLeft: '5%',
    position: 'absolute',
  },
  HeaderSection: {
    // height: '10vmax',
    display: 'flex',
    flex: 0.15,
    alignItems: 'center',
    width: '80%',
  },
  DriverSection: {
    display: 'flex',
    height: '13vmax',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '2vmax',
    marginBottom: '1vmax',
  },
  DriverIcon: {
    position: 'relative',
    display: 'flex',
    flex: 0.2,
    marginRight: 10,
  },
  DriverDetailsSection: {
    display: 'flex',
    flex: 0.4,
    '& p': {
      margin: 0,
      marginTop: 9,
    },
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  VehicleDetailsSection: {
    display: 'flex',
    flex: 0.4,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'right',
  },
  OptionSection: {
    display: 'flex',
    height: '15vmax',
    flexDirection: 'row',
  },
  Option: {
    display: 'flex',
    flex: 0.33,
    alignItems: 'center',
    justifyContent: 'center',
  },
  OptionCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  ImageIcon: {
    height: '60%',
  },
  PassSection: {
    display: 'flex',
    flex: 0.85,
    alignItems: 'center',
    flexDirection: 'column',
    overflowY: 'scroll',

    padding: '10px',

    paddingBottom: '25px',
    paddingTop: '10px',
  },
  Pass: {
    minHeight: 'calc(var(--vh, 1vh) * 10)',

    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    backgroundColor: 'black',
    width: '100%',
    margin: '10px',
  },
  passDetails: {
    flexDirection: 'column',
    flex: 0.7,
    color: 'white',
  },
  passActions: {
    display: 'flex',
    flex: 0.3,
    alignItems: 'center',
    justifyContent: 'center',
    width: '30%',
    height: '100%',
    backgroundColor: 'rgb(118, 38, 188)',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
  },
  planCardDesign: {
    // marginTop: '40%',
    width: '100%',
    height: 'calc(var(--vh, 1vh) * 100)',
    position: 'absolute',
    // width: '100%',
  },
  planHeaderTextContainer: {
    width: '56vmin',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 2,
    top: '18%',
    '& #planType': {
      fontSize: '12px',
      // fontWeight: 'bold',
      color: 'black',
      //   width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    '& #planPrice': {
      fontSize: '16px',
      fontWeight: 'bolder',
      color: 'black',
      //   width: '100%',
      display: 'flex',
      textAlign: 'center',
    },
  },
  qrImg: {
    height: '185px',
    width: '185px',
    zIndex: 1,
    borderRadius: 11,
    border: '6px solid #02020261',
    marginTop: -101,
  },
  cardModal: {
    height: '27vmax',
    width: '80vmin',
    borderRadius: '15px',
    padding: '1vmax',
    display: 'flex',
    justifyContent: 'space-around',
    // alignItems: 'center',
    flexDirection: 'column',
    outline: 'none !important',
    backgroundImage: 'linear-gradient(to bottom, rgb(49,49,49), rgb(31,31,30))',
  },
  headerTextModal: {
    color: 'rgb(255,253,0)',
    fontFamily: 'Avenir-Black',
    textAlign: 'center',
    fontSize: '2.2vmax',
    fontWeight: 'bold',
    // width: '90%',
  },
  paragraphTextModal: {
    color: 'white',
    fontFamily: 'Avenir-Book',
    textAlign: 'center',
    fontSize: '1.8vmax',
    width: '90%',
  },
  verifiedIconModal: {
    marginBottom: '2.2vmax',
    height: '6vmax',
  },
  planButtonModal: {
    width: '70%',
    // marginTop: '2vmax',
    fontSize: '16px',
  },
}));
