import { gql } from '@apollo/client';

import client from '../Containers/App/config/ApolloClient';

export const giveRating = async (rideData, rateData) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation GiveRating(
            $from_user_type: String
            $from_user_id: uuid
            $to_user_type: String
            $to_user_id: uuid
            $ride_id: uuid
            $given_rate: Float
            $comment: String
          ) {
            insert_yt_rating_one(
              object: {
                from_user_type: $from_user_type
                from_user_id: $from_user_id
                to_user_type: $to_user_type
                to_user_id: $to_user_id
                ride_id: $ride_id
                given_rate: $given_rate
                comment: $comment
              }
            ) {
              id
            }
          }
        `,
        variables: {
          from_user_type: 'rider',
          from_user_id: rideData.user.id,
          to_user_type: 'driver',
          to_user_id: rideData.driver.id,
          ride_id: rideData.id,
          given_rate: rateData.rating,
          comment: rateData.comment,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getRideCompleteDetails = async (id) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query MyQuery($ride_id: uuid!) {
            yt_ride_by_pk(id: $ride_id) {
              id
              route_map_file_id
              route_map_image {
                id
              }
              confirmation_code
              duration
              distance
              start_location
              start_address
              start_at
              end_location
              end_address
              end_at
              user {
                id
                full_name
              }
              vehicle {
                name
                registration_date
                registration_number
                id
                vehicle_make {
                  title
                }
                vehicle_model {
                  title
                }
                vehicle_type {
                  title
                }
              }
              driver {
                id
                full_name
                average_rate
                profile_photo_file_id
              }
              boarding_pass {
                pass_number
                pass_type
              }
            }
          }
        `,
        variables: {
          ride_id: id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
