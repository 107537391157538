import React, { useState, useEffect } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import CallIcon from '@material-ui/icons/Call';
import Dialog from '@material-ui/core/Dialog';

import DoneIcon from '@material-ui/icons/Done';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import { createSOSRequest, LookForRequest } from '../../../Actions/SOS';
import dangerIcon from '../../../assets/svg/danger.svg';
import './SOSScreen.css';

const useStyles = makeStyles(() => ({
  btn: {
    backgroundColor: '#e12c2c',
    '&:focus': {
      backgroundColor: '#e12c2a',
    },
    margin: '1vh',
    minWidth: '10vmin',
    color: '#ffffff',
    textTransform: 'capitalize',
    letterSpacing: '0',
    padding: '0px 32px',

    height: '6.5vmax',
    borderRadius: '50px',
    fontSize: '2.3vmax',
    fontFamily: 'Avenir-Black',
    fontWeight: 'bold',
  },
  alertbtn: {
    backgroundColor: 'white !important',
    '&:focus': {
      backgroundColor: 'white !important',
    },
    margin: '1vh',
    minWidth: '10vmin',
    color: 'black !important',
    textTransform: 'capitalize',
    letterSpacing: '0',
    padding: '0px 32px',

    height: '6.5vmax',
    borderRadius: '50px',
    fontSize: '2.3vmax',
    fontFamily: 'Avenir-Black',
    fontWeight: 'bold',
  },
  btnregular: {
    margin: '1vh',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:focus': {
      backgroundColor: '#4F1C7C',
    },
    minWidth: '10vmin',
    color: '#ffffff',
    textTransform: 'capitalize',
    letterSpacing: '0',
    padding: '0px 32px',

    height: '6.5vmax',
    borderRadius: '50px',
    fontSize: '2.3vmax',
    fontFamily: 'Avenir-Black',
    fontWeight: 'bold',
  },
}));
function SOSScreen(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    LookForRequest(props.rideid, props.userid, props.driverid)
      .then((res) => {
        if (res.yt_sos_request && res.yt_sos_request.length > 0) {
          setAlert(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const createRequest = () => {
    setLoading(true);
    createSOSRequest(props.rideid, props.userid, props.driverid, props.loc.rideState)
      .then((res) => {
        setLoading(false);
        setAlert(true);
        setOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert(false);
      });
  };

  const renderAlert = () =>
    alert && (
      <div className="buttonholder">
        <Button
          disabled
          variant="contained"
          className={classes.alertbtn}
          startIcon={<DoneIcon htmlColor="#13b75a" />}
        >
          Alert Sent
        </Button>
      </div>
    );

  const renderDialog = () => (
    <Dialog open={open} aria-labelledby="alert" aria-describedby="alert">
      <div className="dialog-body">
        <p className="dialog-text">Continue to send Alert? </p>
        <Button
          fullWidth
          variant="contained"
          className={classes.btn}
          color="#e12c2c"
          onClick={() => {
            createRequest();
          }}
          disabled={loading}
        >
          Continue
        </Button>
        <Button
          fullWidth
          variant="contained"
          className={classes.btnregular}
          color="transparent"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );

  return (
    <div className="container">
      {renderDialog()}
      <div className="header">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          onClick={() => {
            props.dispatch({ type: 'SET_OPEN', payload: false });
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <p className="bold-yellow-title">SOS</p>
      </div>
      <div className="body">
        <div className="body-card">
          <img src={dangerIcon} className="icon" alt="danger" />
          <div className="body-text-div">
            <p className="body-text">
              USE IN CASE OF
              <br />
              EMERGENCY
            </p>
          </div>
          <div className="buttonholder">
            <Button
              variant="contained"
              className={classes.btn}
              startIcon={<CallIcon />}
              onClick={() => {
                setOpen(true);
              }}
            >
              Call Emergency
            </Button>
          </div>
          {renderAlert()}
        </div>
      </div>
    </div>
  );
}

export default SOSScreen;
