import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import './style.css';

const stripePromise = loadStripe(
  // eslint-disable-next-line max-len
  'pk_test_51HANOQG98ze8pCXuWVfLhHNLtCukN2YtVMhEccfvwxqbR0AyAkjbMkkdksAFWoIMs6Wbdp4VZANfOzH2AVeIKp0E00OVtdsABF',
);
export default function Stripe(props) {
  return (
    <>
      <Elements stripe={stripePromise}>
        <CheckoutForm state={props.state} />
      </Elements>
    </>
  );
}
