/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

// importing UI helpers
import Geocoder from 'react-mapbox-gl-geocoder';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import token from '../../Home/helpers/config/config';
import '../../Home/helpers/Maps/SearchableMap.css';
import HeaderTitle from '../../../Components/headertitle/HeaderTitle';
import useStyles from '../css/AddEditPlaceCss';

import PrimaryButton from '../../../Components/buttons/PrimaryButton';
import {
  addSavedPlace,
  updateSavedPlace,
  updateSavedPlaceAddress,
  updateSavedPlaceTitle,
} from '../../../Actions';

const defaultText = 'Enter Address';

export default function AddEditPlace() {
  const classes = useStyles();
  const history = useHistory();

  const [text, setText] = useState('');
  const [place, setPlace] = useState(null);
  const [title, setTitle] = useState(
    history.location.state.title ? history.location.state.title : '',
  );
  const [error, setError] = useState(false);

  const setDestination = (viewport, event) => {
    setPlace(event);
    setText(event.text);
  };

  const addPlace = () => {
    if (title && place) {
      addSavedPlace(place, title)
        .then((res) => {
          history.goBack();
        })
        .catch((err) => console.log('error save place', err));
      setError(false);
    } else {
      setError(true);
    }
  };

  const updatePlace = () => {
    if (title) {
      if (history.location.state.name !== title && place) {
        updateSavedPlace(place, title, history.location.state.id)
          .then((res) => {
            // console.log('res saveed place 1', res);
            history.goBack();
          })
          .catch((err) => console.log('error save place', err));
      } else if (place) {
        updateSavedPlaceAddress(place, history.location.state.id)
          .then((res) => {
            // console.log('res saveed place 2', res);
            history.goBack();
          })
          .catch((err) => console.log('error save place', err));
      } else {
        updateSavedPlaceTitle(title, history.location.state.id)
          .then((res) => {
            // console.log('res saveed place 3', res);
            history.goBack();
          })
          .catch((err) => console.log('error save place', err));
      }
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <div className={classes.root}>
      <HeaderTitle title={history.location.state.typeLabel} backBtn={() => history.goBack()} />
      <div className={classes.cardBg}>
        <div className={classes.placeCardContainer}>
          <div className={classes.placeContainer}>
            <div className={classes.inputContainer}>
              <div className={classes.inputContainerBox}>
                <InputLabel className={[classes.customLabel]}>Title</InputLabel>
                <Input
                  className={classes.inputContainer}
                  placeholder="Enter place title"
                  // defaultValue={}
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  // inputProps={{ 'aria-label': 'description' }}
                />
              </div>
              <div className={classes.inputContainerBox}>
                <InputLabel className={[classes.customLabel]}>Address</InputLabel>
                {/* <Input
                  className={classes.inputContainer}
                  placeholder="Enter address"
                  defaultValue={history.location.state.address}
                  // inputProps={{ 'aria-label': 'description' }}
                /> */}
                <Geocoder
                  // mapRef={mapRef}
                  onSelected={setDestination}
                  pos="bottom"
                  formatItem={(item) => item.place_name}
                  hideOnSelect
                  limit={4}
                  mapboxApiAccessToken={token}
                  blurEvent={(value) => {
                    if (value === ' ') {
                      setText(defaultText);
                    }
                  }}
                  focusEvent={(value) => {
                    if (value !== defaultText) {
                      setText(value);
                    } else {
                      setText(' ');
                    }
                  }}
                  changeEvent={(value) => {
                    setText(value);
                  }}
                  // containerRef={LocationContainerRef}
                  // onViewportChange={(port) =>
                  //   dispatch({
                  //     type: 'SET_VIEWPORT',
                  //     payload: port,
                  //   })
                  // }
                  // viewPort={state.viewPort}
                  initialInputValue={
                    history.location.state.name ? history.location.state.name : defaultText
                  }
                  textValue={text}
                  className="geocoder-input"
                />
              </div>
            </div>
            <div className={classes.buttonContainer}>
              {error ? (
                <div className={classes.warning}>
                  <span>Please enter all fields.</span>
                </div>
              ) : null}
              <PrimaryButton
                type="primary"
                variant="fullWidth"
                className={classes.saveButton}
                Title="Save"
                onClick={() => {
                  if (history.location.state.type === 'add') {
                    addPlace();
                  } else {
                    updatePlace();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
