/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react';
// import Draggable from 'react-draggable';
import SmallMap from '../../../assets/svg/mapSmall.svg';
import GreenDot from '../../../assets/svg/greenDot.svg';
import YellowDot from '../../../assets/svg/yellowDot.svg';
// import RightArrowSmall from '../../../assets/svg/smallRightArrow.svg';
// import DeleteIcon from '../../../assets/svg/deleteIcon.svg';

import useStyles from '../css/MyRidesPageCSS';
import './swipe-list.css';

export default (props) => {
  const classes = useStyles();

  const [left] = useState(0);
  // const [dragging, setDragging] = useState(false);

  const itemRef = useRef();
  // const actionRef = useRef();

  const svgPath = (iconName) => `${iconName}#svgView(preserveAspectRatio(none))`;

  // const handleStart = () => {};

  // const onDrag = () => {
  //   setDragging(true);
  // };

  // const handleStop = (e, data) => {
  //   setDragging(false);
  //   const w = itemRef.current.offsetWidth;
  //   const x = data.x < 0 ? data.x * -1 : data.x;
  //   const p = (x / w) * 100;
  //   if (dragging) {
  //     if (p > 30) {
  //       const pw = actionRef.current.offsetWidth;
  //       const leftWithAction = data.x > 0 ? 0 : (pw + 18) * -1;
  //       setLeft(leftWithAction);
  //     } else {
  //       setLeft(0);
  //       // props.onClickCard();
  //     }
  //   } else {
  //     props.onClickCard();
  //   }
  // };

  return (
    <div className={classes.listItem}>
      {/* <div className={classes.options} ref={actionRef}>
        <img src={svgPath(DeleteIcon)} alt="nada" className={classes.deleteIcon} />
      </div> */}
      {/* <Draggable
        axis="x"
        onStart={handleStart}
        scale={1}
        onDrag={onDrag}
        defaultPosition={{ x: 10, y: 0 }}
        position={{ x: left, y: 0 }}
        onStop={handleStop} 
      > */}
      <div
        ref={itemRef}
        className={classes.singleRide}
        style={{
          // position: 'absolute',
          transform: `translate3d(${left}px, 0, 0px)`,
          width: '92%',
        }}
        onClick={props.onClickCard}
      >
        <div className={classes.cardContainer}>
          <div className={classes.mapImageContainer}>
            {/* <div className={classes.rightArrowContainer}>
              <img
                src={svgPath(RightArrowSmall)}
                alt="nada"
                className={classes.rightArrowIcon}
                style={{ transform: `rotate(${left < 0 ? '0deg' : '180deg'})` }}
              />
            </div> */}
            <img
              src={props.mapUrl ? props.mapUrl : SmallMap}
              alt="nada"
              className={classes.mapSmall}
            />
          </div>
          <div className={classes.rideInfoOuterContainer}>
            <div className={classes.rideInfoInnerContainer}>
              <div className={classes.rideAddressContainer}>
                <div className={classes.startAddressContainer}>
                  <img src={GreenDot} alt="nada" className={classes.colorDot} />
                  <div className={classes.label}>
                    <span>Start:</span>
                  </div>
                  <div className={classes.address}>
                    <span>{props.startAddress}</span>
                  </div>
                </div>
                <div className={classes.endAddressContainer}>
                  <img src={YellowDot} alt="nada" className={classes.colorDot} />
                  <div className={classes.label}>
                    <span>End:</span>
                  </div>
                  <div className={classes.address}>
                    <span>{props.endAddress}</span>
                  </div>
                </div>
              </div>
              <div className={classes.rideDateTimeContainer}>
                <div className={classes.rideDateContainer}>
                  <span>{props.date}</span>
                </div>
                <div className={classes.rideTimeContainer}>
                  <span>{props.time}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
    </div>
  );
};
