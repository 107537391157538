import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';
import AddToHome from '../../../assets/img/AddToHome.png';
import AddToHome1IOS from '../../../assets/img/AddToHome_Step_1_IOS.png';
import AddToHome2IOS from '../../../assets/img/AddToHome_Step_2_IOS.png';
import AddToHome3IOS from '../../../assets/img/AddToHome_Step_3_IOS.png';
import AddToHome1Android from '../../../assets/img/AddToHome_Step_1_Android.png';
import AddToHome2Android from '../../../assets/img/AddToHome_Step_2_Android.png';
import AddToHome3Android from '../../../assets/img/AddToHome_Step_3_Android.png';
import PrimaryButton from '../../../Components/buttons/PrimaryButton';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const useStyles = makeStyles(() => ({
  root: {
    // display: 'flex',
    flexGrow: 1,
    backgroundColor: '#101510',
    justifyContent: 'center',
    // alignItems: 'center',
    height: 'calc(var(--vh, 1vh) * 100)',
  },
  imageLogoSection: {
    position: 'absolute',
    top: '10vh',
    left: 0,
    width: '100%',
  },
  imageLogo: {
    textAlign: 'center',
  },
  imageBanner: {
    // display: 'flex',
    textAlign: 'center',
    marginBottom: 20,
    height: '75vh',
  },
  instructionArea: {
    // position: 'relative',
    // '& .slick-slider': {
    //   color: '#fff',
    // },
    color: '#fff',
  },
  instructionText: {
    // position: 'relative',
    // '& .slick-slider': {
    //   color: '#fff',
    // },
    color: '#fffd00',
    textAlign: 'center',
    fontSize: '2.5vh',
    padding: '0 15px',
  },
  dotsPlans: {
    display: 'flex !important',
    justifyContent: 'center',
    '& li': {
      width: 6,
      height: 6,
      margin: '0 3px',
      paddingLeft: 3,
      listStyleType: 'none',
      '&.slick-active': {
        '& button': {
          '&:before': {
            opacity: 1,
            color: '#8F8F87',
          },
          background: '#6626b6',
          width: 14,
          borderRadius: 9,
        },
      },
      '& button': {
        width: 6,
        height: 6,
        padding: 0,
        lineHeight: '16px',
        marginLeft: -18,
        color: '$e5e5e5',
        background: '$e5e5e5',
        fontSize: 0,
        outline: 'none',
        boxShadow: 'none',
        border: 'none',
        borderRadius: '50%',

        '&:before': {
          lineHeight: 0,
          width: 6,
          height: 6,
          color: '#535353',
          opacity: 1,
        },
      },
    },
    '& li.slick-active button': {
      background: 'rgb(143,143,135)',
    },
  },
  imageWidth: {
    width: 'auto !important',
    maxWidth: '100%',
    height: '70vh',
  },
  imageSectionWidth: {
    width: 'auto !important',
    maxWidth: '100%',
    height: '67vh',
    padding: '3vh 2vh 0vh',
    '& img': {
      width: 'auto !important',
      maxWidth: '100%',
      height: '67vh',
    },
  },
  buttonSection: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'center',
    '& button': {},
  },
}));

function AddToHomeScreen() {
  let sliderRef;
  const history = useHistory();
  const classes = useStyles();
  const navigationHandler = (url, param, data) => {
    history.push({
      pathname: `${url}`,
      param,
      data: data || {},
    });
  };
  const TOTAL_SLIDE = 4;
  const [CurrentSlide, setCurrentSlide] = React.useState(0);
  const [settings] = React.useState({
    className: `center slideSingle ${classes.slideSingle}`,
    // centerMode: true,
    infinite: false,
    dots: true,
    // centerPadding: '65px',
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    arrows: false,
    dotsClass: `slick-dots ${classes.dotsPlans}`,
  });

  const isIOS = () => {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform,
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  };

  const IOS = isIOS();

  useEffect(() => {
    localStorage.setItem('firstLoad', true);
    // setTimeout(() => {
    //   const token = localStorage.getItem('token');
    //   if (token) {
    //     navigationHandler('home');
    //   } else {
    //     navigationHandler('login');
    //   }
    // }, 4000);
  }, []);

  const openApp = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigationHandler('home');
    } else {
      navigationHandler('login');
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.imageBanner}>
          {/* <div className={classes.imageLogoSection}>
            <img className={classes.imageLogo} src={AddToHomeLogo} alt="Banner" />
          </div> */}
          {IOS ? (
            <Slider
              ref={(c) => (sliderRef = c)}
              beforeChange={(currentSlide, nextSlide) => {
                setCurrentSlide(nextSlide);
              }}
              {...settings}
            >
              <img className={classes.imageWidth} src={AddToHome} alt="Banner" />
              <div className={classes.imageSectionWidth}>
                <img src={AddToHome1IOS} alt="Banner" />
              </div>
              <div className={classes.imageSectionWidth}>
                <img src={AddToHome2IOS} alt="Banner" />
              </div>
              <div className={classes.imageSectionWidth}>
                <img src={AddToHome3IOS} alt="Banner" />
              </div>
            </Slider>
          ) : (
            <Slider
              ref={(c) => (sliderRef = c)}
              beforeChange={(currentSlide, nextSlide) => {
                setCurrentSlide(nextSlide);
              }}
              {...settings}
            >
              <img className={classes.imageWidth} src={AddToHome} alt="Banner" />
              <div className={classes.imageSectionWidth}>
                <img src={AddToHome1Android} alt="Banner" />
              </div>
              <div className={classes.imageSectionWidth}>
                <img src={AddToHome2Android} alt="Banner" />
              </div>
              <div className={classes.imageSectionWidth}>
                <img src={AddToHome3Android} alt="Banner" />
              </div>
            </Slider>
          )}
        </div>

        <div className={classes.instructionArea}>
          <h1 className={classes.instructionText}>
            {!IOS &&
              // eslint-disable-next-line no-nested-ternary
              (CurrentSlide === 0 ? (
                <span>
                  Yello works Best
                  <br />
                  when added to Home screen
                </span>
              ) : // eslint-disable-next-line no-nested-ternary
              CurrentSlide === 1 ? (
                <span>
                  Click on menu button
                  <br />
                  on top right corner
                </span>
              ) : CurrentSlide === 2 ? (
                <span>
                  Select add to home screen option
                  <br />
                  from the menu
                </span>
              ) : (
                <span>
                  Click on add button to
                  <br />
                  add Yello on Home screen
                </span>
              ))}
            {IOS &&
              // eslint-disable-next-line no-nested-ternary
              (CurrentSlide === 0 ? (
                <span>
                  Yello works Best
                  <br />
                  when added to Home screen
                </span>
              ) : // eslint-disable-next-line no-nested-ternary
              CurrentSlide === 1 ? (
                <span>Tap on the screen button</span>
              ) : CurrentSlide === 2 ? (
                <span>
                  Tap on the middle
                  <br />
                  add to home screen button
                </span>
              ) : (
                <span>
                  You can tap on Add button to
                  <br />
                  add icon on home screen
                </span>
              ))}
          </h1>
          <div className={classes.buttonSection}>
            <PrimaryButton
              type="outlined"
              // variant="fullWidth"
              Title="Skip"
              style={{ border: 'none' }}
              disabled={CurrentSlide === TOTAL_SLIDE - 1}
              onClick={() => {
                openApp();
              }}
            />
            <PrimaryButton
              type="primary"
              // variant="fullWidth"
              Title={CurrentSlide === TOTAL_SLIDE - 1 ? 'Done' : 'Next'}
              // disabled={CurrentSlide === TOTAL_SLIDE - 1}
              onClick={() => {
                if (CurrentSlide === TOTAL_SLIDE - 1) {
                  openApp();
                } else {
                  sliderRef.slickNext();
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddToHomeScreen;
