import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    // height: '14vmax',
    overflow: 'auto',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(0,0,0)',
  },
  tripCardContainer: {
    marginTop: '10px',
    width: '90%',
    display: 'flex',
    height: '80%',
    flexDirection: 'column',
    alignSelf: 'center',
    justifyContent: 'space-between',
    // alignItems: 'center',
    // overflow: 'hidden',
  },
  tripContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  tripStartContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tripEndContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tripLabel: {
    marginLeft: '3px',
    fontFamily: 'Avenir-Roman',
    color: 'rgb(255,253,0)',
    fontWeight: '900',
    fontSize: '1.8vmax',
  },
  tripText: {
    marginLeft: '3px',
    fontFamily: 'Avenir-Roman',
    color: 'rgb(255,255,255)',
    fontSize: '1.8vmax',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tripTimeTaxiNoContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1vmax',
    justifyContent: 'space-between',
  },
  tripDate: {
    fontSize: '1.6vmax',
    fontFamily: 'Avenir-Black',
    fontWeight: '900',
    color: 'rgb(181,181,181)',
  },
  tripTime: {
    fontSize: '1.6vmax',
    fontFamily: 'Avenir-Roman',
    color: 'rgb(181,181,181)',
  },
  taxiNoLabel: {
    fontSize: '1.6vmax',
    fontFamily: 'Avenir-Black',
    fontWeight: '900',
    color: 'rgb(181,181,181)',
  },
  taxiNoText: {
    fontSize: '1.6vmax',
    fontFamily: 'Avenir-Roman',
    color: 'rgb(181,181,181)',
  },
  divider: {
    height: '1px',
    backgroundColor: 'rgb(112,112,112)',
    width: '100%',
  },
}));
