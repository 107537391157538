import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  // planBox: {
  //   width: '56vmin',
  //   margin: '0 auto',
  //   border: '0.5vmax solid rgb(112,112,112)',
  //   borderRadius: '2vmax',
  //   height: '50vmax',
  //   overflow: 'hidden',
  // },
  planContainer: {
    minHeight: '60vmax',
    display: 'flex',
    alignItems: 'center',
  },
  planHeaderBox: {
    height: '50%',
    width: '100%',
  },

  planCardDesign: {
    width: '56vmin',
    position: 'absolute',
    // width: '100%',
  },

  planHeaderTextContainer: {
    width: '56vmin',
    height: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    '& #planType': {
      fontSize: '2.7vmax',
      fontWeight: 'bold',
      color: 'black',
      //   width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    '& #planPrice': {
      fontSize: '4.5vmax',
      fontWeight: 'bolder',
      color: 'black',
      //   width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    '& div': {
      height: '1px',
      backgroundColor: 'rgb(112,112,112)',
      width: '70%',
      margin: '1.5vmax 0',
    },
  },
  yellowCheckMark: {
    height: '2.5vmax',
    width: '2.5vmax',
  },

  planDetailBox: {
    transform: 'translateY(-1vmax)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#313131',
    borderRadius: '80px 0 0 0',
    '& ul': {
      padding: 0,
      listStyleType: 'none',
      '& li': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2vmax',
        '& span': {
          color: '#FFF',
          fontSize: '1.8vmax',
          fontWeight: 500,
          marginLeft: '3vmin',
        },
      },
    },
  },
  planButton: {
    width: '70%',
    marginTop: '2vmax',
  },
}));
