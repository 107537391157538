/* eslint-disable camelcase */
import { gql } from '@apollo/client';

import client from '../Containers/App/config/ApolloClient';

// eslint-disable-next-line import/prefer-default-export
export const getPasses = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const user_id = user.id;
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query($user_id: uuid!) {
            yt_boarding_pass(where: { user_id: { _eq: $user_id } }) {
              pass_number
              pass_type
              valid_from
              valid_to
              qr_code
              id
              status
              purchased_at
              user_id
              rides {
                id
                start_location
                end_location
                duration
                distance
                start_address
                end_address
                vehicle_id
                boarding_pass_id
                created_at
                vehicle {
                  registration_number
                  id
                  name
                }
              }
              order {
                amount
                net_amount
                transaction_charge
                tax
                discount
                payments {
                  id
                  user_id
                  payment_method
                  amount
                }
              }
              plan {
                validity_days
                title
              }
            }
          }
        `,
        variables: {
          user_id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
