import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    // paddingTop: '4vh',
    '& .MuiPaper-root': {
      background: 'rgb(0,0,0) !important',
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
    },
    '& .MuiAccordion-rounded:last-child': {
      borderBottomLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
    '& .MuiAccordion-rounded:first-child': {
      borderTopLeftRadius: '8px',
      borderTopLeftRadius: '8px',
    },
    marginBottom: '2.5vmax',
    width: '100%',
    borderRadius: '8px',
    backgroundColor: 'rgb(0,0,0)',
  },
  accordian: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'rgb(0,0,0) !important',
    flexDirection: 'column',
  },
  summaryTextContainer: {
    fontSize: '1.8vmax',
    display: 'flex',
    color: 'white',
    fontFamily: 'Avenir-Black',
    fontWeight: '900',
  },
  accordianSummary: {
    display: 'flex',
    flex: 1,
  },
  detailsTextContainer: {
    fontSize: '1.8vmax',
    display: 'flex',
    color: 'white',
    fontFamily: 'Avenir-Book',
    fontWeight: '500',
    paddingBottom: '7%',
  },
}));
