import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Grow from '@material-ui/core/Grow';
import useStyles from '../css/IssueSuccessModalCss';
import Verified from '../../../assets/svg/verified.svg';
import PrimaryButton from '../../../Components/buttons/PrimaryButton';

export default function IssueSuccessModal(props) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openModal}
        // onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grow in={props.openModal}>
          <div className={classes.card}>
            <img src={Verified} alt="nothing" className={classes.verifiedIcon} />
            <div className={classes.headerText}>
              <span>Issue submitted</span>
            </div>
            <div className={classes.paragraphText}>
              {/* <span>
                {`Your plan starts when you ride for the first time and will 
                remain valid for next ${props.validity} hours.`}
              </span> */}
            </div>
            <PrimaryButton
              type="primary"
              variant="fullWidth"
              onClick={props.handleClose}
              className={classes.planButton}
              Title="Procceed"
            />
          </div>
        </Grow>
      </Modal>
    </div>
  );
}
