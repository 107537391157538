/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unknown-property */
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    flexFlow: 'column',
    marginLeft: 0,
    marginRight: 0,
    width: '20%',
    '& .MuiTypography-root': {
      fontSize: 10,
      color: '#fff',
      fontFamily: 'Avenir Next',
      letterSpacing: 2,
    },
  },
  switchContainer: {
    display: 'flex',
    flexFlow: 'row',
    width: '80vmin',
    height: '6vmax',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
  },
  activePlan: {
    fontSize: '2.2vmax',
    color: '#FFFD00',
  },
  inActivePlan: {
    fontSize: '2.2vmax',
    color: '#8F8F87',
  },
  switchLabel1: {
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  switchLabel2: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: '42px',
    height: '22px',
    padding: 0,
    display: 'flex',
    overflow: 'visible',
  },
  switchBase: {
    padding: 2,
    color: 'rgb(174,174,174)',
    '&$checked': {
      transform: 'translateX(22px)',
      color: 'rgb(174,174,174)',
      '& + $track': {
        opacity: 1,
        backgroundColor: 'black',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    marginTop: '1px',
    boxShadow: 'none',
  },
  track: {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: 'black',
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

// eslint-disable-next-line no-unused-vars
const SwitchField = (props) => {
  const classes = useStyles();
  // console.log(props.type);
  return (
    <>
      <FormGroup>
        <div className={classes.switchContainer}>
          {props.switchLabel1 ? (
            <div className={classes.switchLabel1}>
              <span className={!props.checked ? classes.activePlan : classes.inActivePlan}>
                {props.switchLabel1}
              </span>
            </div>
          ) : null}
          <FormControlLabel
            className={classes.label}
            control={
              <IOSSwitch checked={props.checked} onChange={props.onChange} name="checkedSwitch" />
            }
            label={props.switchLabelOptions ? props.switchLabelOptions : null}
          />
          {props.switchLabel2 ? (
            <div className={classes.switchLabel2}>
              <span className={props.checked ? classes.activePlan : classes.inActivePlan}>
                {props.switchLabel2}
              </span>
            </div>
          ) : null}
        </div>
      </FormGroup>
    </>
  );
};

export default SwitchField;
