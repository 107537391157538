/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */
import React from 'react';

import { useHistory } from 'react-router-dom';

// importing UI components
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { Button } from '@material-ui/core';
import PrimaryButton from '../../../../Components/buttons/PrimaryButton';
import HeaderTitle from '../../../../Components/headertitle/HeaderTitle';
import MapBig from '../../../../assets/svg/mapBig.svg';
import ProfilePic from '../../../../assets/svg/profilePic1.svg';
import RideEndTripDetailCard from '../../Components/RideEndTripDetailCard';

// importing helpers
import useStyles from '../../css/RideEndDetailCss';
import RateDriverCard from '../../../../Components/cards/RateDriverCard';
import { getRideCompleteDetails, giveRating } from '../../../../Actions';
import navigationHandler from '../../../App/helpers/NavigationHandler';
import getUrlFromId from '../../../../Actions/Common';
import Loader from '../../../../Components/loader/Loader';

export default function EndRideDetail() {
  const [showRateDriverCard, setRateDriverCard] = React.useState(false);
  const [rideCompleteDetails, setRideCompleteDetails] = React.useState(null);
  const [finalRating, setFinalRating] = React.useState(0);
  const [driverProfileImage, setDriverProfileImage] = React.useState();
  const [mapImageUrl, setMapImageUrl] = React.useState();
  const classes = useStyles();
  const history = useHistory();

  let mapTimer = 0;
  const getUrl = async (id) => {
    try {
      const url = await getUrlFromId(id);
      const result = await url.data.GenViewUrl[0].url;
      return result;
    } catch (error) {
      return '';
    }
  };

  React.useEffect(() => {
    if (history.location.state && history.location.state.id !== null) {
      let mapUrl;
      mapTimer = setInterval(() => {
        getRideCompleteDetails(history.location.state.id).then(async (res) => {
          setRideCompleteDetails(res.data.yt_ride_by_pk);
          const driverProfileUrl = await getUrl(
            res.data.yt_ride_by_pk?.driver?.profile_photo_file_id,
          );
          setDriverProfileImage(driverProfileUrl);
          mapUrl = await getUrl(res.data.yt_ride_by_pk?.route_map_file_id);
          setMapImageUrl(mapUrl);
          if (mapUrl != '' && mapUrl != undefined && mapUrl != null) {
            clearTimeout(mapTimer);
          }
        });
      }, 1000);
    }
    return () => {
      clearTimeout(mapTimer);
    };
  }, []);

  const onSubmitRateDriver = (rateData) => {
    giveRating(rideCompleteDetails, rateData)
      .then(() => {
        setFinalRating(rateData.rating);
        history.push({
          state: {
            amountToPay: rateData.tipAmount,
            ride_id: rideCompleteDetails.id,
            driver_name: rideCompleteDetails.driver.full_name,
            // ride_id: "689ffad9-1b42-4d42-a51f-e1a0656ddbe4"
          },
        });
        // setOpenPaymentScreen(true);
        setRateDriverCard(false);
      })
      .catch((err) => {
        console.log('🚀 ~ file: RideEndDetails.js ~ line 48 ~ onSubmitRateDriver ~ err', err);
        // setIsSubmiting(false);
      });
  };

  return (
    <div className={classes.root}>
      <RateDriverCard
        checked={showRateDriverCard}
        profilePic={ProfilePic}
        onClickToggle={(data) => onSubmitRateDriver(data)}
      />
      <HeaderTitle title="Ride Details" backBtn={() => history.push({ pathname: '/home' })} />
      <div className={classes.cardBg}>
        <div
          className={
            mapImageUrl === '' || mapImageUrl
              ? classes.imageMapContainerMap
              : classes.imageMapContainer
          }
        >
          {!mapImageUrl && <img alt="map" src={MapBig} className={classes.mapBig} />}
          {mapImageUrl && <img alt="map" src={mapImageUrl} className={classes.mapBig} />}
        </div>
        <RideEndTripDetailCard tripData={rideCompleteDetails} />
        <div className={classes.rateReviewCardContainer}>
          <div className={classes.rateReviewCard}>
            <div className={classes.profilePicContainer}>
              {driverProfileImage ? (
                <img alt="nothing" src={driverProfileImage} className={classes.profilePic} />
              ) : (
                <img alt="nothing" src={ProfilePic} className={classes.profilePic} />
              )}
            </div>
            <div className={classes.driverRatingContainer}>
              <span className={classes.driverNameText}>
                {rideCompleteDetails ? rideCompleteDetails.driver.full_name : ''}
              </span>
              <div className={classes.driverTaxiDetailCont}>
                <span className={classes.driverTaxiLabel}>
                  {rideCompleteDetails
                    ? rideCompleteDetails.vehicle.name
                      ? rideCompleteDetails.vehicle.name
                      : 'Car Model'
                    : ''}
                </span>
                <span className={classes.driverTaxiNumber}>
                  {' '}
                  {rideCompleteDetails
                    ? rideCompleteDetails.vehicle.registration_number
                      ? rideCompleteDetails.vehicle.registration_number
                      : 'XXXXXXXX'
                    : ''}
                </span>
              </div>
              <Rating
                name="customized-empty"
                value={finalRating}
                precision={0.5}
                className={classes.ratings}
                disabled
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
            </div>
            <div className={classes.rateButtonContainer}>
              <Button
                onClick={() => setRateDriverCard(true)}
                className={classes.rateButton}
                variant="outlined"
              >
                Rate
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <PrimaryButton
            type="primary"
            variant="fullWidth"
            className={classes.rideButton}
            Title="Ride Again"
            onClick={() =>
              navigationHandler(history, '/home', null, {
                // start: rideCompleteDetails.start_location,
                // startAddress: rideCompleteDetails.start_address.line1
                //   ? rideCompleteDetails.start_address.line1
                //   : '',
                // end: rideCompleteDetails.end_location,
                // endAddress: rideCompleteDetails.end_address.line1
                //   ? rideCompleteDetails.end_address.line1
                //   : '',
                // isRideAgain: true,
              })
            }
          />
        </div>
      </div>
    </div>
  );
}
