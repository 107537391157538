/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-fragments */
/* eslint-disable max-len */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import BackArrow from '../../assets/svg/back.svg';

const useStyles = makeStyles(() => ({
  headertitle: {
    padding: '3.5vmax 0 0 4vmin',
  },
  backBtn: {
    padding: 0,
    border: 'none',
    background: 'transparent',
    '&:focus': {
      outline: 'none',
    },
  },
  screentitle: {
    fontFamily: 'Avenir Next',
    fontSize: 20,
    color: '#FFFD00',
    marginBottom: 0,
    marginTop: 8,
    fontWeight: '900',
  },
}));

// eslint-disable-next-line no-unused-vars
const HeaderBackButton = (props) => {
  const classes = useStyles();
  // console.log(props.type);
  return (
    <div style={props.headerStyle} className={classes.headertitle}>
      <button type="button" className={classes.backBtn} onClick={props.backBtn}>
        <img
          src={BackArrow}
          style={{ height: '3vmax', width: '5vmin', position: 'absolute', zIndex: '999' }}
          alt="nothing"
        />
      </button>
    </div>
  );
};

export default HeaderBackButton;
